import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { login } from '../../action/Auth';
import { Row, Button, Col, Typography, Form, Input } from 'antd';
import { blue } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';

const Login = ({ login, isAuthenticated }) => {
  const { Title, Text } = Typography;

  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }
  const onFinish = (values) => {
    login(values.email.trim(), values.password);
  };
  return (
    <Row justify='space-around' align='middle' style={{ marginTop: '5px' }}>
      <Col xs={24} sm={9} className='signinCol'>
        <Title
          level={3}
          style={{ color: blue, marginBottom: '5px', marginLeft: '10px' }}
        >
          <Text style={{ color: blue[6] }}> Đăng Nhập</Text>
        </Title>
        <UserOutlined style={{ marginLeft: '10px' }} /> Đăng nhập vào tài khoản
        <Form
          name='normal_login'
          className='login-form'
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 19,
          }}
          onFinish={onFinish}
          style={{ marginTop: '20px' }}
        >
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              { required: true, message: 'Please input your Email!' },
            ]}
          >
            <Input type='email' />
          </Form.Item>
          <Form.Item
            name='password'
            label='Mật Mã'
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input type='password' placeholder='Password' />
          </Form.Item>
          <Row>
            <Col xs={23}>
              <div>
                <a className='login-form-forgot' href='/'>
                  Quên mật mã
                </a>
              </div>
            </Col>
          </Row>
          <Form.Item
            wrapperCol={{
              xs: { span: 10, offset: 7 },
              sm: { span: 8, offset: 10 },
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              size='large'
            >
              Đăng Nhập
            </Button>
          </Form.Item>
          <Text className='already-register-or-login'>
            Bạn chưa có tài khoản? <Link to='/register'>Đăng ký tại đây</Link>
          </Text>
        </Form>
      </Col>
    </Row>
  );
};
Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.AuthRedux.isAuthenticated,
});
export default connect(mapStateToProps, { login })(Login);
