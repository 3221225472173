import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Table, Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Store from '../../Store';
import axios from 'axios';
import {
  addOrder,
  deleteOrder,
  updateOrder,
  updateCounter,
} from '../../action/Order';
import { SocketContext } from '../context/socket';
import { isMobile } from 'react-device-detect';
const MassUpdateModal = (props) => {
  const { Option } = Select,
    socket = useContext(SocketContext),
    {
      selectedOrder,
      massUpdateModalId,
      clearAllSelectedOrder,
      orders,
      user,
      data,
    } = props,
    [modalVisible, setModalVisible] = useState(false),
    [tableData, setTableData] = useState([]),
    [disabledUpdate, setDisabledUpdate] = useState(true),
    [massOrderUpdateForm] = Form.useForm(),
    config = {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    massUpdateModalFotter = [
      <Button
        key='update'
        htmlType='submit'
        type='primary'
        data-id='update'
        disabled={disabledUpdate}
        onClick={async (e) => {
          try {
            massOrderUpdateForm.validateFields().then((values) => {
              onFinish(e, values);
            });
          } catch (err) {
            console.log(err);
          }
        }}
      >
        Cập Nhật
      </Button>,
      <Button
        key='cancel'
        type='danger'
        onClick={() => {
          try {
            setModalVisible(false);
          } catch (err) {
            console.log(err);
          }
        }}
      >
        Huỷ
      </Button>,
    ],
    columns = [
      {
        title: 'Khách hàng',
        dataIndex: 'name',
        key: 'id',
        width: '30%',
      },
      {
        title: 'Số ĐT',
        dataIndex: 'rPhone',
        width: '23%',
      },
      {
        title: 'Trạng Thái',
        dataIndex: 'status',
        width: '24%',
      },
      {
        title: 'Kết quả',
        dataIndex: 'result',
        width: '22%',
        render: (text) => (
          <span style={{ color: text === 'Không hợp lệ' ? 'red' : 'blue' }}>
            {text}
          </span>
        ),
      },
    ],
    columns1 = [
      {
        title: 'Khách hàng',
        dataIndex: 'nameAndPhone',
        key: 'id',
        width: '55%',
        render: (text) => (
          <>
            <p style={{ padding: 0, margin: 0 }}>{text.split('@')[0]}</p>
            <p style={{ padding: 0, margin: 0 }}>{text.split('@')[1]}</p>
          </>
        ),
      },

      {
        title: 'Kết quả',
        dataIndex: 'result',
        width: '45%',
        render: (text) => (
          <span style={{ color: text === 'Không hợp lệ' ? 'red' : 'blue' }}>
            {text}
          </span>
        ),
      },
    ],
    handleCancleMassUpdate = () => setModalVisible(false),
    onFinish = async (e, value) => {
      try {
        const tempTable = [...tableData];
        for (let val in tableData) {
          if (
            tempTable[val].realStatus === 'dahuy' ||
            tempTable[val].realStatus === 'danhanhang' ||
            tempTable[val].realStatus === 'đaoisoat' ||
            tempTable[val].realStatus === 'moi'
          ) {
            tempTable[val].result = 'Không hợp lệ';
          } else if (
            String(value.choicedStatus) == String(tableData[val].realStatus)
          ) {
            tempTable[val].result = 'Thành Công';
          } else {
            const donHang = {
              id: tableData[val].id,
              donHang: { status: value.choicedStatus },
            };

            const submitResult = await axios.put(
              '/api/orders',
              donHang,
              config
            );
            if (submitResult.data.success === true) {
              socket.emit('changeOrderStatus', {
                room: user.partition,
                data: submitResult.data.result,
                oldStatus: tableData[val].realStatus,
                newStatus: value.choicedStatus,
              });
              if (data.currentTab === 'all') {
                Store.dispatch(
                  updateOrder(
                    submitResult.data.result,
                    tableData[val].realStatus,
                    value.choicedStatus
                  )
                );
              } else if (
                data.currentTab === tableData[val].realStatus &&
                data.currentTab !== value.choicedStatus
              ) {
                Store.dispatch(
                  deleteOrder(
                    submitResult.data.result._id,
                    tableData[val].realStatus,
                    value.choicedStatus
                  )
                );
              } else if (
                data.currentTab === value.choicedStatus &&
                data.currentTab !== tableData[val].realStatus
              ) {
                Store.dispatch(addOrder(submitResult.data.result));
                Store.dispatch(
                  updateCounter(tableData[val].realStatus, value.choicedStatus)
                );
              } else if (
                data.currentTab === value.choicedStatus &&
                data.currentTab === tableData[val].realStatus
              ) {
                Store.dispatch(
                  updateOrder(
                    submitResult.data.result,
                    tableData[val].realStatus,
                    value.choicedStatus
                  )
                );
              } else {
                Store.dispatch(
                  updateCounter(tableData[val].realStatus, value.choicedStatus)
                );
              }
              tempTable[val].result = 'Thành Công';
              tempTable[val].realStatus = value.choicedStatus;
              tempTable[val].status =
                value.choicedStatus === 'moi'
                  ? 'Đơn hàng mới'
                  : value.choicedStatus === 'dagiaohang'
                  ? 'Đã gửi hàng'
                  : value.choicedStatus === 'daxacnhan'
                  ? 'Đã xác nhận'
                  : value.choicedStatus === 'chochuyenhang'
                  ? 'Chờ Chuyển Hàng'
                  : value.choicedStatus === 'danhanhang'
                  ? 'Đã Nhận Hàng'
                  : value.choicedStatus === 'dahuy'
                  ? 'Đã Huỷ'
                  : value.choicedStatus === 'dadoisoat'
                  ? 'Đã Đối Soát'
                  : 'Không hợp lệ';
            } else {
              tempTable[val].result = 'Thất bại';
            }
          }
        }
        setTableData([...tempTable]);
        console.log(clearAllSelectedOrder);
        massOrderUpdateForm.resetFields();
        setDisabledUpdate(true);
        clearAllSelectedOrder();
      } catch (err) {
        console.log(err);
      }
    };
  const handleOnChange = (e) => {
    if (massOrderUpdateForm.getFieldValue('choicedStatus')) {
      setDisabledUpdate(false);
    }
  };
  useEffect(() => {
    try {
      setTableData([]);
      if (selectedOrder.selectedOrder) {
        for (let val of selectedOrder.selectedOrder) {
          setTableData((prev) => [
            ...prev,
            {
              name: orders[val].name,
              rPhone: orders[val].rPhone,
              nameAndPhone: orders[val].name + '@Số ĐT: ' + orders[val].rPhone,
              status:
                orders[val].status === 'moi'
                  ? 'Đơn hàng mới'
                  : orders[val].status === 'dagiaohang'
                  ? 'Đã gửi hàng'
                  : orders[val].status === 'daxacnhan'
                  ? 'Đã xác nhận'
                  : orders[val].status === 'chochuyenhang'
                  ? 'Chờ Chuyển Hàng'
                  : orders[val].status === 'danhanhang'
                  ? 'Đã Nhận Hàng'
                  : orders[val].status === 'dahuy'
                  ? 'Đã Huỷ'
                  : orders[val].status === 'dadoisoat'
                  ? 'Đã Đối Soát'
                  : 'Không hợp lệ',
              result: '',
              id: orders[val]._id,
              realStatus: orders[val].status,
            },
          ]);
        }

        setModalVisible(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [massUpdateModalId]);

  if (isMobile) {
    return (
      <>
        <Modal
          visible={modalVisible}
          footer={massUpdateModalFotter}
          onCancel={handleCancleMassUpdate}
          title=' Cập nhật hàng loạt đơn hàng'
          width={650}
        >
          <Form
            name='massupdate'
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onValuesChange={handleOnChange}
            autoComplete='off'
            form={massOrderUpdateForm}
          >
            <Form.Item
              name='choicedStatus'
              label='Chọn trạng thái đơn hàng'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn trạng thái đơn hàng',
                },
              ]}
            >
              <Select
                placeholder='Chọn trạng thái đơn hàng muốn cập nhật'
                style={{ width: '99%' }}
              >
                <Option value='chochuyenhang'>Chờ gửi hang</Option>
                <Option value='dagiaohang'>Đã gửi hàng</Option>
                <Option value='danhanhang'>đã nhận hàng</Option>
              </Select>
            </Form.Item>
          </Form>
          <Table
            dataSource={tableData}
            columns={columns1}
            rowKey={'id'}
            pagination={false}
            scroll={{ y: 300 }}
          />
        </Modal>
      </>
    );
  }
  return (
    <Modal
      visible={modalVisible}
      footer={massUpdateModalFotter}
      onCancel={handleCancleMassUpdate}
      title=' Cập nhật hàng loạt đơn hàng'
      width={650}
    >
      <Form
        name='massupdate'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onValuesChange={handleOnChange}
        autoComplete='off'
        form={massOrderUpdateForm}
      >
        <Form.Item
          name='choicedStatus'
          label='Chọn trạng thái'
          rules={[
            { required: true, message: 'Vui lòng chọn trạng thái đơn hàng' },
          ]}
        >
          <Select
            placeholder='Chọn trạng thái đơn hàng muốn cập nhật'
            allowClear
          >
            <Option value='chochuyenhang'>Chờ gửi hàng</Option>
            <Option value='dagiaohang'>Đã gửi hàng</Option>
            <Option value='danhanhang'>đã nhận hàng</Option>
          </Select>
        </Form.Item>
      </Form>
      <Table
        dataSource={tableData}
        columns={columns}
        rowKey={'id'}
        pagination={false}
        scroll={{ y: 300 }}
      />
    </Modal>
  );
};
MassUpdateModal.propTypes = {
  orders: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  orders: state.Orders.allOrder.data ? state.Orders.allOrder.data : {},
  user: state.AuthRedux.user,
  data: state.Orders,
});
export default connect(mapStateToProps)(MassUpdateModal);
