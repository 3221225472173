import axios from 'axios';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SAVEUSER,
} from './Types';
import { setAlert } from './Alert';
import { getStatistics, clearAllStatistics } from './Statistics';
import { clearAllOrders } from './Order';
import SetAuthToken from '../components/util/SetAuthToken';

//Register User

export const register =
  (email, password, repeatPassword, phone) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ email, password, repeatPassword, phone });
    try {
      const res = await axios.post('/api/users', body, config);
      if (res.data.success) {
        localStorage.setItem('token', res.data.token);
        SetAuthToken(localStorage.token);
        const res1 = await axios.get('/api/auth');
        localStorage.setItem('user', JSON.stringify(res1.data));
        dispatch({
          type: REGISTER_SUCCESS,
          payload: { payload1: res.data, payload2: res1.data },
        });
        dispatch(loadUser());
        dispatch(setAlert('register successed', 'success'));
        if (res1.data.partition) {
          getStatistics();
        }
      }
    } catch (err) {
      const error = err.response.data.error;
      console.log(error);
      if (error) {
        for (let err1 of error) {
          dispatch(setAlert(err1.msg, 'error'));
        }
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

//Load user
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    SetAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get('/api/auth');

    dispatch({ type: USER_LOADED, payload: res.data });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

//save user
export const saveuser = (data) => async (dispatch) => {
  const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body = JSON.stringify({ data });
  try {
    const res = await axios.put('/api/users', body, config);

    if (res.data.success) {
      dispatch({
        type: SAVEUSER,
        payload: { payload1: res.data.msg },
      });
      localStorage.setItem('user', JSON.stringify(res.data.msg));
      dispatch(setAlert('Đã lưu thông tin thành công', 'success'));
    } else {
      dispatch(setAlert(res.data.msg, 'error'));
    }
  } catch (err) {
    let error;
    if (err.response) {
      error = err.response.data.error;
    } else {
      error = err;
    }
    console.log(err.response);
    if (error && Array.isArray(error)) {
      for (let err1 of error) {
        dispatch(setAlert(err1.msg, 'error'));
      }
    } else {
      dispatch(setAlert(error, 'error'));
      // toast.success('It works!');
      // snackActions.error(error);
    }
  }
};

//login user

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post('/api/auth', body, config);
    localStorage.setItem('token', res.data.token);
    SetAuthToken(localStorage.token);
    const res1 = await axios.get('/api/auth');
    localStorage.setItem('user', JSON.stringify(res1.data));
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { payload1: res.data, payload2: res1.data },
    });

    if (res1.data.partition) {
      dispatch(getStatistics());
    }
  } catch (err) {
    let error;
    if (err.response) {
      error = err.response.data.error;
    } else {
      error = err;
    }
    if (error && Array.isArray(error)) {
      for (let err1 of error) {
        dispatch(setAlert(err1.msg, 'error'));
      }
    } else {
      dispatch(setAlert(error, 'error'));
      // toast.success('It works!');
      // snackActions.error(error);
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

//logout and clear all
export const logout = () => (dispatch) => {
  dispatch(clearAllOrders());
  dispatch(clearAllStatistics());
  dispatch({ type: LOGOUT });
};
