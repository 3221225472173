import { GET_STATISTICS, CLEAR_ALL_STATISTICS } from '../action/Types';
import axios from 'axios';
import { setAlert } from './Alert';
export const getStatistics = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.get('/api/baocao/doanhso', config);
    if (res.data.success) {
      dispatch({
        type: GET_STATISTICS,
        payload: { payload: res.data.msg },
      });
    } else {
      dispatch(setAlert(res.msg, 'error'));
    }
  } catch (err) {
    console.log(err.response.data);
    if (err.response.data.error) {
      dispatch(setAlert(err.response.data.error, 'error'));
    }
    if (err.response.data) {
      dispatch(setAlert(err.response.data, 'error'));
    }
  }
};
export const clearAllStatistics = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_STATISTICS, payload: {} });
};
