import React, { useEffect, useState, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../action/Auth';
import {
  MenuOutlined,
  LoginOutlined,
  LogoutOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { Layout, Row, Col, Button } from 'antd';

const Navbar = (props) => {
  const { Header } = Layout,
    history = useHistory(),
    setLogout = async () => {
      await logout();
      history.push('/');
    };
  const {
      auth: { isAuthenticated, loading },
      logout,
      bugerClick,
    } = props,
    current = useLocation(),
    authLinks = (
      <Button
        onClick={setLogout}
        icon={<LogoutOutlined />}
        type='link'
        className='header-link'
        name='login'
      >
        Logout
      </Button>
    ),
    guestLinks = (
      <>
        {' '}
        <Button
          onClick={() => {
            history.push('/register');
          }}
          icon={<FormOutlined />}
          type='link'
          className='header-link'
        >
          Register
        </Button>{' '}
        /
        <Button
          icon={<LoginOutlined />}
          type='link'
          className='header-link'
          onClick={() => {
            history.push('/login');
          }}
        >
          Login
        </Button>{' '}
      </>
    ),
    [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    let cp = '';
    current.pathname === '/login' && (cp = 'Đăng Nhập');
    current.pathname === '/register' && (cp = 'Đăng Ký');
    current.pathname === '/dashboard' && (cp = 'Thống Kê');
    current.pathname === '/orders' && (cp = 'Đơn Hàng');
    current.pathname === '/reports' && (cp = 'Báo Cáo');
    current.pathname === '/products' && (cp = 'Sản Phẩm');
    current.pathname === '/setting' && (cp = 'Cấu Hình');
    current.pathname === '/' && (cp = 'Home');
    setCurrentPage(cp);
  }, [current]);

  return (
    <Header className='site-layout-header'>
      <Row>
        <Col xs={12} lg={3}>
          <MenuOutlined
            style={{ fontSize: '23px', marginLeft: '5px' }}
            onClick={bugerClick}
          />
          <span style={{ marginLeft: '5px' }}>{currentPage.toUpperCase()}</span>
        </Col>
        <Col
          lg={{ span: 6, offset: 15 }}
          xs={12}
          style={{ textAlign: 'right' }}
        >
          {!loading && (
            <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
          )}
        </Col>
      </Row>
    </Header>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.AuthRedux,
});

export default connect(mapStateToProps, { logout })(Navbar);
