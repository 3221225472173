import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Store from '../Store';
import {
  Row,
  Col,
  Button,
  Input,
  Tooltip,
  message,
  Tabs,
  Table,
  Dropdown,
  Menu,
  Card,
  Skeleton,
  Typography,
  Checkbox,
  Select,
  Pagination,
} from 'antd';
import { red, cyan } from '@ant-design/colors';
import {
  PlusCircleOutlined,
  PrinterOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  DownOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  countOrder,
  listAllOrder,
  loadingList,
  listAllSearchOrder,
  noLoadingList,
  addOrder,
  deleteOrder,
  updateOrder,
  updateCounterNewOrder,
  confirmOrder1,
  updateCounter,
} from '../action/Order';
import {
  saveDistrict,
  saveWard,
  changeDistrict,
  changeWard,
  initProvine,
  clearAllProvine,
} from '../action/Provine';
import { getStatistics } from '../action/Statistics';
import MassUpdateModal from './layout/MassUpdateModal';
import OrderModal from './layout/OrderModal';

import { SocketContext } from './context/socket';
import { isMobile } from 'react-device-detect';

const Orders = ({ data, thongKe, user }) => {
  const { Search } = Input,
    { TabPane } = Tabs,
    { Paragraph } = Typography,
    { Option } = Select,
    socket = useContext(SocketContext),
    [selectedOrder, setSelectedOrder] = useState([]),
    [orderId, setOrderId] = useState(null),
    [modalId, setModalId] = useState(null),
    [hasSelected, setHasSelected] = useState(false),
    [currentTablePage, setCurrentTablePage] = useState(1),
    [currentTab, setCurrentTab] = useState('all'),
    [massUpdateModalId, setmassUpdateModalId] = useState(''),
    [screenHeight, setScreenHeight] = useState(window.innerHeight - 330),
    [socketData, setsocketData] = useState({}),
    columns = [
      {
        title: 'Tên Khách',
        dataIndex: 'tenKhach',

        width: 180,
      },
      {
        title: 'Điện Thoại',
        dataIndex: 'dienThoai',

        width: 120,
      },
      {
        title: 'Địa Chỉ',
        dataIndex: 'diaChi',

        width: 350,
        render: (text) => (
          <Tooltip title={text} placement='right'>
            {text.length > 40 ? text.substring(0, 40) + '... ' : text}
          </Tooltip>
        ),
      },
      {
        title: 'Sản Phẩm',
        dataIndex: 'sanPham',

        width: 100,
        align: 'center',
        render: (text) => (
          <Tooltip title={text} placement='right'>
            <Button type='link' style={{ padding: 0 }}>
              Sản Phẩm
            </Button>
          </Tooltip>
        ),
      },
      {
        title: 'Tổng Tiền',
        dataIndex: 'tongTien',
        width: 100,
        align: 'right',
      },
      {
        title: 'Ngày Mua',
        dataIndex: 'ngayMua',
        align: 'center',
        width: 120,
      },
      {
        title: 'VC',
        dataIndex: 'vanChuyen',

        width: 85,
        render: (text) => (
          <span
            className={`${text.donViVanChuyen}${
              text.status === 'dadoisoat' ? '-dadoisoat' : ''
            } `}
          >
            {text.donViVanChuyen.toUpperCase()}
          </span>
        ),
      },
      {
        title: 'Ghi Chú',
        dataIndex: 'ghiChu',

        align: 'center',
      },
    ],
    onSelectChange = (selectedOrder) => {
      setSelectedOrder({ selectedOrder });
      setHasSelected(selectedOrder.length > 0);
    },
    orderSelection = {
      selectedOrder,
      onChange: onSelectChange,
    },
    pageChangehandler = (page, pagesize) => {
      setCurrentTablePage(page);
      if (data.search) {
        const searchTerm = document.querySelector(
          'input[name="searchOrder"]'
        ).value;

        Store.dispatch(loadingList());
        Store.dispatch(listAllSearchOrder(searchTerm, page));
      } else {
        Store.dispatch(loadingList());
        Store.dispatch(listAllOrder(currentTab, page));
      }
    },
    tabOnChangeHandler = (tab) => {
      Store.dispatch(loadingList());
      Store.dispatch(listAllOrder(tab, 1));
      setCurrentTablePage(1);
      setCurrentTab((prev) => {
        return tab;
      });
      setSelectedOrder([]);
      setHasSelected(false);
    },
    clearAllSelectedOrder = () => {
      setHasSelected(false);
      setSelectedOrder([]);
    },
    showNewOrderModal = async (key) => {
      try {
        Store.dispatch(
          initProvine(
            data.allOrder.data[key]
              ? data.allOrder.data[key].provine.cityCode
              : 0,
            data.allOrder.data[key]
              ? data.allOrder.data[key].provine.districtCode
              : 0,
            data.allOrder.data[key].provine
              ? data.allOrder.data[key].provine.wardCode
              : 0,
            data.allOrder.data[key].provine
              ? data.allOrder.data[key].provine.city
              : 0,
            data.allOrder.data[key].provine
              ? data.allOrder.data[key].provine.district
              : 0,
            data.allOrder.data[key].provine
              ? data.allOrder.data[key].provine.ward
              : 0
          )
        );
        data.allOrder.data[key].provine &&
          Store.dispatch(
            saveDistrict(data.allOrder.data[key].provine.cityCode)
          );
        Store.dispatch(saveWard(data.allOrder.data[key].provine.districtCode));

        setOrderId(key);
        setModalId(Math.random());
      } catch (err) {
        console.log(err);
        message.error('cannot load provine data');
      }
    };

  useEffect(() => {
    async function initilizeState() {
      try {
        if (user.partition && user.partition.length > 0) {
          await Store.dispatch(countOrder());
          await Store.dispatch(listAllOrder());
        }
        if (!user.partition) {
          Store.dispatch(noLoadingList());
        }
      } catch (err) {
        console.log(err);
        message.error('Initilize error orrcured');
      }
    }
    initilizeState();
  }, []);

  const thongKeMenu = (
      <Menu>
        <Menu.Item key='1'>
          <Tooltip
            title={
              <>
                <div>
                  Số Đơn:{' '}
                  {thongKe.homnay
                    ? thongKe.homnay.sodon.toLocaleString('vi-VN')
                    : 0}
                </div>
                <div>
                  Doanh Số:{' '}
                  {thongKe.homnay
                    ? thongKe.homnay.doanhthu.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Vốn:{' '}
                  {thongKe.homnay
                    ? thongKe.homnay.von.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Giảm Giá:{' '}
                  {thongKe.homnay
                    ? thongKe.homnay.giamgia.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Lợi Nhuận:{' '}
                  {thongKe.homnay
                    ? thongKe.homnay.loinhuan.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
              </>
            }
            placement='right'
          >
            Hôm Nay
          </Tooltip>
        </Menu.Item>
        <Menu.Item key='2'>
          {' '}
          <Tooltip
            title={
              <>
                <div>
                  Số Đơn:{' '}
                  {thongKe.homqua
                    ? thongKe.homqua.sodon.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Doanh Số:{' '}
                  {thongKe.homqua
                    ? thongKe.homqua.doanhthu.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Vốn:{' '}
                  {thongKe.homqua
                    ? thongKe.homqua.von.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Giảm Giá:{' '}
                  {thongKe.homqua
                    ? thongKe.homqua.giamgia.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Lợi Nhuận:{' '}
                  {thongKe.homqua
                    ? thongKe.homqua.loinhuan.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
              </>
            }
            placement='right'
          >
            Hôm Qua
          </Tooltip>
        </Menu.Item>
        <Menu.Item key='3'>
          <Tooltip
            title={
              <>
                <div>
                  Số Đơn:{' '}
                  {thongKe.tuannay
                    ? thongKe.tuannay.sodon.toLocaleString('vi-VN')
                    : 0}
                </div>
                <div>
                  Doanh Số:{' '}
                  {thongKe.tuannay
                    ? thongKe.tuannay.doanhthu.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Vốn:{' '}
                  {thongKe.tuannay
                    ? thongKe.tuannay.von.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Giảm Giá:{' '}
                  {thongKe.tuannay
                    ? thongKe.tuannay.giamgia.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Lợi Nhuận:{' '}
                  {thongKe.tuannay
                    ? thongKe.tuannay.loinhuan.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
              </>
            }
            placement='right'
          >
            Tuần Này
          </Tooltip>
        </Menu.Item>
        <Menu.Item key='4'>
          <Tooltip
            title={
              <>
                <div>
                  Số Đơn:{' '}
                  {thongKe.thangnay
                    ? thongKe.thangnay.sodon.toLocaleString('vi-VN')
                    : 0}
                </div>
                <div>
                  Doanh Số:{' '}
                  {thongKe.thangnay
                    ? thongKe.thangnay.doanhthu.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Vốn:{' '}
                  {thongKe.thangnay
                    ? thongKe.thangnay.von.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Giảm Giá:{' '}
                  {thongKe.thangnay
                    ? thongKe.thangnay.giamgia.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Lợi Nhuận:{' '}
                  {thongKe.thangnay
                    ? thongKe.thangnay.loinhuan.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
              </>
            }
            placement='right'
          >
            Tháng Này
          </Tooltip>
        </Menu.Item>
        <Menu.Item key='5'>
          <Tooltip
            title={
              <>
                <div>
                  Số Đơn:{' '}
                  {thongKe.thangtruoc
                    ? thongKe.thangtruoc.sodon.toLocaleString('vi-VN')
                    : 0}
                </div>
                <div>
                  Doanh Số:{' '}
                  {thongKe.thangtruoc
                    ? thongKe.thangtruoc.doanhthu.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Vốn:{' '}
                  {thongKe.thangtruoc
                    ? thongKe.thangtruoc.von.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Giảm Giá:{' '}
                  {thongKe.thangtruoc
                    ? thongKe.thangtruoc.giamgia.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
                <div>
                  Lợi Nhuận:{' '}
                  {thongKe.thangtruoc
                    ? thongKe.thangtruoc.loinhuan.toLocaleString('vi-VN')
                    : 0}{' '}
                  đ
                </div>
              </>
            }
            placement='right'
          >
            Tháng Trước
          </Tooltip>
        </Menu.Item>
      </Menu>
    ),
    handleCreateNewOrder = () => {
      try {
        Store.dispatch(clearAllProvine());
        Store.dispatch(initProvine(0, 0, 0, '', '', ''));
        setOrderId('new');
        setModalId(Math.random());
      } catch (err) {
        console.log(err);
        message.error('cannot load provine data');
      }
    },
    handleOrderSearch = (e) => {
      setCurrentTablePage(1);
      try {
        if (e) {
          Store.dispatch(loadingList());
          Store.dispatch(listAllSearchOrder(e));
        } else {
          Store.dispatch(loadingList());
          Store.dispatch(listAllOrder());
        }
      } catch (err) {
        console.log(err);
        message.error('Error while doing search');
      }
    },
    handleMassUpdateOrder = () => {
      setmassUpdateModalId(Math.random());
    };

  useEffect(() => {
    socket.on();
    socket.on('disconnect', function () {
      console.log('socket disconnected');
    });
    socket.on('connect', () => {
      console.log('socket connected');
      const username = Store.getState().AuthRedux.user._id,
        roomname = Store.getState().AuthRedux.user.partition;
      if (username && roomname) {
        socket.emit('joinRoom', { username: username, room: roomname });
      }
    });
    socket.on('reconnect', () => {
      console.log('socket reconnected');

      const username = Store.getState().AuthRedux.user._id,
        roomname = Store.getState().AuthRedux.user.partition;
      if (username && roomname) {
        socket.emit('joinRoom', { username: username, room: roomname });
      }
    });

    socket.on('newOrder', (socketPayload) => {
      setsocketData({ type: 'newOrder', ...socketPayload });
    });
    socket.on('changeOrderStatus', (socketPayload) => {
      console.log(socketPayload);
      setsocketData({ type: 'changeOrderStatus', ...socketPayload });
    });
  }, []);
  useEffect(() => {
    if (socketData.type === 'newOrder') {
      Store.dispatch(getStatistics());
      if (data.currentTab === 'all') {
        Store.dispatch(addOrder(socketData.payload));
      }
      if (data.currentTab === socketData.payload.status) {
        Store.dispatch(addOrder(socketData.payload));
      }
      Store.dispatch(updateCounterNewOrder(socketData.payload.status));
    }
    if (socketData.type === 'changeOrderStatus') {
      if (socketData.payload.newStatus === 'daxacnhan') {
        if (data.currentTab === 'all') {
          Store.dispatch(
            updateOrder(
              socketData.payload.data,
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        } else if (
          data.currentTab === 'daxacnhan' &&
          socketData.payload.newStatus === 'daxacnhan'
        ) {
          console.log('hehe');
          Store.dispatch(
            confirmOrder1(
              socketData.payload.data,
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        } else if (
          data.currentTab !== 'daxacnhan' &&
          socketData.payload.newStatus === 'daxacnhan'
        ) {
          Store.dispatch(
            updateCounter(
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        } else if (
          data.currentTab !== 'daxacnhan' &&
          data.currentTab === socketData.payload.oldStatus
        ) {
          Store.dispatch(
            deleteOrder(
              socketData.payload.data._id,
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        } else if (
          data.currentTab !== 'daxacnhan' &&
          data.currentTab === socketData.payload.newStatus
        ) {
          Store.dispatch(addOrder(socketData.payload.data));
          Store.dispatch(
            updateCounter(
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        }
      } else {
        if (data.currentTab === 'all') {
          Store.dispatch(
            updateOrder(
              socketData.payload.data,
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        } else if (
          data.currentTab === socketData.payload.oldStatus &&
          data.currentTab !== socketData.payload.newStatus
        ) {
          Store.dispatch(
            deleteOrder(
              socketData.payload.data._id,
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        } else if (
          data.currentTab === socketData.payload.newStatus &&
          data.currentTab !== socketData.payload.oldStatus
        ) {
          Store.dispatch(addOrder(socketData.payload.data));
          Store.dispatch(
            updateCounter(
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        } else if (
          data.currentTab === socketData.payload.newStatus &&
          data.currentTab === socketData.payload.oldStatus
        ) {
          Store.dispatch(
            updateOrder(
              socketData.payload.data,
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        } else {
          Store.dispatch(
            updateCounter(
              socketData.payload.oldStatus,
              socketData.payload.newStatus
            )
          );
        }
        if (socketData.payload.newStatus === 'dahuy') {
          Store.dispatch(getStatistics());
        }
      }
    }
  }, [socketData]);
  useEffect(() => {
    function handleResize() {
      setScreenHeight(window.innerHeight - 330);
    }
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const handlePrintOrder = () => {
    const listOrderPrint = selectedOrder.selectedOrder.toString();

    window.open(
      `/api/orders/print?token=${localStorage.getItem(
        'token'
      )}&orderids=${listOrderPrint}`
    );
  };
  const selectChangehandler = (e) => {
    Store.dispatch(loadingList());
    Store.dispatch(listAllOrder(e, 1));
    setCurrentTablePage(1);
    setCurrentTab((prev) => {
      return e;
    });
    setSelectedOrder({ selectedOrder: [] });
    setHasSelected(false);
  };
  const onCheckAllChange = (e) => {
    console.log(e.target);
    if (e.target.checked === true) {
      setSelectedOrder((prev) => {
        const selectedArray = [];
        for (let val of data.allOrdersTable) {
          if (val.vanChuyen.status !== 'dahuy') selectedArray.push(val.key);
        }
        return { selectedOrder: [...selectedArray] };
      });
    } else {
      setSelectedOrder([]);
    }
    setHasSelected(e.target.checked);
  };
  const onGroupChange = (e) => {
    const index = selectedOrder.selectedOrder
      ? selectedOrder.selectedOrder.indexOf(e.target.value)
      : -1;

    // if (selectedOrder.selectedOrder.indexOf(e.target.valu) === -1) {
    //   setSelectedOrder((prev) =>
    //    ( selectedOrder: [...prev.selectedOrder, e.target.value];
    //   };
    // }
    if (index > -1) {
      const reducedArr = selectedOrder;
      reducedArr.selectedOrder.splice(index, 1);
      setSelectedOrder({ ...reducedArr });
    }
    if (index === -1) {
      const reducedArr = selectedOrder;
      if (!reducedArr.selectedOrder) {
        reducedArr.selectedOrder = [];
      }
      reducedArr.selectedOrder.push(e.target.value);
      setSelectedOrder({ ...reducedArr });
    }
    setHasSelected(selectedOrder.selectedOrder.length > 0);
  };
  const hehe = (e, key) => {
    console.log(e.target.parentElement.classList);

    if (
      Array.from(e.target.parentElement.classList).indexOf(
        'ant-typography-ellipsis'
      ) === -1
    ) {
      showNewOrderModal(key);
    }
  };
  if (isMobile) {
    return (
      <>
        <MassUpdateModal
          selectedOrder={{ ...selectedOrder }}
          massUpdateModalId={massUpdateModalId}
          clearAllSelectedOrder={clearAllSelectedOrder}
        />
        <OrderModal
          modalId={modalId}
          orderId={orderId}
          currentTab={currentTab}
        />
        <Row style={{ padding: '0 5px 5px 0' }}>
          <Search
            placeholder='Tên khách hàng, số điện thoại, mã đơn hàng,...'
            enterButton
            name='searchOrder'
            onSearch={(e) => {
              handleOrderSearch(e);
            }}
          />
        </Row>
        <Row
          align='middle'
          style={{
            padding: '10px 5px',
            marginBottom: '8px',
            backgroundImage:
              'radial-gradient( circle 610px at 5.2% 51.6%,  rgba(5,8,114,1) 0%, rgba(7,3,53,1) 97.5% )',
          }}
        >
          <Col xs={10}>
            <Button
              shape='circle'
              size='default'
              type='primary'
              icon={<PlusOutlined style={{ fontSize: '160%' }} />}
              style={{
                marginRight: '8px',
                marginLeft: '5px',
                marginBottom: '5px',
                backgroundColor: '#cf1322',
                border: 'none',
              }}
              onClick={handleCreateNewOrder}
            ></Button>
            <Button
              shape='circle'
              size='default'
              type='primary'
              disabled={!hasSelected}
              onClick={handlePrintOrder}
              icon={
                <PrinterOutlined
                  style={{
                    fontSize: '150%',
                    paddingTop: '2px',
                    color: '#fafafa',
                  }}
                />
              }
              style={{
                marginRight: '8px',
                marginLeft: '5px',
                marginBottom: '5px',
                backgroundColor: '#389e0d',
                border: 'none',
              }}
            ></Button>
            <Button
              shape='circle'
              size='default'
              type='primary'
              icon={
                <CheckOutlined
                  style={{ fontSize: '140%', paddingTop: '3px' }}
                />
              }
              style={{
                marginRight: '8px',
                marginLeft: '5px',

                border: 'none',
              }}
              disabled={!hasSelected}
              onClick={handleMassUpdateOrder}
            ></Button>
          </Col>
          <Col xs={14} style={{ textAlign: 'right', paddingRight: '8px' }}>
            <Dropdown overlay={thongKeMenu}>
              <Button className='thong-ke-dropdown' shape='round' size='middle'>
                Thống Kê <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>

        <Row
          style={{
            padding: '8px',
            backgroundColor: '#f6e58d',
            marginBottom: '8px',
          }}
          align='middle'
        >
          <Col xs={2} style={{ textAlign: 'center' }}>
            <Checkbox onChange={onCheckAllChange} checked={hasSelected} />
          </Col>
          <Col xs={20} style={{ marginLeft: '16px' }}>
            <Select
              onChange={selectChangehandler}
              defaultValue='all'
              style={{ width: '230px' }}
            >
              <Option value='all'>Tất cả đơn hàng ({data.sodon.total})</Option>
              <Option value='moi'>Đơn hàng mới ({data.sodon.moi})</Option>
              <Option value='daxacnhan'>
                Đã xác nhận ({data.sodon.daxacnhan})
              </Option>
              <Option value='chochuyenhang'>
                Chờ gửi hàng ({data.sodon.chochuyenhang})
              </Option>
              <Option value='dagiaohang'>
                Đã gửi hàng ({data.sodon.dagiaohang})
              </Option>
              <Option value='danhanhang'>
                Đã nhận hàng ({data.sodon.danhanhang})
              </Option>
              <Option value='dadoisoat'>
                Đã đối soát ({data.sodon.dadoisoat})
              </Option>
              <Option value='dahuy'>Đã huỷ ({data.sodon.dahuy})</Option>
            </Select>
          </Col>
        </Row>
        <Skeleton loading={data.tableLoading} active paragraph={{ rows: 10 }}>
          <Checkbox.Group
            style={{
              width: '100%',
              overflowY: 'scroll',
              height: screenHeight + 50,
            }}
            value={selectedOrder.selectedOrder}
          >
            {data.allOrdersTable.map((item, index) => {
              let bgColor = '';
              if (
                selectedOrder.selectedOrder &&
                selectedOrder.selectedOrder.includes(item.key)
              ) {
                bgColor = '#e6f7ff';
              }
              if (item.vanChuyen.status === 'dahuy') {
                bgColor = '#d9d9d9';
              }
              return (
                <Row key={item.key + 'row'} id={item.key + 'row'}>
                  <Card
                    key={item.key}
                    id={item.key + 'card'}
                    style={{
                      width: '100%',
                      boxShadow: '0px 2px 18px 0px rgb(0 0 0 / 50%)',
                      border:
                        item.vanChuyen.status === 'dahuy'
                          ? '1px solid #bfbfbf'
                          : '1px solid white',
                      borderRadius: '5px',
                      marginBottom: '5px',
                      backgroundColor: bgColor,
                    }}
                    bodyStyle={{ padding: '5px' }}
                  >
                    <Row align='middle'>
                      <Col xs={2} style={{ textAlign: 'center' }}>
                        <Checkbox
                          value={item.key}
                          disabled={
                            item.vanChuyen.status === 'dahuy' ? true : false
                          }
                          id={item.key}
                          onChange={onGroupChange}
                        ></Checkbox>
                      </Col>
                      <Col
                        xs={22}
                        onClick={(e) => {
                          hehe(e, item.key);
                        }}
                        data-id={`${item.key}`}
                      >
                        <p
                          style={{ padding: 0, margin: 0, fontWeight: 'bold' }}
                        >
                          {item.tenKhach} |{' '}
                          <span style={{ color: '#0050b3' }}>
                            {item.dienThoai}
                          </span>
                        </p>
                        {item.vanChuyen.vanDon && (
                          <p style={{ padding: 0, margin: 0 }}>
                            Mã Vận Đơn:{' '}
                            <span
                              style={{ fontWeight: 'bold', color: '#a8071a' }}
                            >
                              {item.vanChuyen.vanDon}
                            </span>
                          </p>
                        )}
                        <Tooltip title={item.diaChi}>
                          <Paragraph
                            ellipsis={{ rows: 1 }}
                            style={{ padding: 0, margin: 0 }}
                          >
                            Địa Chỉ:{' '}
                            <span style={{ color: '#08979c' }}>
                              {item.diaChi}
                            </span>
                          </Paragraph>
                        </Tooltip>
                        <Tooltip title={item.sanPham}>
                          <Paragraph
                            ellipsis={{ rows: 1 }}
                            style={{ padding: 0, margin: 0 }}
                          >
                            Sản phẩm:{' '}
                            <span style={{ color: '#08979c' }}>
                              {item.sanPham}
                            </span>
                          </Paragraph>
                        </Tooltip>
                        <p style={{ padding: 0, margin: 0 }}>
                          Tiền Hàng:{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {item.tongTien.toLocaleString('vi-VN')} đ
                          </span>
                        </p>
                        <p style={{ padding: 0, margin: 0 }}>
                          Ngày đặt hàng: {item.ngayMua}
                        </p>
                        {item.ghiChu && item.ghiChu.trim() && (
                          <p style={{ padding: 0, margin: 0 }}>
                            Ghi Chú:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              {item.ghiChu}
                            </span>
                          </p>
                        )}
                        {item.vanChuyen.donViVanChuyen && (
                          <p style={{ padding: 0, margin: 0 }}>
                            Đơn vị vận chuyển:{' '}
                            <span className={item.vanChuyen.donViVanChuyen}>
                              {item.vanChuyen.donViVanChuyen}
                            </span>
                          </p>
                        )}
                        <p style={{ padding: 0, margin: 0 }}>
                          Trạng thái đơn hàng:{' '}
                          <span
                            style={{
                              color:
                                item.vanChuyen.status === 'moi'
                                  ? '#fa541c'
                                  : item.vanChuyen.status === 'dahuy'
                                  ? '#fafafa'
                                  : '#08979c',
                              fontWeight:
                                item.vanChuyen.status === 'moi'
                                  ? 'bold'
                                  : item.vanChuyen.status === 'daxacnhan'
                                  ? 'bold'
                                  : '',
                            }}
                          >
                            {item.vanChuyen.status === 'moi'
                              ? 'Đơn hàng Mới'
                              : item.vanChuyen.status === 'dahuy'
                              ? 'Đã huỷ'
                              : item.vanChuyen.status === 'daxacnhan'
                              ? 'Đã xác nhận'
                              : item.vanChuyen.status === 'chochuyenhang'
                              ? 'Chờ chuyển hàng'
                              : item.vanChuyen.status === 'dagiaohang'
                              ? 'Đã giao hàng'
                              : item.vanChuyen.status === 'danhanhang'
                              ? 'Đã nhận hàng'
                              : item.vanChuyen.status === 'dadoisoat'
                              ? 'Đã đối soát'
                              : ''}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              );
            })}
          </Checkbox.Group>
        </Skeleton>
        <Pagination
          defaultPageSize={30}
          total={data.allOrder.count}
          showSizeChanger={false}
          position={['bottomCenter']}
          onChange={pageChangehandler}
          showQuickJumper={true}
          size='large'
          current={currentTablePage}
          hideOnSinglePage={true}
          style={{
            position: 'fixed',
            bottom: '5px',
            zIndex: 2,
            backgroundColor: '#f5f5f5',
            padding: '10px 5px',
            textAlign: 'center',
            width: '100%',
          }}
          responsive={true}
        />
      </>
    );
  }
  return (
    <>
      <MassUpdateModal
        selectedOrder={{ ...selectedOrder }}
        massUpdateModalId={massUpdateModalId}
        clearAllSelectedOrder={clearAllSelectedOrder}
      />

      <OrderModal modalId={modalId} orderId={orderId} currentTab={currentTab} />
      <Row className='orders-head-row' style={{ backgroundColor: cyan[6] }}>
        <Col xs={24} md={24} xl={6} lg={6} className='orders-search-col'>
          <Search
            placeholder='Customer name, phone, address,...'
            enterButton
            name='searchOrder'
            onSearch={(e) => {
              handleOrderSearch(e);
            }}
          />
        </Col>

        <Col xs={0} md={0} xl={18} lg={18} className='orders-button-col'>
          <Button
            type='default'
            shape='round'
            icon={<PlusCircleOutlined />}
            size='middle'
            className='orders-head-action-button'
            onClick={handleCreateNewOrder}
          >
            Tạo Đơn
          </Button>
          <Button
            type='default'
            shape='round'
            disabled={!hasSelected}
            onClick={handlePrintOrder}
            icon={<PrinterOutlined />}
            size='middle'
            className='orders-head-action-button'
          >
            In Đơn
          </Button>
          <Button
            type='primary'
            shape='round'
            icon={<CheckCircleOutlined />}
            size='middle'
            className='orders-head-action-button'
            disabled={!hasSelected}
            onClick={handleMassUpdateOrder}
          >
            Cập Nhật Đơn
          </Button>
          <span
            style={{
              marginLeft: 8,
              color: '#c2136b',
              fontWeight: 600,
              marginRight: '16px',
            }}
            className='so-don-hang-da-chon'
          >
            {hasSelected && selectedOrder.selectedOrder
              ? `Đã chọn ${selectedOrder.selectedOrder.length} đơn hàng`
              : ''}
          </span>
          <Dropdown overlay={thongKeMenu}>
            <Button className='thong-ke-dropdown' shape='round'>
              Thống Kê <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
        <Col xs={24} md={24} lg={0} className='orders-button-col'>
          <Button
            type='default'
            shape='round'
            size='small'
            icon={<PlusOutlined />}
            style={{
              backgroundColor: red[6],
              border: `1px solid ${red[6]}`,
            }}
            className='mobile-orders-head-button'
          >
            <span style={{ marginLeft: '3px !important' }}>Tạo Đơn Hàng</span>
          </Button>
          <Button
            type='default'
            shape='round'
            size='small'
            style={{
              backgroundColor: red[6],
              border: `1px solid ${red[6]}`,
            }}
            className='mobile-orders-head-button'
          >
            <PrinterOutlined /> In Đơn Hàng
          </Button>
          <Button
            type='default'
            shape='round'
            size='small'
            style={{
              backgroundColor: red[6],
              border: `1px solid ${red[6]}`,
            }}
            className='mobile-orders-head-button'
          >
            Update
          </Button>
        </Col>
      </Row>
      <Row>
        <Col
          xs={0}
          md={0}
          lg={24}
          style={{ marginTop: '5px', backgroundColor: 'white' }}
        >
          <Tabs type='card' onChange={tabOnChangeHandler} size='large'>
            <TabPane
              tab={`Tất Cả Đơn Hàng (${
                data.sodon && data.sodon.total ? data.sodon.total : 0
              })`}
              key='all'
              className='orders-status-tab'
            >
              <Table
                rowSelection={orderSelection}
                columns={columns}
                dataSource={data.allOrdersTable}
                loading={data.tableLoading}
                locale='vi-VN'
                pagination={{
                  defaultPageSize: 30,
                  total: data.allOrder.count,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  onChange: pageChangehandler,
                  size: 'large',
                  showQuickJumper: true,
                  current: currentTablePage,
                  hideOnSinglePage: true,
                  style: { margin: '8px 0' },
                }}
                rowClassName={(record, index) => {
                  if (record.vanChuyen.status === 'dahuy') {
                    return index % 2 === 0
                      ? 'don-hang-da-huy table-row-light order-list-table-row'
                      : 'don-hang-da-huy table-row-dark order-list-table-row';
                  } else {
                    return index % 2 === 0
                      ? 'table-row-light order-list-table-row'
                      : 'table-row-dark order-list-table-row';
                  }
                }}
                size='middle'
                onRow={(record) => ({
                  onClick: () => {
                    showNewOrderModal(record.key);
                  },
                })}
                showHeader={true}
                scroll={{ y: screenHeight, x: 'max-content' }}
              />
            </TabPane>
            <TabPane
              tab={`Đơn Hàng Mới (${
                data.sodon && data.sodon.moi ? data.sodon.moi : 0
              })`}
              key='moi'
            >
              <Table
                rowSelection={orderSelection}
                columns={columns}
                dataSource={data.allOrdersTable}
                loading={data.tableLoading}
                locale='VI-VN'
                pagination={{
                  defaultPageSize: 30,
                  total: data.allOrder.count,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  onChange: pageChangehandler,
                  size: 'large',
                  showQuickJumper: true,
                  current: currentTablePage,
                  hideOnSinglePage: true,
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                size='middle'
                onRow={(record) => ({
                  onClick: () => {
                    showNewOrderModal(record.key);
                  },
                })}
                showHeader={true}
                scroll={{ y: screenHeight, x: 'max-content' }}
              />
            </TabPane>
            <TabPane
              tab={`Đã Xác Nhận (${
                data.sodon && data.sodon.daxacnhan ? data.sodon.daxacnhan : 0
              })`}
              key='daxacnhan'
            >
              <Table
                rowSelection={orderSelection}
                columns={columns}
                dataSource={data.allOrdersTable}
                loading={data.tableLoading}
                locale='VI-VN'
                pagination={{
                  defaultPageSize: 30,
                  total: data.allOrder.count,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  onChange: pageChangehandler,
                  size: 'large',
                  showQuickJumper: true,
                  current: currentTablePage,
                  hideOnSinglePage: true,
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                size='middle'
                onRow={(record) => ({
                  onClick: () => {
                    showNewOrderModal(record.key);
                  },
                })}
                showHeader={true}
                scroll={{ y: screenHeight, x: 'max-content' }}
              />
            </TabPane>
            <TabPane
              tab={`Chờ Chuyển Hàng (${
                data.sodon && data.sodon.chochuyenhang
                  ? data.sodon.chochuyenhang
                  : 0
              })`}
              key='chochuyenhang'
            >
              <Table
                rowSelection={orderSelection}
                columns={columns}
                dataSource={data.allOrdersTable}
                loading={data.tableLoading}
                locale='VI-VN'
                pagination={{
                  defaultPageSize: 30,
                  total: data.allOrder.count,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  onChange: pageChangehandler,
                  size: 'large',
                  showQuickJumper: true,
                  current: currentTablePage,
                  hideOnSinglePage: true,
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                size='middle'
                onRow={(record) => ({
                  onClick: () => {
                    showNewOrderModal(record.key);
                  },
                })}
                showHeader={true}
                scroll={{ y: screenHeight, x: 'max-content' }}
              />
            </TabPane>
            <TabPane
              tab={`Đã Giao Hàng (${
                data.sodon && data.sodon.dagiaohang ? data.sodon.dagiaohang : 0
              })`}
              key='dagiaohang'
            >
              <Table
                rowSelection={orderSelection}
                columns={columns}
                dataSource={data.allOrdersTable}
                loading={data.tableLoading}
                locale='VI-VN'
                pagination={{
                  defaultPageSize: 30,
                  total: data.allOrder.count,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  onChange: pageChangehandler,
                  size: 'large',
                  showQuickJumper: true,
                  current: currentTablePage,
                  hideOnSinglePage: true,
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                size='middle'
                onRow={(record) => ({
                  onClick: () => {
                    showNewOrderModal(record.key);
                  },
                })}
                showHeader={true}
                scroll={{ y: screenHeight, x: 'max-content' }}
              />
            </TabPane>
            <TabPane
              tab={`Đã Nhận Hàng (${
                data.sodon && data.sodon.danhanhang ? data.sodon.danhanhang : 0
              })`}
              key='danhanhang'
            >
              <Table
                rowSelection={orderSelection}
                columns={columns}
                dataSource={data.allOrdersTable}
                loading={data.tableLoading}
                locale='VI-VN'
                pagination={{
                  defaultPageSize: 30,
                  total: data.allOrder.count,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  onChange: pageChangehandler,
                  size: 'large',
                  showQuickJumper: true,
                  current: currentTablePage,
                  hideOnSinglePage: true,
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                size='middle'
                onRow={(record) => ({
                  onClick: () => {
                    showNewOrderModal(record.key);
                  },
                })}
                showHeader={true}
                scroll={{ y: screenHeight, x: 'max-content' }}
              />
            </TabPane>
            <TabPane
              tab={`Đã Đối Soát (${
                data.sodon && data.sodon.dadoisoat ? data.sodon.dadoisoat : 0
              })`}
              key='dadoisoat'
            >
              <Table
                rowSelection={orderSelection}
                columns={columns}
                dataSource={data.allOrdersTable}
                loading={data.tableLoading}
                locale='VI-VN'
                pagination={{
                  defaultPageSize: 30,
                  total: data.allOrder.count,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  onChange: pageChangehandler,
                  size: 'large',
                  showQuickJumper: true,
                  current: currentTablePage,
                  hideOnSinglePage: true,
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                size='middle'
                onRow={(record) => ({
                  onClick: () => {
                    showNewOrderModal(record.key);
                  },
                })}
                showHeader={true}
                scroll={{ y: screenHeight, x: 'max-content' }}
              />
            </TabPane>
            <TabPane
              tab={`Đã Huỷ (${
                data.sodon && data.sodon.dahuy ? data.sodon.dahuy : 0
              })`}
              key='dahuy'
            >
              <Table
                rowSelection={orderSelection}
                columns={columns}
                dataSource={data.allOrdersTable}
                loading={data.tableLoading}
                locale='VI-VN'
                pagination={{
                  defaultPageSize: 30,
                  total: data.allOrder.count,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  onChange: pageChangehandler,
                  size: 'large',
                  showQuickJumper: true,
                  current: currentTablePage,
                  hideOnSinglePage: true,
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                size='middle'
                onRow={(record) => ({
                  onClick: () => {
                    showNewOrderModal(record.key);
                  },
                })}
                showHeader={true}
                scroll={{ y: screenHeight, x: 'max-content' }}
              />
            </TabPane>
          </Tabs>
        </Col>
        <Col xs={24} md={24} lg={0}></Col>
      </Row>
    </>
  );
};

Orders.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  provine: PropTypes.object.isRequired,
  thongKe: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.AuthRedux.user,
  data: state.Orders,
  provine: state.Provine,
  thongKe: state.Statistics,
});

export default connect(mapStateToProps, {
  saveDistrict,
  saveWard,
  changeDistrict,
  changeWard,
  initProvine,
  listAllSearchOrder,
})(Orders);
