import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Typography, Table, Layout, Card, Tooltip } from 'antd';
import {
  DollarCircleTwoTone,
  PropertySafetyTwoTone,
  ShoppingTwoTone,
  TagsTwoTone,
} from '@ant-design/icons';
import { Line, Column } from '@ant-design/charts';
import axios from 'axios';
import { SocketContext } from './context/socket';

const Dashboard = ({ user }) => {
  const [dailyReport, setDailyReport] = useState({
    profit: 0,
    sales: 0,
    totalOrder: 0,
    newOrder: 0,
  });
  const [monthlyReport, setMonthlyReport] = useState([
    {
      name: '',
      ngay: '',
      giatri: '',
    },
  ]);
  const [topSP, setTopSP] = useState({
    loading: true,
    table: [{ id: 0, productName: '', sold: 0 }],
  });
  const [topKH, setTopKH] = useState({
    loading: true,
    table: [{ id: 0, name: '', phone: 0, purchased: 0 }],
  });

  const [soDonReport, setSoDonReport] = useState([{ ngay: '', sodon: 0 }]);
  const socket = useContext(SocketContext);
  const { Title } = Typography;
  const today = moment().utc().startOf('day').subtract(7, 'h').toDate();

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month].join('/');
  };
  const todayFormated = formatDate(today);
  const monthlyReportLineChartConfig = {
    data: monthlyReport,
    xField: 'ngay',
    yField: 'giatri',
    seriesField: 'name',
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return v.toLocaleString('vi-VN') + 'đ';
        },
      },
    },
    legend: { position: 'top' },
    smooth: true,
    height: 300,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 1500,
      },
    },
  };

  const totalOrderReportAreaChartConfig = {
    data: soDonReport,
    height: 250,
    xField: 'ngay',
    yField: 'sodon',
    smooth: true,

    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
      animation: {
        appear: {
          animation: 'path-in',
          duration: 1500,
        },
      },
    },
  };
  const columns = [
    {
      title: 'Tên Sản Phẩm',
      dataIndex: 'productName',
      key: 'productName',
      className: 'productname-col',
      ellipsis: true,

      render: (text) => (
        <Tooltip title={text} placement='right'>
          <span style={{ marginLeft: '10px' }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Đã Bán',
      dataIndex: 'sold',
      className: 'sold-col',
      render: (text) => {
        return (
          <span style={{ marginRight: '10px' }}>
            {text && text.toLocaleString('vi-VN')}
          </span>
        );
      },
      align: 'right',
    },
  ];
  const columnsKH = [
    {
      title: 'Họ Tên',
      dataIndex: 'name',
      key: 'name',
      className: 'name-col',
      ellipsis: true,
      width: '43%',
      render: (text) => (
        <Tooltip title={text} placement='right'>
          <span style={{ marginLeft: '10px' }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Số ĐT',
      dataIndex: 'phone',
      className: 'phone-col',
      width: '27%',
      align: 'center',
    },
    {
      title: 'Số Lần Mua',
      dataIndex: 'purchased',
      className: 'purchased-col',
      align: 'right',
      render: (text) => {
        return (
          <span style={{ marginRight: '10px' }}>
            {text.toLocaleString('vi-VN')}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    socket.emit('joinRoom', { username: user._id, room: user.partition });
    const getDailyReport = async () => {
      const getDailyReportResult = await axios.get(
        `/api/baocao/baocaongay`,
        config
      );
      setDailyReport({
        profit: getDailyReportResult.data.result.loinhuan,
        sales: getDailyReportResult.data.result.doanhthu,
        totalOrder: getDailyReportResult.data.result.sodon,
        newOrder: getDailyReportResult.data.result.moi,
      });
    };
    const getMonthlyReport = async () => {
      const getMonthlyReportResult = await axios.get(
        `/api/baocao/baocaothang`,
        config
      );

      setMonthlyReport(getMonthlyReportResult.data.baocao1);
      setSoDonReport(getMonthlyReportResult.data.result);
    };
    const getTopProduct = async () => {
      const getTopProductResult = await axios.get(
        `/api/baocao/topsanphambanchay`
      );
      setTopSP({ loading: false, table: [...getTopProductResult.data.result] });
    };
    const getTopCustomers = async () => {
      const getTopCustomerResult = await axios.get(
        `/api/baocao/topkhachang`,
        config
      );
      if (getTopCustomerResult.data.result.length > 0) {
        for (let val in getTopCustomerResult.data.result) {
          getTopCustomerResult.data.result[
            val
          ].name = `${getTopCustomerResult.data.result[val].lastName} ${getTopCustomerResult.data.result[val].firstName}`;
        }
        setTopKH({
          loading: false,
          table: [...getTopCustomerResult.data.result],
        });
      }
    };
    getDailyReport();
    getMonthlyReport();
    getTopProduct();
    getTopCustomers();
  }, []);

  useEffect(() => {
    socket.on('newOrder', (socketPayload) => {
      setDailyReport((prev) => ({
        ...prev,
        profit:
          prev.profit +
          (socketPayload.payload.value -
            socketPayload.payload.von -
            socketPayload.payload.discount),
        sales: prev.sales + socketPayload.payload.value,
        totalOrder: prev.totalOrder + 1,
        newOrder:
          socketPayload.payload.status === 'moi'
            ? prev.newOrder + 1
            : prev.newOrder,
      }));

      setMonthlyReport((prev) => {
        const index = prev.findIndex(
          (p) => p.ngay === todayFormated && p.name === 'Doanh Số'
        );
        prev[index].giatri = prev[index].giatri + socketPayload.payload.value;
        const index1 = prev.findIndex(
          (p) => p.ngay === todayFormated && p.name === 'Lợi Nhuận'
        );
        prev[index1].giatri =
          prev[index1].giatri +
          (socketPayload.payload.value -
            socketPayload.payload.von -
            socketPayload.payload.discount);
        return [...prev];
      });
      setSoDonReport((prev) => {
        const index = prev.findIndex((p) => p.ngay === todayFormated);
        prev[index].sodon = prev[index].sodon + 1;
        return [...prev];
      });
    });
    socket.on('changeOrderStatus', (socketPayload) => {
      if (
        socketPayload.payload.newStatus === 'daxacnhan' &&
        socketPayload.payload.oldStatus === 'moi'
      ) {
        setDailyReport((prev) => ({
          ...prev,
          newOrder: prev.newOrder - 1,
        }));
      }
      if (socketPayload.payload.newStatus === 'dahuy') {
        setDailyReport((prev) => ({
          ...prev,
          profit:
            prev.profit -
            (socketPayload.payload.data.value -
              socketPayload.payload.data.von -
              socketPayload.payload.data.discount),
          sales: prev.sales - socketPayload.payload.data.value,
          totalOrder: prev.totalOrder - 1,
          newOrder:
            socketPayload.payload.oldStatus === 'moi'
              ? prev.newOrder - 1
              : prev.newOrder,
        }));
        const ngaycuadon = formatDate(socketPayload.payload.data.date);
        setMonthlyReport((prev) => {
          const index = prev.findIndex(
            (p) => p.ngay === ngaycuadon && p.name === 'Doanh Số'
          );
          prev[index].giatri =
            prev[index].giatri - socketPayload.payload.data.value;
          const index1 = prev.findIndex(
            (p) => p.ngay === ngaycuadon && p.name === 'Lợi Nhuận'
          );
          prev[index1].giatri =
            prev[index1].giatri -
            (socketPayload.payload.data.value -
              socketPayload.payload.data.von -
              socketPayload.payload.data.discount);
          return [...prev];
        });
        setSoDonReport((prev) => {
          const index = prev.findIndex((p) => p.ngay === ngaycuadon);
          prev[index].sodon = prev[index].sodon - 1;
          return [...prev];
        });
      }
    });
  }, [socket]);

  return (
    <Layout>
      <Row
        justify='space-between'
        gutter={{ xs: 8, sm: 8, md: 0 }}
        style={{ paddingTop: '5px' }}
      >
        <Col xs={24} sm={24} md={12} lg={6} style={{ marginBottom: '5px' }}>
          <Card className='dashboard-card' bodyStyle={{ padding: '12px' }}>
            <Row>
              <Col
                xs={5}
                sm={6}
                md={6}
                lg={5}
                style={{ textAlign: 'center', alignSelf: 'center' }}
              >
                <DollarCircleTwoTone
                  className='dashboard-card-icon'
                  twoToneColor='#a0d911'
                />
              </Col>

              <Col xs={19} sm={18} md={18} lg={19}>
                <p className='dashboard-cart-content'>Lợi Nhuận Hôm Nay</p>
                <p className='report-card'>
                  {dailyReport.profit.toLocaleString('vi-VN')} đ
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} style={{ marginBottom: '5px' }}>
          <Card className='dashboard-card' bodyStyle={{ padding: '12px' }}>
            <Row>
              <Col
                xs={5}
                sm={6}
                md={6}
                lg={5}
                style={{ textAlign: 'center', alignSelf: 'center' }}
              >
                <PropertySafetyTwoTone
                  className='dashboard-card-icon'
                  twoToneColor='#1890ff'
                />
              </Col>

              <Col xs={19} sm={18} md={18} lg={19}>
                <p className='dashboard-cart-content'>Doanh Số Hôm Nay</p>
                <p className='report-card'>
                  {dailyReport.sales.toLocaleString('vi-VN')} đ
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} style={{ marginBottom: '5px' }}>
          <Card className='dashboard-card' bodyStyle={{ padding: '12px' }}>
            <Row>
              <Col
                xs={5}
                sm={6}
                md={6}
                lg={5}
                style={{ textAlign: 'center', alignSelf: 'center' }}
              >
                <ShoppingTwoTone
                  className='dashboard-card-icon'
                  twoToneColor='#f759ab'
                />
              </Col>

              <Col xs={19} sm={18} md={18} lg={19}>
                <p className='dashboard-cart-content'>Số Đơn Hôm Nay</p>
                <p className='report-card'>
                  {dailyReport.totalOrder.toLocaleString('vi-VN')} đơn
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} style={{ marginBottom: '5px' }}>
          <Card className='dashboard-card' bodyStyle={{ padding: '12px' }}>
            <Row>
              <Col
                xs={5}
                sm={6}
                md={6}
                lg={5}
                style={{ textAlign: 'center', alignSelf: 'center' }}
              >
                <TagsTwoTone
                  className='dashboard-card-icon'
                  twoToneColor='#ff4d4f'
                />
              </Col>

              <Col xs={19} sm={18} md={18} lg={19}>
                <p className='dashboard-cart-content'>Số Đơn Chưa Xử Lý</p>
                <p className='report-card'>
                  {dailyReport.newOrder.toLocaleString('vi-VN')} đơn
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={8} style={{ padding: '0 10px 10px 10px' }}>
        <Col xs={24}>
          <Card>
            <Title level={5}>Báo Cáo Kinh Doanh</Title>
            <Line {...monthlyReportLineChartConfig} />
          </Card>
        </Col>
      </Row>
      <Row gutter={8} style={{ padding: '0 10px 10px 10px' }}>
        <Col xs={24}>
          <Card>
            <Title level={5}>Số Đơn Trong Tháng</Title>
            <Column {...totalOrderReportAreaChartConfig} height={300} />
          </Card>
        </Col>
      </Row>
      <Row
        style={{ padding: '0 10px 10px 10px', justifyContent: 'space-around' }}
      >
        <Col xs={24} sm={24} md={24} lg={11}>
          <Title level={5} style={{ textAlign: 'center', color: '#096dd9' }}>
            TOP 10 Sản Phẩm Bán Chạy
          </Title>
          <Table
            dataSource={topSP.table}
            columns={columns}
            loading={topSP.loading}
            rowKey='_id'
            pagination={false}
            rowClassName={(record, index) => {
              return index % 2 === 0
                ? 'table-row-light order-list-table-row'
                : 'table-row-dark order-list-table-row';
            }}
            size='small'
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Title level={5} style={{ textAlign: 'center', color: '#096dd9' }}>
            TOP 10 Khách Hàng Mua Nhiều
          </Title>
          <Table
            dataSource={topKH.table}
            columns={columnsKH}
            loading={topKH.loading}
            rowKey='_id'
            pagination={false}
            rowClassName={(record, index) => {
              return index % 2 === 0
                ? 'table-row-light order-list-table-row'
                : 'table-row-dark order-list-table-row';
            }}
            size='small'
          />
        </Col>
      </Row>
    </Layout>
  );
};

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.AuthRedux.user,
});
export default connect(mapStateToProps)(Dashboard);
