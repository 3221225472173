import {
  LOAD_PROVINE,
  SAVE_DISTRICT,
  SAVE_WARD,
  CHANGE_DISTRICT,
  CHANGE_WARD,
  CLEAR_ALL_PROVINE,
} from './Types';

const districtJson = require('../json/districts.json'),
  wardJson = require('../json/wards.json');

export const changeWard = (choicedWardCode, choicedward) => (dispatch) => {
  dispatch({
    type: CHANGE_WARD,
    payload: { choicedWardCode: choicedWardCode, choicedward: choicedward },
  });
};
export const changeDistrict =
  (choicedDistrictCode, choicedDistrictName) => (dispatch) => {
    let ward = [];
    for (let val in wardJson[choicedDistrictCode]) {
      ward.push({
        wardCode: val,
        wardName: wardJson[choicedDistrictCode][val],
      });
    }
    dispatch({
      type: CHANGE_DISTRICT,
      payload: {
        choicedDistrictCode: choicedDistrictCode,
        choicedDistrictName: choicedDistrictName,
        ward: ward,
      },
    });
  };
export const initProvine =
  (
    choicedCityCode,
    choicedDistrictCode,
    choicedWardCode,
    city,
    district,
    ward
  ) =>
  async (dispatch) => {
    await dispatch({
      type: LOAD_PROVINE,
      payload: {
        choicedCityCode: choicedCityCode,
        choicedDistrictCode: choicedDistrictCode,
        choicedWardCode: choicedWardCode,
        choicedCity: city,
        choicedDistrict: district,
        choicedWard: ward,
      },
    });
  };

export const clearAllProvine = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_PROVINE });
};
export const saveDistrict = (cityCode) => (dispatch) => {
  let district = [];
  for (let val in districtJson[cityCode]) {
    district.push({
      districtCode: val,
      districtName: districtJson[cityCode][val],
    });
  }
  dispatch({ type: SAVE_DISTRICT, payload: district });
};

export const saveWard = (districtCode) => (dispatch) => {
  let ward = [];
  for (let val in wardJson[districtCode]) {
    ward.push({
      wardCode: val,
      wardName: wardJson[districtCode][val],
    });
  }
  dispatch({ type: SAVE_WARD, payload: ward });
};
