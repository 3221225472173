import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';

import 'antd/dist/antd.css';
import './App.css';
import Sidebar from './components/layout/Sidebar';
import { Provider } from 'react-redux';
import Store from './Store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Login from './components/auth/Login';
import Main from './components/Main';
import Register from './components/auth/Register';
import Dashboard from './components/Dashboard';
import Orders from './components/Orders';
import MyRoute from './components/routing/MyRoute';
import Admin from './components/Admin';
import Setting from './components/Setting';
import SetAuthToken from './components/util/SetAuthToken';
import { loadUser } from './action/Auth';
import { getStatistics } from './action/Statistics';
import Products from './components/Products';

import Alert from './components/layout/Alert';

import { SocketContext, socket } from './components/context/socket';
import PrintOrder from './components/PrintOrder';

if (localStorage.token) {
  SetAuthToken(localStorage.token);
}

const App = () => {
  const { Content, Footer } = Layout,
    [collapsed, setCollapsed] = useState(undefined),
    bugerClick = () => {
      setCollapsed(!collapsed);
    },
    onCollapse = (collapsed) => {
      setCollapsed(collapsed);
    };
  useEffect(() => {
    if (localStorage.token && !Store.getState().AuthRedux.user) {
      Store.dispatch(loadUser());
    }
    if (localStorage.user) {
      const user = JSON.parse(localStorage.user);
      if (user && user.partition) Store.dispatch(getStatistics());

      // const username = Store.getState().AuthRedux.user._id,
      //   roomname = Store.getState().AuthRedux.user.partition;
      // socket.emit('joinRoom', { username, roomname });
    }
  }, []);
  const showContent = () => {
    if (window.location.pathname === '/printorders') {
      return <PrintOrder />;
    } else {
      return (
        <Layout style={{ minHeight: '100vh' }}>
          <Sidebar
            collapsed={collapsed}
            onCollapse={onCollapse}
            bugerClick={bugerClick}
          />
          <Layout className='site-layout'>
            <Navbar bugerClick={bugerClick} />
            <Content style={{ margin: '5px' }}>
              <Switch>
                <MyRoute exact path='/' component={Main} />
                <Route exact path='/register' component={Register} />
                <Route exact path='/login' component={Login} />
                <MyRoute
                  needShop={true}
                  isRestricted={true}
                  exact
                  path='/dashboard'
                  component={Dashboard}
                />
                <MyRoute
                  isRestricted={true}
                  needShop={true}
                  exact
                  path='/products'
                  component={Products}
                />
                <MyRoute
                  isRestricted={true}
                  needShop={true}
                  exact
                  path='/orders'
                  component={Orders}
                />

                <MyRoute
                  exact
                  isRestricted={true}
                  path='/admin'
                  component={Admin}
                />
                <MyRoute exact path='/setting' component={Setting} />
              </Switch>
            </Content>
            <Footer style={{ textAlign: 'center', padding: '12px 20px' }}>
              Coded by Kiem Dan Le @Babyponyshop
            </Footer>
          </Layout>
        </Layout>
      );
    }
  };
  return (
    <Provider store={Store}>
      <SocketContext.Provider value={socket}>
        <Router>
          <Alert />
          {showContent()}
        </Router>
      </SocketContext.Provider>
    </Provider>
  );
};

export default App;
