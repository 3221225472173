import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Layout,
  message,
  Upload,
} from 'antd';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { PlusOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import Store from '../../Store';
import { addProduct, updateProduct } from '../../action/Products';
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const ProductModal = (props) => {
  const { productAction, user } = props,
    { Content } = Layout,
    { Option } = Select,
    [productForm] = Form.useForm(),
    [openModal, setOpenModal] = useState(false),
    [modalTitle, setModalTitle] = useState(''),
    [integratedIdDisplay, setIntergratedIdDisplay] = useState('none'),
    [selectCategoryDisplay, setSelectCategoryDisplay] = useState('none'),
    [motaDisplay, setMotaDisplay] = useState('none'),
    [oldEditorContent, setOldEditorContent] = useState(''),
    [optionWooCat, setOptionWooCat] = useState([]),
    [previewVisible, setPreviewVisible] = useState(false),
    [previewImage, setPreviewImage] = useState(''),
    [previewTitle, setPreviewTitle] = useState(''),
    [fileList, setFileList] = useState([]),
    [modalFooter, setModalFooter] = useState([]),
    [woocommerceCatRule, setWoocommerceCatRule] = useState([
      { required: false },
    ]),
    [woocommerceIdRule, setWoocommerceIdRule] = useState([{ required: false }]),
    editorRef = useRef(''),
    handleOk = (e) => {},
    handleChonWoo = async (value) => {
      try {
        if (value === 'taoMoi' && user.partition === 'Babyponyshop') {
          setOptionWooCat([]);
          const wooCat = await axios.get('/api/products/woocat', config);

          wooCat.data.data.map((item) =>
            setOptionWooCat((prev) => [
              ...prev,
              { label: item.name, value: item.wooId },
            ])
          );

          setIntergratedIdDisplay('none');
          setSelectCategoryDisplay('');
          setMotaDisplay('');
          productForm.setFieldsValue({ woocommerce: '' });
          setWoocommerceIdRule([{ required: false }]);
          setWoocommerceCatRule([
            {
              required: true,
              message: 'Xin vui lòng chọn danh mục Woocommerce',
            },
          ]);
        }
        if (value === 'taoMoi' && user.partition !== 'Babyponyshop') {
          setIntergratedIdDisplay('none');
          setSelectCategoryDisplay('');
          setMotaDisplay('');
          productForm.setFieldsValue({ woocommerce: '' });
          setWoocommerceIdRule([{ required: false }]);
          setWoocommerceCatRule([{ required: false }]);
        }
        if (value === 'coSan') {
          setIntergratedIdDisplay('');
          setSelectCategoryDisplay('none');
          setMotaDisplay('none');
          productForm.setFieldsValue({ danhMucWoo: '' });
          setWoocommerceIdRule([
            {
              required: true,
              message: 'Xin vui lòng nhập ID sản phẩm Woocommerce!',
            },
          ]);
          setWoocommerceCatRule([{ required: false }]);
        }
        if (value === 'khongLienKet') {
          setIntergratedIdDisplay('none');
          setSelectCategoryDisplay('none');
          setMotaDisplay('none');
          productForm.setFieldsValue({ woocommerce: '' });
          productForm.setFieldsValue({ danhMucWoo: '' });
          setWoocommerceIdRule([{ required: false }]);
          setWoocommerceCatRule([{ required: false }]);
        }
      } catch (err) {
        console.log(err);
        message.error('Không thể kết nối woo category');
      }
    },
    handleCancel = () => setPreviewVisible(false),
    handlePreview = async (file) => {
      if (!file.response) {
        file.response = {
          url: file.url,
          preview: file.preview,
          name: file.name,
        };
      }
      console.log(file);
      setPreviewImage(file.response.url || file.response.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name ||
          file.response.url.substring(file.response.url.lastIndexOf('/') + 1)
      );
    },
    handleChange = ({ file, fileList, event }) => {
      setFileList(fileList);
    },
    handleModalCancel = () => {
      setOpenModal(false);
      setFileList([]);

      productForm.resetFields();
    },
    uploadRequest = async (options) => {
      const { onSuccess, file } = options;
      const fmData = new FormData();
      fmData.append('image', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'x-auth-token': localStorage.token,
        },
      };
      try {
        const res = await axios.post('/api/upload', fmData, config);

        onSuccess({
          uid: res.data.image.replace(/\D/g, ''),
          url: res.data.image,
          preview: res.data.thumb,
        });
      } catch (err) {
        console.log(err);
        message.error('Có lỗi! không thể upload hình sản phẩm');
      }
    },
    uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
  const handleSubmit = (e, values) => {
    const submitType = e.target.parentNode.getAttribute('id');

    const sanPham = productForm.getFieldValue();
    sanPham.imageLink = [];
    sanPham.thumbLink = [];

    sanPham.luachontaomoicosan = { woocommerce: sanPham.kieuKetNoi };
    if (sanPham.kieuKetNoi === 'coSan') {
      sanPham.idLienKet = { woocommerce: sanPham.woocommerce };
    }

    if (sanPham.kieuKetNoi === 'taoMoi') {
      sanPham.category = { woocommerce: sanPham.danhMucWoo };
      sanPham.mota = editorRef.current.getContent();
    }

    productForm.validateFields().then(async () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        if (
          submitType === 'luuVaThemMoi' ||
          submitType === 'luu' ||
          submitType === 'luuVaSaoChep'
        ) {
          if (sanPham.upload) {
            for (let val in sanPham.upload.fileList) {
              sanPham.imageLink.push(sanPham.upload.fileList[val].response.url);
              sanPham.thumbLink.push(
                sanPham.upload.fileList[val].response.preview
              );
            }
          }

          const body = JSON.stringify(sanPham);
          const res = await axios.post('/api/products', body, config);
          if (res.data.success) {
            message.success(`Đã tạo sản phẩm thành công`);
            Store.dispatch(addProduct(res.data.data));
            if (submitType === 'luu') {
              productForm.resetFields();
              setFileList([]);
              if (editorRef.current.getContent())
                editorRef.current.setContent('');
              setOpenModal(false);
              setIntergratedIdDisplay('none');
              setSelectCategoryDisplay('none');
              setMotaDisplay('none');
              setWoocommerceIdRule([{ required: false }]);
              setWoocommerceCatRule([
                {
                  required: false,
                },
              ]);
            }
            if (submitType === 'luuVaThemMoi') {
              setFileList([]);
              productForm.resetFields();
              setIntergratedIdDisplay('none');
              setSelectCategoryDisplay('none');
              setMotaDisplay('none');
              if (editorRef.current.getContent())
                editorRef.current.setContent('');
              setWoocommerceIdRule([{ required: false }]);
              setWoocommerceCatRule([
                {
                  required: false,
                },
              ]);
            }
          } else {
            console.log(res.data);
            message.error(
              `Có lỗi khi tạo sản phẩm mới, nội dung lỗi là: ${res.data.msg}`
            );
          }
        }
        if (submitType === 'update') {
          if (!sanPham.upload) {
            sanPham.imageLink = [...productAction.product.imageLink];
            sanPham.thumbLink = [...productAction.product.thumbLink];
          }
          if (sanPham.upload) {
            for (let val in sanPham.upload.fileList) {
              console.log(val);
              sanPham.imageLink.push(sanPham.upload.fileList[val].response.url);
              sanPham.thumbLink.push(
                sanPham.upload.fileList[val].response.preview
              );
            }
          }
          if (editorRef.current) {
            sanPham.mota = editorRef.current.getContent();
          }
          const body = JSON.stringify(sanPham);
          const res = await axios.put(
            `/api/products/${sanPham._id}`,
            body,
            config
          );
          if (res.data.success) {
            message.success(`Đã cập nhật thành công`);
            Store.dispatch(updateProduct(res.data.data));
            productForm.resetFields();
            setFileList([]);
            productAction.clearExpaned();
            if (editorRef.current.getContent())
              editorRef.current.setContent('');
            setOpenModal(false);
            setIntergratedIdDisplay('none');
            setSelectCategoryDisplay('none');
            setMotaDisplay('none');
            setWoocommerceIdRule([{ required: false }]);
            setWoocommerceCatRule([
              {
                required: false,
              },
            ]);
          }
        }

        if (
          submitType === 'luuVaThemMoiNhanBan' ||
          submitType === 'luuNhanBan' ||
          submitType === 'luuVaSaoChepNhanBan'
        ) {
          if (!sanPham.upload) {
            sanPham.imageLink = [...productAction.product.imageLink];
            sanPham.thumbLink = [...productAction.product.thumbLink];
          }
          if (sanPham.upload) {
            for (let val in sanPham.upload.fileList) {
              sanPham.imageLink.push(sanPham.upload.fileList[val].response.url);
              sanPham.thumbLink.push(
                sanPham.upload.fileList[val].response.preview
              );
            }
          }
          if (editorRef.current) {
            sanPham.mota = editorRef.current.getContent();
          }
          delete sanPham._id;
          if (sanPham.kieuKetNoi === 'khongLienKet') {
            delete sanPham.category;
            delete sanPham.idLienKet;
            delete sanPham.danhMucWoo;
            delete sanPham.woocommerce;
          }
          const body = JSON.stringify(sanPham);
          const res = await axios.post('/api/products', body, config);
          if (res.data.success) {
            message.success(`Đã tạo sản phẩm thành công`);
            Store.dispatch(addProduct(res.data.data));
            if (submitType === 'luuNhanBan') {
              productForm.resetFields();
              setFileList([]);
              if (editorRef.current.getContent())
                editorRef.current.setContent('');
              setOpenModal(false);
              setIntergratedIdDisplay('none');
              setSelectCategoryDisplay('none');
              setMotaDisplay('none');
              setWoocommerceIdRule([{ required: false }]);
              setWoocommerceCatRule([
                {
                  required: false,
                },
              ]);
            }
            if (submitType === 'luuVaThemMoiNhanBan') {
              setFileList([]);
              productForm.resetFields();
              setIntergratedIdDisplay('none');
              setSelectCategoryDisplay('none');
              setMotaDisplay('none');
              if (editorRef.current.getContent())
                editorRef.current.setContent('');
              setWoocommerceIdRule([{ required: false }]);
              setWoocommerceCatRule([
                {
                  required: false,
                },
              ]);
            }
          } else {
            console.log(res.data);
            message.error(
              `Có lỗi khi tạo sản phẩm mới, nội dung lỗi là: ${res.data.msg}`
            );
          }
        }
      } catch (err) {
        console.log(err);
        message.error(err);
      }
    });
  };
  useEffect(() => {
    try {
      setOldEditorContent('');
      if (productAction.type === 'update') {
        console.log(productAction.product);
        setFileList([]);
        productForm.setFieldsValue(productAction.product);
        setModalTitle('Cập nhật sản phẩm');
        if (
          productAction.product.idLienKet &&
          productAction.product.idLienKet.woocommerce
        ) {
          productForm.setFieldsValue({
            kieuKetNoi: 'coSan',
            woocommerce: productAction.product.idLienKet.woocommerce,
          });
          setIntergratedIdDisplay('');
        }
        if (
          productAction.product.imageLink.length > 0 &&
          productAction.product.thumbLink.length > 0
        ) {
          for (let image in productAction.product.imageLink) {
            setFileList((prev) => [
              ...prev,
              {
                uid: productAction.product.imageLink[image].replace(/\D/g, ''),
                name: productAction.product.imageLink[image],
                url: productAction.product.imageLink[image],
                preview: productAction.product.thumbLink[image],
                status: 'done',
                percent: 100,
                response: {
                  uid: productAction.product.imageLink[image].replace(
                    /\D/g,
                    ''
                  ),

                  url: productAction.product.imageLink[image],
                  preview: productAction.product.thumbLink[image],
                },
              },
            ]);
          }
        }
        setModalFooter([
          <Button
            key='update'
            htmlType='submit'
            id='update'
            type='primary'
            data-id='update'
            onClick={(e) => {
              productForm.validateFields().then((values) => {
                handleSubmit(e, values);
              });
            }}
          >
            Lưu
          </Button>,

          <Button danger type='primary' key='huy' onClick={handleModalCancel}>
            Huỷ
          </Button>,
        ]);
        if (productAction.product.mota) {
          setOldEditorContent(productAction.product.mota);
          setMotaDisplay('');
        }
        if (editorRef.current && productAction.product.mota) {
          editorRef.current.setContent(productAction.product.mota);
        }
        setOpenModal(true);
      }
      if (productAction.type === 'copy') {
        console.log(productAction.product);

        setFileList([]);
        productForm.setFieldsValue(productAction.product);
        setModalTitle('Nhân bản sản phẩm');
        productForm.setFieldsValue({ danhMucWoo: '' });
        productForm.setFieldsValue({ woocommerce: '' });

        if (
          productAction.product.imageLink.length > 0 &&
          productAction.product.thumbLink.length > 0
        ) {
          for (let image in productAction.product.imageLink) {
            setFileList((prev) => [
              ...prev,
              {
                uid: productAction.product.imageLink[image].replace(/\D/g, ''),
                name: productAction.product.imageLink[image],
                url: productAction.product.imageLink[image],
                preview: productAction.product.thumbLink[image],
                status: 'done',
                percent: 100,
                response: {
                  uid: productAction.product.imageLink[image].replace(
                    /\D/g,
                    ''
                  ),

                  url: productAction.product.imageLink[image],
                  preview: productAction.product.thumbLink[image],
                },
              },
            ]);
          }
        }
        setModalFooter([
          <Button
            key='luuNhanBan'
            htmlType='submit'
            id='luuNhanBan'
            type='primary'
            data-id='luuNhanBan'
            onClick={(e) => {
              productForm.validateFields().then((values) => {
                handleSubmit(e, values);
              });
            }}
          >
            Lưu
          </Button>,
          <Button
            key='luuVaSaoChepNhanBan'
            htmlType='submit'
            id='luuVaSaoChepNhanBan'
            type='primary'
            data-id='luuVaSaoChepNhanBan'
            onClick={(e) => {
              productForm.validateFields().then((values) => {
                handleSubmit(e, values);
              });
            }}
          >
            Lưu và sao chép
          </Button>,
          <Button
            key='luuVaThemMoiNhanBan'
            htmlType='submit'
            id='luuVaThemMoiNhanBan'
            type='primary'
            data-id='luuVaThemMoiNhanBan'
            onClick={(e) => {
              productForm.validateFields().then((values) => {
                handleSubmit(e, values);
              });
            }}
          >
            Lưu và thêm mới
          </Button>,
          <Button danger type='primary' key='huy' onClick={handleModalCancel}>
            Huỷ
          </Button>,
        ]);
        if (productAction.product.mota) {
          setOldEditorContent(productAction.product.mota);
          setMotaDisplay('');
        }
        if (editorRef.current && productAction.product.mota) {
          editorRef.current.setContent(productAction.product.mota);
        }
        setOpenModal(true);
      }
      if (productAction.type === 'new') {
        productForm.resetFields();
        setFileList([]);
        if (editorRef.current && editorRef.current.getContent())
          editorRef.current.setContent('');

        setIntergratedIdDisplay('none');
        setSelectCategoryDisplay('none');
        setMotaDisplay('none');
        setWoocommerceIdRule([{ required: false }]);
        setWoocommerceCatRule([
          {
            required: false,
          },
        ]);
        setModalTitle('Tạo Sản Phẩm Mới');
        setModalFooter([
          <Button
            key='luu'
            htmlType='submit'
            id='luu'
            type='primary'
            data-id='luu'
            onClick={(e) => {
              productForm.validateFields().then((values) => {
                handleSubmit(e, values);
              });
            }}
          >
            Lưu
          </Button>,
          <Button
            key='luuVaSaoChep'
            htmlType='submit'
            id='luuVaSaoChep'
            type='primary'
            data-id='luuVaSaoChep'
            onClick={(e) => {
              productForm.validateFields().then((values) => {
                handleSubmit(e, values);
              });
            }}
          >
            Lưu và sao chép
          </Button>,
          <Button
            key='luuVaThemMoi'
            htmlType='submit'
            id='luuVaThemMoi'
            type='primary'
            data-id='luuVaThemMoi'
            onClick={(e) => {
              productForm.validateFields().then((values) => {
                handleSubmit(e, values);
              });
            }}
          >
            Lưu và thêm mới
          </Button>,
          <Button danger type='primary' key='huy' onClick={handleModalCancel}>
            Huỷ
          </Button>,
        ]);
        setOpenModal(true);
      }
    } catch (err) {
      console.log(err);
      message.error('Không thể tạo sản phẩm mới');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productAction]);
  const onFinish = (e) => {
    const sanPham = productForm.getFieldValue();

    sanPham.luachontaomoicosan = { woocommerce: sanPham.kieuKetNoi };
    if (sanPham.kieuKetNoi === 'coSan') {
      sanPham.idLienKet = { woocommerce: sanPham.woocommerce };
    }

    if (sanPham.kieuKetNoi === 'taoMoi') {
      sanPham.category = { woocommerce: sanPham.danhMucWoo };
    }
    console.log(sanPham);
  };
  if (isMobile) {
    return (
      <>
        <Modal
          visible={openModal}
          onOk={handleOk}
          onCancel={handleModalCancel}
          footer={modalFooter}
          style={{ top: 20, padding: '8px' }}
          motion={null}
          title={modalTitle}
        >
          <Content>
            <Form
              name='product-form'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              form={productForm}
              onFinish={onFinish}
            >
              <Form.Item
                name='productName'
                label={<b>Tên sản phẩm:</b>}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên sản phẩm',
                  },
                ]}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>

              <Form.Item
                label={<b>Giá Vốn:</b>}
                name='inPrice'
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập giá vốn sản phẩm',
                  },
                ]}
                labelCol={{ span: 13 }}
                wrapperCol={{ span: 11 }}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item
                label={<b>Giá bán:</b>}
                name='outPrice'
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập giá bán sản phẩm',
                  },
                ]}
                labelCol={{ span: 13 }}
                wrapperCol={{ span: 11 }}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item
                label={<b>Số lượng:</b>}
                name='quantity'
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập số lượng tồn kho sản phẩm',
                  },
                ]}
                labelCol={{ span: 13 }}
                wrapperCol={{ span: 11 }}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item
                label={<b>Trọng lượng (gram):</b>}
                name='weight'
                rules={[
                  {
                    required: true,
                    message:
                      'Xin vui lòng nhập trọng lượng sản phẩm đơn vị Gram',
                  },
                ]}
                labelCol={{ span: 13 }}
                wrapperCol={{ span: 11 }}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item
                label={<b>Kích thước (DxRxC):</b>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name='length'
                  style={{
                    display: 'inline-block',
                    width: 'calc(25% - 8px)',
                    marginRight: '10px',
                    marginLeft: '8px',
                    marginBottom: 0,
                  }}
                >
                  <Input
                    placeholder='Dài'
                    style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                  />
                </Form.Item>
                <span style={{ fontWeight: 'bold' }}>X</span>
                <Form.Item
                  name='width'
                  style={{
                    display: 'inline-block',
                    width: 'calc(25% - 8px)',
                    marginRight: '10px',
                    marginLeft: '10px',
                  }}
                >
                  <Input
                    placeholder='Rộng'
                    style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                  />
                </Form.Item>
                <span style={{ fontWeight: 'bold' }}>X</span>
                <Form.Item
                  name='height'
                  style={{
                    display: 'inline-block',
                    width: 'calc(25% - 8px)',
                    marginLeft: '10px',
                  }}
                >
                  <Input
                    placeholder='Cao'
                    style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item label={<b>Vị trí sản phẩm:</b>} name='location'>
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              {user.WoocommerceUrl &&
                user.woocommerceApiKey &&
                user.woocommerceSecretKey &&
                user.partition !== 'Babyponyshop' && (
                  <Row style={{ marginBottom: '8px' }}>
                    <Col span={10}>
                      <Form.Item
                        label='Woocommerce'
                        name='kieuKetNoi'
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}
                        initialValue='khongLienKet'
                        style={{ marginBottom: '8px' }}
                      >
                        <Select onChange={handleChonWoo}>
                          <Option value='taoMoi'>
                            Tạo sản phẩm mới trên Website
                          </Option>
                          <Option value='coSan'>
                            Liên kết sản phẩm có sẵn trên Website
                          </Option>
                          <Option value='khongLienKet'>Không liên kết</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <Form.Item
                        label='ID Woocommerce'
                        name='woocommerce'
                        style={{
                          display: integratedIdDisplay,
                          marginBottom: '8px',
                        }}
                        labelCol={{ span: 11 }}
                        wrapperCol={{ span: 13 }}
                      >
                        <Input
                          style={{
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              {user.WoocommerceUrl &&
                user.woocommerceApiKey &&
                user.woocommerceSecretKey &&
                user.partition === 'Babyponyshop' && (
                  <>
                    <Row style={{ marginBottom: '8px' }}>
                      <Col span={10}>
                        <Form.Item
                          label='Woocommerce'
                          name='kieuKetNoi'
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          initialValue='khongLienKet'
                          style={{ marginBottom: '8px' }}
                        >
                          <Select onChange={handleChonWoo}>
                            <Option value='taoMoi'>Tạo sản phẩm mới</Option>
                            <Option value='coSan'>
                              Liên kết sản phẩm có sẵn
                            </Option>
                            <Option value='khongLienKet'>Không liên kết</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={14}>
                        <Form.Item
                          label='ID Woocommerce'
                          name='woocommerce'
                          style={{
                            display: integratedIdDisplay,
                            marginBottom: '8px',
                          }}
                          labelCol={{ span: 11 }}
                          wrapperCol={{ span: 13 }}
                          rules={woocommerceIdRule}
                        >
                          <Input
                            style={{
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label='Danh mục Woocommerce'
                          name='danhMucWoo'
                          labelCol={{ span: 11 }}
                          wrapperCol={{ span: 13 }}
                          rules={woocommerceCatRule}
                          style={{
                            display: selectCategoryDisplay,
                            marginBottom: '8px',
                          }}
                        >
                          <Select options={optionWooCat}></Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      style={{ display: motaDisplay }}
                      justify='center'
                      id='motaEditor'
                    >
                      <Col span={22}>
                        <div
                          style={{
                            fontWeight: 'bold',
                            marginBottom: '10px',
                            textAlign: 'center',
                          }}
                        >
                          Mô tả chi tiết sản phẩm:
                        </div>
                        <Editor
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue={oldEditorContent}
                          apiKey='dddjzszg84gv17u8gqqb6aez3hfxqt9eg4hqf76d65gfe67u'
                          init={{
                            height: 250,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              <Row>
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  name='upload'
                  style={{ margin: '8px auto 0px auto' }}
                >
                  <Upload
                    accept='image/*'
                    customRequest={uploadRequest}
                    action='/api/upload'
                    listType='picture-card'
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    multiple={true}
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                  width='80%'
                >
                  <img
                    alt='example'
                    style={{ width: '100%' }}
                    src={previewImage}
                  />
                </Modal>
              </Row>
            </Form>
          </Content>
        </Modal>
      </>
    );
  }
  return (
    <Modal
      visible={openModal}
      onOk={handleOk}
      onCancel={handleModalCancel}
      footer={modalFooter}
      width={900}
      style={{ top: 20, padding: '12px' }}
      motion={null}
      title={modalTitle}
    >
      <Content>
        <Form
          name='product-form'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          form={productForm}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                label='Tên sản phẩm'
                name='productName'
                rules={[
                  { required: true, message: 'Xin vui lòng nhập tên sản phẩm' },
                ]}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item label='Vị trí sản phẩm' name='location'>
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item
                label='Kích thước sản phẩm'
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name='length'
                  style={{
                    display: 'inline-block',
                    width: 'calc(30% - 8px)',
                    marginRight: '10px',
                    marginBottom: 0,
                  }}
                >
                  <Input
                    placeholder='Dài'
                    style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                  />
                </Form.Item>
                <span style={{ fontWeight: 'bold' }}>X</span>
                <Form.Item
                  name='width'
                  style={{
                    display: 'inline-block',
                    width: 'calc(30% - 8px)',
                    marginRight: '10px',
                    marginLeft: '10px',
                  }}
                >
                  <Input
                    placeholder='Rộng'
                    style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                  />
                </Form.Item>
                <span style={{ fontWeight: 'bold' }}>X</span>
                <Form.Item
                  name='height'
                  style={{
                    display: 'inline-block',
                    width: 'calc(30% - 8px)',
                    marginLeft: '10px',
                  }}
                >
                  <Input
                    placeholder='Cao'
                    style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item label='Link sản phẩm' name='link' id='link'>
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='Giá vốn'
                name='inPrice'
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập giá vốn sản phẩm',
                  },
                ]}
                labelCol={{ span: 13 }}
                wrapperCol={{ span: 11 }}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item
                label='Giá bán'
                name='outPrice'
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập giá bán sản phẩm',
                  },
                ]}
                labelCol={{ span: 13 }}
                wrapperCol={{ span: 11 }}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item
                label='Số lượng'
                name='quantity'
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập số lượng tồn kho sản phẩm',
                  },
                ]}
                labelCol={{ span: 13 }}
                wrapperCol={{ span: 11 }}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
              <Form.Item
                label='Trọng lượng(gram)'
                name='weight'
                rules={[
                  {
                    required: true,
                    message:
                      'Xin vui lòng nhập trọng lượng sản phẩm đơn vị Gram',
                  },
                ]}
                labelCol={{ span: 13 }}
                wrapperCol={{ span: 11 }}
              >
                <Input
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                />
              </Form.Item>
            </Col>
          </Row>

          {user.WoocommerceUrl &&
            user.woocommerceApiKey &&
            user.woocommerceSecretKey &&
            user.partition !== 'Babyponyshop' && (
              <Row style={{ marginBottom: '8px' }}>
                <Col span={10}>
                  <Form.Item
                    label='Woocommerce'
                    name='kieuKetNoi'
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    initialValue='khongLienKet'
                    style={{ marginBottom: '8px' }}
                  >
                    <Select onChange={handleChonWoo}>
                      <Option value='taoMoi'>
                        Tạo sản phẩm mới trên Website
                      </Option>
                      <Option value='coSan'>
                        Liên kết sản phẩm có sẵn trên Website
                      </Option>
                      <Option value='khongLienKet'>Không liên kết</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <Form.Item
                    label='ID Woocommerce'
                    name='woocommerce'
                    style={{
                      display: integratedIdDisplay,
                      marginBottom: '8px',
                    }}
                    labelCol={{ span: 11 }}
                    wrapperCol={{ span: 13 }}
                  >
                    <Input
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          {user.WoocommerceUrl &&
            user.woocommerceApiKey &&
            user.woocommerceSecretKey &&
            user.partition === 'Babyponyshop' && (
              <>
                <Row style={{ marginBottom: '8px' }}>
                  <Col span={10}>
                    <Form.Item
                      label='Woocommerce'
                      name='kieuKetNoi'
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      initialValue='khongLienKet'
                      style={{ marginBottom: '8px' }}
                    >
                      <Select onChange={handleChonWoo}>
                        <Option value='taoMoi'>Tạo sản phẩm mới</Option>
                        <Option value='coSan'>Liên kết sản phẩm có sẵn</Option>
                        <Option value='khongLienKet'>Không liên kết</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Form.Item
                      label='ID Woocommerce'
                      name='woocommerce'
                      style={{
                        display: integratedIdDisplay,
                        marginBottom: '8px',
                      }}
                      labelCol={{ span: 11 }}
                      wrapperCol={{ span: 13 }}
                      rules={woocommerceIdRule}
                    >
                      <Input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Danh mục Woocommerce'
                      name='danhMucWoo'
                      labelCol={{ span: 11 }}
                      wrapperCol={{ span: 13 }}
                      rules={woocommerceCatRule}
                      style={{
                        display: selectCategoryDisplay,
                        marginBottom: '8px',
                      }}
                    >
                      <Select options={optionWooCat}></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{ display: motaDisplay }}
                  justify='center'
                  id='motaEditor'
                >
                  <Col span={22}>
                    <div
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        textAlign: 'center',
                      }}
                    >
                      Mô tả chi tiết sản phẩm:
                    </div>
                    <Editor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={oldEditorContent}
                      apiKey='dddjzszg84gv17u8gqqb6aez3hfxqt9eg4hqf76d65gfe67u'
                      init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          <Row>
            <Form.Item
              wrapperCol={{ span: 24 }}
              name='upload'
              style={{ margin: '8px auto 0px auto' }}
            >
              <Upload
                accept='image/*'
                customRequest={uploadRequest}
                action='/api/upload'
                listType='picture-card'
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                multiple={true}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
            </Form.Item>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
              width={650}
            >
              <img alt='example' style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

ProductModal.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.AuthRedux.user,
});

export default connect(mapStateToProps, {})(ProductModal);
