import React, { useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
const PrintOrder = () => {
  const search = useLocation().search;
  const orderids = new URLSearchParams(search).get('orderid');
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  useEffect(() => {
    const getData = async () => {
      const getPrintData = await axios.get(
        `/api/orders/print?orderids=${orderids}`,
        config
      );
      if (getPrintData.data.success === true) {
        return getPrintData.data.result;
      } else {
        message.error('Không thể lấy dữ liệu đơn hàng cần in');
      }
    };
  }, []);
  return <div>print</div>;
};

export default PrintOrder;
