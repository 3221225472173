import Store from '../../Store';

export const isLoggin = () => {
  return !Store.getState().AuthRedux.isAuthenticated && !localStorage.token
    ? false
    : true;
};

export const isAdmin = () => {
  if (
    localStorage.token &&
    Store.getState().AuthRedux.isAuthenticated &&
    Store.getState().AuthRedux.user
  ) {
    if (Store.getState().AuthRedux.user.superAdmin) {
      return Store.getState().AuthRedux.user.superAdmin !== true ? false : true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isShop = () => {
  if (
    localStorage.token &&
    Store.getState().AuthRedux.isAuthenticated &&
    Store.getState().AuthRedux.user
  ) {
    if (Store.getState().AuthRedux.user.shop) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
