import React, { useState, useReducer, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Divider,
  DatePicker,
  List,
  Dropdown,
  Layout,
  message,
  Menu,
  Popconfirm,
  Spin,
  Radio,
  AutoComplete,
  Checkbox,
  Collapse,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Store from '../../Store';
import {
  saveDistrict,
  saveWard,
  changeDistrict,
  changeWard,
  initProvine,
  clearAllProvine,
} from '../../action/Provine';
import { getStatistics } from '../../action/Statistics';
import {
  addOrder,
  deleteOrder,
  updateOrder,
  updateCounterNewOrder,
  updateCounter,
} from '../../action/Order';
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { SocketContext } from '../context/socket';
import { isMobile } from 'react-device-detect';
const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  },
  chiTietDonHangReducer = (state, action) => {
    try {
      switch (action.type) {
        case 'set-chi-tiet-don-hang':
          return { ...action.data };
        case 'reset-all':
          state = {
            donhang: {
              shippingFee: 20000,
              discount: 0,
              paid: 0,
              date: moment().toDate(),
              dagiaohang: 'no',

              address: '',
              rPhone: '',
              name: '',
              note: '',
              noteInside: '',
              shippingDetail: {},
            },
            sanpham: [],
            vanchuyen: {
              trackingId: '',
              fee: 20000,
              estimated_pick_time: '',
              estimated_deliver_time: '',
              partner: '',
              xFast: false,
            },
            provine: {},
            hamlet: [],
          };
          state.donhang.date = moment().toDate();
          return { ...state };
        case 'clean-all':
          state = {
            donhang: {
              shippingFee: 20000,
              discount: 0,
              paid: 0,
              date: moment().toDate(),
              dagiaohang: 'no',

              address: '',
              rPhone: '',
              name: '',
              note: '',
              noteInside: '',
              shippingDetail: {},
            },
            sanpham: [],
            vanchuyen: {
              trackingId: '',
              fee: 20000,
              estimated_pick_time: '',
              estimated_deliver_time: '',
              partner: '',
              xFast: false,
            },
            provine: {},
            hamlet: [],
          };
          state.donhang.date = moment().toDate();
          return { ...state };
        case 'chon-khach-hang-cu':
          state.donhang.address = action.data.address;
          state.provine = action.data.provine;
          state.donhang.rPhone = action.data.value;
          state.donhang.date = moment().toDate();
          state.donhang.dagiaohang = 'no';
          state.donhang.customer = [
            {
              firstName: action.data.firstName,
              lastName: action.data.lastName,
              phone: action.data.value,
            },
          ];
          state.donhang.name = `${action.data.lastName} ${action.data.firstName}`;
          return { ...state };
        case 'set-thong-tin-van-chuyen':
          return { ...state, vanchuyen: action.data };
        case 'update-product':
          state.donhang.conlai = 0;
          state.donhang.value = 0;
          state.donhang.weight = 0;
          state.donhang.product[action.productId].quantity = action.quantity;

          for (let i of state.sanpham.keys()) {
            if (state.sanpham[i].key === action.productId) {
              state.sanpham[i].quantity = action.quantity;
            }
          }
          for (let val in state.donhang.product) {
            state.donhang.value +=
              state.donhang.product[val].price *
              state.donhang.product[val].quantity;
            state.donhang.weight +=
              state.donhang.product[val].weight *
              state.donhang.product[val].quantity;
          }
          state.donhang.conlai =
            state.donhang.value +
            state.donhang.shippingFee -
            state.donhang.discount -
            state.donhang.paid;
          return { ...state };
        case 'them-san-pahm':
          if (!state.donhang.product) state.donhang.product = {};
          state.donhang.product[action.data._id] = {
            productId: action.data._id,
            productName: action.data.productName,
            price: action.data.outPrice,
            weight: action.data.weight,
            quantity: 1,
          };
          state.donhang.conlai = 0;
          state.donhang.value = 0;
          state.donhang.weight = 0;
          if (!state.donhang.paid) state.donhang.paid = 0;
          if (!state.donhang.discount) state.donhang.discount = 0;
          const sanpham2 = [];
          // state.sanpham.push(state.donhang.product[action.data._id]);
          for (let val in state.donhang.product) {
            state.donhang.value +=
              state.donhang.product[val].price *
              state.donhang.product[val].quantity;
            state.donhang.weight +=
              state.donhang.product[val].weight *
              state.donhang.product[val].quantity;
            sanpham2.push({
              key: state.donhang.product[val].productId,
              productName: state.donhang.product[val].productName,
              quantity: state.donhang.product[val].quantity,
              price: state.donhang.product[val].price,
            });
          }

          state.donhang.conlai =
            state.donhang.value +
            state.donhang.shippingFee -
            state.donhang.discount -
            state.donhang.paid;

          return { ...state, sanpham: sanpham2 };

        case 'xoa-san-pham':
          state.donhang.conlai = 0;
          state.donhang.value = 0;
          delete state.donhang.product[action.productId];

          for (let val in state.donhang.product) {
            state.donhang.value +=
              state.donhang.product[val].price *
              state.donhang.product[val].quantity;
            state.donhang.weight +=
              state.donhang.product[val].weight *
              state.donhang.product[val].quantity;
          }
          state.donhang.conlai =
            state.donhang.value +
            state.donhang.shippingFee -
            state.donhang.discount -
            state.donhang.paid;
          const sanpham1 = [];
          for (let i of state.sanpham.keys()) {
            if (state.sanpham[i].key !== action.productId) {
              sanpham1.push(state.sanpham[i]);
            }
          }

          return { ...state, sanpham: sanpham1 };
        case 'update-customer':
          if (state.donhang.customer) {
            state.donhang = { ...state.donhang, ...action.adition };
            state.donhang.customer = [
              { ...state.donhang.customer[0], ...action.data },
            ];
          } else {
            state.donhang.customer = [{ ...action.data }];
            state.donhang = { ...state.donhang, ...action.adition };
          }
          return {
            ...state,
          };

        case 'update-order':
          state.donhang = { ...state.donhang, ...action.data };
          if (action.data.shippingFee) {
            state.donhang.conlai =
              state.donhang.value +
              action.data.shippingFee -
              state.donhang.discount -
              state.donhang.paid;
          }
          if (action.provine) {
            return { ...state, provine: { ...action.provine } };
          } else {
            return { ...state };
          }
        case 'update-provine':
          state.provine = { ...state.provine, ...action.data.provine };
          state.donhang = { ...state.donhang, ...action.data };
          return { ...state };
        case 'clear-city-code':
          state.provine.cityCode = '';
          return { ...state };
        case 'clear-district-code':
          state.provine.districtCode = '';
          return { ...state };
        case 'clear-ward-code':
          state.provine.wardCode = '';
          return { ...state };
        case 'chon-don-hang-da-giao':
          state.dagiaohang = 'yes';
          return { ...state };
        case 'bo-chon-don-hang-da-giao':
          state.dagiaohang = 'no';
          return { ...state };
        case 'change-order-status':
          state.donhang.status = action.payload;
          return { ...state, vanchuyen: action.payload1 };
        case 'change-hamlet':
          state.hamlet = action.payload;
          return { ...state };
        default:
          return {
            donhang: {},
            sanpham: [],
            vanchuyen: {
              trackingId: '',
              fee: '',
              estimated_pick_time: '',
              estimated_deliver_time: '',
              partner: '',
              xFast: false,
            },
            provine: {},
            hamlet: [],
          };
      }
    } catch (err) {
      console.log(err);
      message.error('Không thể tải chi tiết đơn hàng');
    }
  };
const OrderModal = (props) => {
  const { user, data, provine, handleOk, orderId, modalId } = props,
    socket = useContext(SocketContext),
    { Panel } = Collapse,
    [chiTietDonHang, dispatchChiTietDonHang] = useReducer(
      chiTietDonHangReducer,
      {
        donhang: {
          shippingFee: 20000,
          date: moment().toDate(),
          dagiaohang: 'no',
        },
        sanpham: [],
        vanchuyen: {
          trackingId: '',
          fee: '',
          estimated_pick_time: '',
          estimated_deliver_time: '',
          partner: '',
          xFast: false,
        },
        provine: {},
        hamlet: [],
      }
    ),
    [loadDonHangFinished, setLoadDonHangFinish] = useState(false),
    [cauHinhVanChuyen, setCauHinhVanChuyen] = useState(''),
    [trangThaiDonHang, setTrangThaiDonHang] = useState(''),
    [menuItem, setMenuItem] = useState(''),
    [options, setOptions] = useState([]),
    [customerPhoneOption, setCustomerPhoneOption] = useState([]),
    [searchResult, setSearchResult] = useState({}),
    [customerSearchResult, setCustomerSearchResult] = useState({}),
    [openModal, setOpenModal] = useState(false),
    [customerLocationInfo, setCustomerLocationInfo] = useState({}),
    [shopLocationiIfo, setShopLocationInfo] = useState({}),
    [saveDisabled, setSaveDisabled] = useState(false),
    noithanh = [
      '1442',
      '1443',
      '1444',
      '1446',
      '1447',
      '1448',
      '1449',
      '1450',
      '1452',
      '1453',
      '1455',
      '1456',
      '1457',
      '1461',
      '1462',
    ],
    { Content } = Layout,
    [orderForm] = Form.useForm(),
    { Option } = Select,
    citiChoice = provine.city.map((item, i) => {
      return (
        <Option key={item.cityCode} value={item.cityCode}>
          {item.cityName}
        </Option>
      );
    }),
    districtChoice = provine.district.map((item, i) => {
      return (
        <Option key={item.districtCode} value={item.districtCode}>
          {item.districtName}
        </Option>
      );
    }),
    wardChoice = provine.ward.map((item, i) => {
      return (
        <Option key={item.wardCode} value={item.wardCode}>
          {item.wardName}
        </Option>
      );
    });

  const handleCancel = () => {
    dispatchChiTietDonHang({ type: 'clear-all' });

    setOpenModal(false);
  };
  const submitOrder = async (e) => {
    try {
      const submitType = e.target.parentNode.getAttribute('id');
      const donHang = {};
      let readyToSubmit = false;
      donHang.donHang = {};
      donHang.sanPham = {};

      if (
        chiTietDonHang &&
        chiTietDonHang.donhang &&
        chiTietDonHang.donhang.customer &&
        chiTietDonHang.donhang.customer.length > 0 &&
        chiTietDonHang.donhang.customer[0].firstName &&
        chiTietDonHang.donhang.customer[0].lastName &&
        chiTietDonHang.donhang.date &&
        chiTietDonHang.donhang.value &&
        chiTietDonHang.donhang.rPhone &&
        chiTietDonHang.donhang.address &&
        chiTietDonHang.donhang.name &&
        chiTietDonHang.provine &&
        chiTietDonHang.provine.cityCode &&
        chiTietDonHang.provine.districtCode &&
        chiTietDonHang.provine.wardCode &&
        chiTietDonHang.sanpham
      ) {
        setSaveDisabled(true);
        readyToSubmit = true;
        donHang.firstName = chiTietDonHang.donhang.customer[0].firstName;
        donHang.lastName = chiTietDonHang.donhang.customer[0].lastName;
        donHang.phone = chiTietDonHang.donhang.customer[0].phone;
        chiTietDonHang.dagiaohang
          ? (donHang.dagiaohang = chiTietDonHang.dagiaohang)
          : (donHang.dagiaohang = 'no');
        donHang.donHang.date = chiTietDonHang.donhang.date;
        donHang.donHang.discount = chiTietDonHang.donhang.discount;
        donHang.donHang.paid = chiTietDonHang.donhang.paid;
        donHang.donHang.provine = chiTietDonHang.provine;
        donHang.donHang.value = chiTietDonHang.donhang.value;
        chiTietDonHang.donhang.note
          ? (donHang.donHang.note = chiTietDonHang.donhang.note)
          : (donHang.donHang.note = '');
        chiTietDonHang.donhang.noteInside
          ? (donHang.donHang.noteInside = chiTietDonHang.donhang.noteInside)
          : (donHang.donHang.noteInside = '');
        donHang.donHang.rPhone = chiTietDonHang.donhang.rPhone;
        donHang.donHang.address = chiTietDonHang.donhang.address;
        donHang.donHang.shippingFee = chiTietDonHang.donhang.shippingFee;
        donHang.donHang.name = chiTietDonHang.donhang.name;
        for (let val of chiTietDonHang.sanpham) {
          donHang.sanPham[val.key] = val.quantity;
        }
      } else {
        console.log(chiTietDonHang);
        message.error('Xin vui lòng nhập đầy đủ thông tin đon hàng!');
      }

      if (readyToSubmit === true) {
        try {
          const body = JSON.stringify(donHang);
          const res = await axios.post('/api/orders', body, config);
          if (res.data.success === true) {
            socket.emit('newOrder', {
              room: user.partition,
              data: res.data.data,
            });
            if (data.currentTab === 'all') {
              Store.dispatch(addOrder(res.data.data));
            }
            if (data.currentTab === res.data.data.status) {
              Store.dispatch(addOrder(res.data.data));
            }

            Store.dispatch(updateCounterNewOrder(res.data.data.status));

            Store.dispatch(getStatistics());
            dispatchChiTietDonHang({
              type: 'clean-all',
            });
            orderForm.resetFields();
            setCauHinhVanChuyen('');
            Store.dispatch(clearAllProvine());
            Store.dispatch(initProvine(0, 0, 0, '', '', ''));
            const phiship = 20000;
            orderForm.setFieldsValue({
              shippingFee: phiship.toLocaleString('vi-VN'),
              date: moment(),
              value: 0,
              paid: 0,
              discount: 0,
            });
            setTrangThaiDonHang('');
            if (submitType === 'luu') {
              setOpenModal(false);
            }

            setSaveDisabled(false);
          } else {
            setSaveDisabled(false);
            message.error('Có lỗi trong quá trình tạo đon hàng');
          }
        } catch (err) {
          console.log(err);
          message.error('Có lỗi trong quá trình tạo đon hàng');
        }
      }

      setSaveDisabled(false);
    } catch (err) {
      setSaveDisabled(false);
      console.log(err);
      message.error(err);
    }
  };

  useEffect(() => {
    if (orderId !== 'new' && orderId !== null) {
      let product = [];
      for (let val in data.allOrder.data[orderId].product) {
        product.push({
          key: data.allOrder.data[orderId].product[val].productId,
          productName: data.allOrder.data[orderId].product[val].productName,
          quantity: data.allOrder.data[orderId].product[val].quantity,
          price: data.allOrder.data[orderId].product[val].price,
        });
      }
      const vanchuyen = {
        fee: data.allOrder.data[orderId].shippingDetail
          ? data.allOrder.data[orderId].shippingDetail.fee
          : 0,
        partner: data.allOrder.data[orderId].shippingDetail
          ? data.allOrder.data[orderId].shippingDetail.shippingPartner
          : '',
        trackingId: data.allOrder.data[orderId].shippingDetail
          ? data.allOrder.data[orderId].shippingDetail.trackingId
          : '',
        estimated_pick_time: data.allOrder.data[orderId].shippingDetail
          ? data.allOrder.data[orderId].shippingDetail.estimated_pick_time
          : '',
        estimated_deliver_time: data.allOrder.data[orderId].shippingDetail
          ? data.allOrder.data[orderId].shippingDetail.estimated_deliver_time
          : '',
        xFast: false,
      };

      dispatchChiTietDonHang({
        type: 'set-chi-tiet-don-hang',
        data: {
          donhang: data.allOrder.data[orderId],
          sanpham: product,
          vanchuyen: vanchuyen,
          provine: data.allOrder.data[orderId].provine,
          hamlet: [],
        },
      });
      setLoadDonHangFinish(true);
    }
    if (orderId === 'new') {
      setCauHinhVanChuyen('');
      dispatchChiTietDonHang({
        type: 'clean-all',
      });

      Store.dispatch(clearAllProvine());
      Store.dispatch(initProvine(0, 0, 0, '', '', ''));

      const phiship = 20000;
      orderForm.setFieldsValue({
        shippingFee: phiship.toLocaleString('vi-VN'),
        date: moment(),
      });

      setLoadDonHangFinish(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalId]);

  useEffect(() => {
    try {
      if (loadDonHangFinished === true) {
        orderForm.setFieldsValue({
          phone: chiTietDonHang.donhang.customer
            ? chiTietDonHang.donhang.customer[0].phone
            : '',
          firstName: chiTietDonHang.donhang.customer
            ? chiTietDonHang.donhang.customer[0].firstName
            : '',
          lastName: chiTietDonHang.donhang.customer
            ? chiTietDonHang.donhang.customer[0].lastName
            : '',
          address: chiTietDonHang.donhang.address
            ? chiTietDonHang.donhang.address
            : '',
          rPhone: chiTietDonHang.donhang.rPhone
            ? chiTietDonHang.donhang.rPhone
            : '',
          name: chiTietDonHang.donhang.name ? chiTietDonHang.donhang.name : '',
          shippingFee: chiTietDonHang.donhang.shippingFee
            ? chiTietDonHang.donhang.shippingFee.toLocaleString('vi-VN')
            : 0,
          discount: chiTietDonHang.donhang.discount
            ? chiTietDonHang.donhang.discount.toLocaleString('vi-VN')
            : 0,
          paid: chiTietDonHang.donhang.discount
            ? chiTietDonHang.donhang.paid.toLocaleString('vi-VN')
            : 0,
          date: moment(chiTietDonHang.donhang.date),
          note: chiTietDonHang.donhang.note ? chiTietDonHang.donhang.note : '',
          noteInside: chiTietDonHang.donhang.noteInside
            ? chiTietDonHang.donhang.noteInside
            : 0,
          chonvanchuyen: chiTietDonHang.donhang.shippingDetail
            ? chiTietDonHang.donhang.shippingDetail.shippingPartner
            : '',
          selectHamlet: '',
        });
        setTrangThaiDonHang(() => {
          if (chiTietDonHang.donhang.status) {
            return chiTietDonHang.donhang.status === 'moi'
              ? 'Đơn hàng mới'
              : chiTietDonHang.donhang.status === 'daxacnhan'
              ? 'Đã Xác nhận'
              : chiTietDonHang.donhang.status === 'chochuyenhang'
              ? 'Chờ gửi hàng'
              : chiTietDonHang.donhang.status === 'dagiaohang'
              ? 'Đã gửi hàng'
              : chiTietDonHang.donhang.status === 'danhanhang'
              ? 'Đã nhận hàng'
              : chiTietDonHang.donhang.status === 'dadoisoat'
              ? 'Đã đối soat'
              : chiTietDonHang.donhang.status === 'dahuy'
              ? 'Đã huỷ'
              : 'Chưa xác định';
          }
        });
        setCauHinhVanChuyen('');
        setMenuItem(() => {
          if (chiTietDonHang.donhang.status) {
            return chiTietDonHang.donhang.status === 'moi' ? (
              <>
                <Menu.Item disabled={saveDisabled} key='daxacnhan'>
                  Xác nhận đơn hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='chochuyenhang'>
                  Chờ chuyển hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='dagiaohang'>
                  Đã gửi hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='danhanhang'>
                  Đã nhận hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='dahuy'>
                  Huỷ đơn hàng
                </Menu.Item>
              </>
            ) : chiTietDonHang.donhang.status === 'daxacnhan' ? (
              <>
                <Menu.Item disabled={saveDisabled} key='daxacnhan'>
                  Xác nhận đơn hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='chochuyenhang'>
                  Chờ chuyển hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='dagiaohang'>
                  Đã gửi hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='danhanhang'>
                  Đã nhận hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='dahuy'>
                  Huỷ đơn hàng
                </Menu.Item>
              </>
            ) : chiTietDonHang.donhang.status === 'chochuyenhang' ? (
              <>
                <Menu.Item disabled={saveDisabled} key='dagiaohang'>
                  Đã gửi hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='danhanhang'>
                  Đã nhận hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='dahuy'>
                  Huỷ đơn hàng
                </Menu.Item>
              </>
            ) : chiTietDonHang.donhang.status === 'dagiaohang' ? (
              <>
                <Menu.Item disabled={saveDisabled} key='danhanhang'>
                  Đã nhận hàng
                </Menu.Item>
                <Menu.Item disabled={saveDisabled} key='dahuy'>
                  Huỷ đơn hàng
                </Menu.Item>
              </>
            ) : (
              ''
            );
          }
        });
        setLoadDonHangFinish(false);
        setOpenModal(true);
      }
    } catch (err) {
      console.log(err);
      message.error('Không thể tải thông tin đơn hàng');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDonHangFinished]);

  const modalFotter = chiTietDonHang['donhang']['_id']
      ? [
          <Button key='back' type='danger' onClick={handleCancel}>
            Đóng
          </Button>,
        ]
      : [
          <Button
            key='luu'
            type='primary'
            htmlType='submit'
            id='luu'
            onClick={submitOrder}
            disabled={saveDisabled}
          >
            Lưu
          </Button>,
          <Button
            key='saveAndNew'
            type='primary'
            htmlType='submit'
            id='saveAndNew'
            onClick={submitOrder}
            disabled={saveDisabled}
          >
            Lưu và Tạo Mới
          </Button>,
          <Button key='cancel' onClick={handleCancel}>
            Huỷ
          </Button>,
        ],
    handleMenuClick = async (e) => {
      try {
        const trangThaiXacNhan = e.key;
        setSaveDisabled(true);
        if (trangThaiXacNhan === 'daxacnhan') {
          const shippingPartner = orderForm.getFieldValue('chonvanchuyen');
          if (!shippingPartner) {
            setSaveDisabled(false);
            message.error(
              'vui lòng chọn đơn vị vận chuyển trươc khi xác nhận đơn hàng'
            );
          } else {
            const donHang = {};
            let readyToSubmit = false;
            donHang.donHang = {};
            donHang.sanPham = {};

            if (
              chiTietDonHang &&
              chiTietDonHang.donhang.customer &&
              chiTietDonHang.donhang.customer.length > 0 &&
              chiTietDonHang.donhang.customer[0].firstName &&
              chiTietDonHang.donhang.customer[0].lastName &&
              chiTietDonHang.donhang &&
              chiTietDonHang.donhang.date &&
              chiTietDonHang.donhang.value &&
              chiTietDonHang.donhang.rPhone &&
              chiTietDonHang.donhang.address &&
              chiTietDonHang.donhang.name &&
              chiTietDonHang.provine &&
              chiTietDonHang.provine.cityCode &&
              chiTietDonHang.provine.districtCode &&
              chiTietDonHang.provine.wardCode &&
              chiTietDonHang.sanpham
            ) {
              readyToSubmit = true;
              donHang.firstName = chiTietDonHang.donhang.customer[0].firstName;
              donHang.lastName = chiTietDonHang.donhang.customer[0].lastName;
              donHang.phone = chiTietDonHang.donhang.customer[0].phone;
              chiTietDonHang.dagiaohang
                ? (donHang.dagiaohang = chiTietDonHang.dagiaohang)
                : (donHang.dagiaohang = 'no');
              donHang.donHang.date = chiTietDonHang.donhang.date;
              donHang.donHang.discount = chiTietDonHang.donhang.discount;
              donHang.donHang.paid = chiTietDonHang.donhang.paid;
              donHang.donHang.provine = chiTietDonHang.provine;
              donHang.donHang.value = chiTietDonHang.donhang.value;
              donHang.donHang.status = trangThaiXacNhan;
              chiTietDonHang.donhang.note
                ? (donHang.donHang.note = chiTietDonHang.donhang.note)
                : (donHang.donHang.note = '');
              chiTietDonHang.donhang.noteInside
                ? (donHang.donHang.noteInside =
                    chiTietDonHang.donhang.noteInside)
                : (donHang.donHang.noteInside = '');
              donHang.donHang.rPhone = chiTietDonHang.donhang.rPhone;
              donHang.donHang.address = chiTietDonHang.donhang.address;
              donHang.donHang.shippingFee = chiTietDonHang.donhang.shippingFee;
              donHang.donHang.name = chiTietDonHang.donhang.name;
              for (let val of chiTietDonHang.sanpham) {
                donHang.sanPham[val.key] = val.quantity;
              }
              donHang.id = chiTietDonHang.donhang._id;
              donHang.partnerName = shippingPartner;
              if (shippingPartner === 'ghtk') {
                donHang.hamlet = orderForm.getFieldValue('selectHamlet')
                  ? orderForm.getFieldValue('selectHamlet')
                  : 'khác';
                donHang.shippingMethod = document.querySelector(
                  "input[type='radio'][name='vanchuyen']:checked"
                ).value;
                donHang.cagiao = document.querySelector(
                  "input[type='radio'][name='cagiao']:checked"
                ).value;
                donHang.pick_option = 'cod';
                donHang.deliver_option = document.querySelector(
                  'input[name="giaoxfast"]:checked'
                )
                  ? document.querySelector('input[name="giaoxfast"]:checked')
                      .value
                  : 'none';
                donHang.giaotag =
                  document.querySelector('input[name="tuychondevo"]:checked')
                    .value === 'codevo'
                    ? [1]
                    : [0];
              }
              if (shippingPartner === 'ghn') {
                donHang.shippingMethod = document.querySelector(
                  "input[type='radio'][name='vanchuyen']:checked"
                ).value;
                donHang.customerLocationInfo = customerLocationInfo;
                donHang.shopLocationiIfo = shopLocationiIfo;
              }
              const submitResult = await axios.put(
                '/api/orders',
                donHang,
                config
              );

              if (submitResult.data.success === true) {
                message.success('Đã xác nhận đơn hàng thành công');
                socket.emit('changeOrderStatus', {
                  room: user.partition,
                  data: submitResult.data.result,
                  oldStatus: chiTietDonHang.donhang.status,
                  newStatus: trangThaiXacNhan,
                });

                if (data.currentTab === 'moi') {
                  Store.dispatch(
                    deleteOrder(
                      submitResult.data.result._id,
                      chiTietDonHang.donhang.status,
                      trangThaiXacNhan
                    )
                  );
                }
                if (data.currentTab === 'daxacnhan') {
                  Store.dispatch(
                    updateOrder(
                      submitResult.data.result,
                      chiTietDonHang.donhang.status,
                      trangThaiXacNhan
                    )
                  );
                }
                if (data.currentTab === 'all') {
                  Store.dispatch(
                    updateOrder(
                      submitResult.data.result,
                      chiTietDonHang.donhang.status,
                      trangThaiXacNhan
                    )
                  );
                }
                dispatchChiTietDonHang({
                  type: 'change-order-status',
                  payload: trangThaiXacNhan,
                  payload1: {
                    fee: submitResult.data.result.shippingDetail.fee,
                    partner:
                      submitResult.data.result.shippingDetail.shippingPartner,
                    trackingId:
                      submitResult.data.result.shippingDetail.trackingId,
                    estimated_pick_time:
                      submitResult.data.result.shippingDetail
                        .estimated_pick_time,
                    estimated_deliver_time:
                      submitResult.data.result.shippingDetail
                        .estimated_deliver_time,
                  },
                });

                setTrangThaiDonHang('Đã Xác Nhận');
                setSaveDisabled(false);
              } else {
                setSaveDisabled(false);
                message.error(
                  `Có lỗi sau đây trong lúc cập nhật trạng thái đon hàng: ${submitResult.data.error}`
                );
              }
            } else {
              setSaveDisabled(false);
              message.error('Xin vui lòng nhập đầy đủ thông tin đon hàng!');
            }
          }
        } else {
          const submitResult = await axios.put(
            '/api/orders',
            {
              id: chiTietDonHang.donhang._id,
              donHang: { status: trangThaiXacNhan },
            },
            config
          );
          if (submitResult.data.success === true) {
            message.success('Đã cập nhật trạng thái đơn hàng thành công');
            socket.emit('changeOrderStatus', {
              room: user.partition,
              data: submitResult.data.result,
              oldStatus: chiTietDonHang.donhang.status,
              newStatus: trangThaiXacNhan,
            });
            if (data.currentTab === 'all') {
              Store.dispatch(
                updateOrder(
                  submitResult.data.result,
                  chiTietDonHang.donhang.status,
                  trangThaiXacNhan
                )
              );
            } else if (
              data.currentTab === chiTietDonHang.donhang.status &&
              data.currentTab !== trangThaiXacNhan
            ) {
              Store.dispatch(
                deleteOrder(
                  submitResult.data.result._id,
                  chiTietDonHang.donhang.status,
                  trangThaiXacNhan
                )
              );
            } else if (
              data.currentTab === trangThaiXacNhan &&
              data.currentTab !== chiTietDonHang.donhang.status
            ) {
              Store.dispatch(addOrder(submitResult.data.result));
              Store.dispatch(
                updateCounter(chiTietDonHang.donhang.status, trangThaiXacNhan)
              );
            } else if (
              data.currentTab === trangThaiXacNhan &&
              data.currentTab === chiTietDonHang.donhang.status
            ) {
              Store.dispatch(
                updateOrder(
                  submitResult.data.result,
                  chiTietDonHang.donhang.status,
                  trangThaiXacNhan
                )
              );
            } else {
              Store.dispatch(
                updateCounter(chiTietDonHang.donhang.status, trangThaiXacNhan)
              );
            }
            if (trangThaiXacNhan === 'dahuy') {
              Store.dispatch(getStatistics());
            }
            dispatchChiTietDonHang({
              type: 'change-order-status',
              payload: trangThaiXacNhan,
              payload1: {
                fee: submitResult.data.result.shippingDetail.fee
                  ? submitResult.data.result.shippingDetail.fee
                  : '',
                partner: submitResult.data.result.shippingDetail.shippingPartner
                  ? submitResult.data.result.shippingDetail.shippingPartner
                  : '',
                trackingId: submitResult.data.result.shippingDetail.trackingId
                  ? submitResult.data.result.shippingDetail.trackingId
                  : '',
                estimated_pick_time: submitResult.data.result.shippingDetail
                  .estimated_pick_time
                  ? submitResult.data.result.shippingDetail.estimated_pick_time
                  : '',
                estimated_deliver_time: submitResult.data.result.shippingDetail
                  .estimated_deliver_time
                  ? submitResult.data.result.shippingDetail
                      .estimated_deliver_time
                  : '',
              },
            });
            setTrangThaiDonHang(() => {
              if (trangThaiXacNhan) {
                return trangThaiXacNhan === 'moi'
                  ? 'Đơn hàng mới'
                  : trangThaiXacNhan === 'daxacnhan'
                  ? 'Đã Xác nhận'
                  : trangThaiXacNhan === 'chochuyenhang'
                  ? 'Chờ gửi hàng'
                  : trangThaiXacNhan === 'dagiaohang'
                  ? 'Đã gửi hàng'
                  : trangThaiXacNhan === 'danhanhang'
                  ? 'Đã nhận hàng'
                  : trangThaiXacNhan === 'dadoisoat'
                  ? 'Đã đối soat'
                  : trangThaiXacNhan === 'dahuy'
                  ? 'Đã huỷ'
                  : 'Chưa xác định';
              }
            });
          }
          setSaveDisabled(false);
        }
      } catch (err) {
        setSaveDisabled(false);
        console.log(err);
        message.error('Có lỗi không thể xác nhận đơn hàng');
      }
    },
    updateOrderMenu = <Menu onClick={handleMenuClick}>{menuItem}</Menu>,
    detailProductsColumn = [
      {
        title: 'Tên Sản Phẩm',
        dataIndex: 'productName',
        width: 300,
      },
      {
        title: 'Giá',
        dataIndex: 'price',
        width: 90,
      },
      {
        title: 'SL',
        dataIndex: 'quantity',
        render: (text, record) => {
          if (
            !chiTietDonHang.donhang.status ||
            chiTietDonHang.donhang.status === 'moi' ||
            chiTietDonHang.donhang.status === 'daxacnhan'
          ) {
            return (
              <Input
                type='number'
                name='productquantity'
                defaultValue={text}
                style={{ width: '65px' }}
                data-productid={record.key}
                onChange={(e) => {
                  handleProductQuantityChange(e);
                }}
              ></Input>
            );
          } else {
            return <center>{text}</center>;
          }
        },
        width: 100,
      },
      {
        title: 'Xoá',
        dataIndex: 'operation',
        render: (_, record) => {
          if (
            !chiTietDonHang.donhang.status ||
            chiTietDonHang.donhang.status === 'moi' ||
            chiTietDonHang.donhang.status === 'daxacnhan'
          ) {
            return (
              <Popconfirm
                title='Xác nhận xoá sản phẩm?'
                onConfirm={() => handleDelete(record.key)}
              >
                <Button type='link' style={{ padding: 0 }}>
                  Delete
                </Button>
              </Popconfirm>
            );
          }
        },
      },
    ],
    detailProductsColumnMobile = [
      {
        title: 'Tên SP',
        dataIndex: 'productName',
      },
      {
        title: 'Giá',
        dataIndex: 'price',
        width: '70px',
        render: (text) => {
          return text.toLocaleString('vi-VN');
        },
      },
      {
        title: 'SL',
        dataIndex: 'quantity',
        width: '50px',
        render: (text, record) => {
          if (
            !chiTietDonHang.donhang.status ||
            chiTietDonHang.donhang.status === 'moi' ||
            chiTietDonHang.donhang.status === 'daxacnhan'
          ) {
            return (
              <Input
                type='number'
                name='productquantity'
                defaultValue={text}
                style={{ width: '40px' }}
                data-productid={record.key}
                onChange={(e) => {
                  handleProductQuantityChange(e);
                }}
              ></Input>
            );
          } else {
            return <center>{text}</center>;
          }
        },
      },
      {
        title: 'Xoá',
        dataIndex: 'operation',
        width: '55px',
        render: (_, record) => {
          if (
            !chiTietDonHang.donhang.status ||
            chiTietDonHang.donhang.status === 'moi' ||
            chiTietDonHang.donhang.status === 'daxacnhan'
          ) {
            return (
              <Popconfirm
                title='Xác nhận xoá sản phẩm?'
                onConfirm={() => handleDelete(record.key)}
                style={{ textAlign: 'center' }}
              >
                <Button type='link' style={{ padding: 0 }}>
                  <center>
                    <DeleteOutlined
                      style={{ fontSize: '16px', color: '#cf1322' }}
                    />
                  </center>
                </Button>
              </Popconfirm>
            );
          }
        },
      },
    ],
    hadlerChonVanChuyen = async (e) => {
      if (e === 'ghtk') {
        setCauHinhVanChuyen(
          <div
            style={{
              height: 128,
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin size='large' />
          </div>
        );
        try {
          orderForm.setFieldsValue({ selectHamlet: '' });
          const getHamletData = {
            city: chiTietDonHang.donhang.provine.city,
            district: chiTietDonHang.donhang.provine.district,
            ward: chiTietDonHang.donhang.provine.ward,
          };

          const checkData = {
            transport: 'fly',
            tuychondevo: 0,
            tocdogiao: 'none',
            city: chiTietDonHang.donhang.provine.city,
            district: chiTietDonHang.donhang.provine.district,
            ward: chiTietDonHang.donhang.provine.ward,
            address: chiTietDonHang.donhang.address,
            weight: chiTietDonHang.donhang.weight,
            value: chiTietDonHang.donhang.conlai,
          };
          const getHamlet = await axios.post(
            `/api/gethamlet/ghtk`,
            JSON.stringify(getHamletData),
            config
          );
          const checkxfast = await axios.post(
            '/api/tinhphiship/checkxfast',
            JSON.stringify(checkData),
            config
          );
          const tinhphiship = await axios.post(
            '/api/tinhphiship/ghtk',
            JSON.stringify(checkData),
            config
          );
          if (getHamlet.data.success === true) {
            dispatchChiTietDonHang({
              type: 'change-hamlet',
              payload: getHamlet.data.data,
            });
            console.log(chiTietDonHang);
          }
          if (tinhphiship.data.success === true) {
            dispatchChiTietDonHang({
              type: 'set-thong-tin-van-chuyen',
              data: {
                fee: tinhphiship.data.fee.fee,
                partner: 'ghtk',
                trackingId: '',
                estimated_pick_time: '',
                estimated_deliver_time: '',
                xFast: checkxfast.data.success,
              },
            });
            setCauHinhVanChuyen(
              <Content
                style={{
                  marginTop: '10px',
                  backgroundColor: '#e6fffb',
                  padding: '5px 0',
                }}
              >
                <Row>
                  <Col
                    xs={24}
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Cấu hình vận chuyển GHTK:
                  </Col>
                </Row>
                <Row style={{ padding: '0px 10px' }}>
                  <Col xs={10}>Chọn Phương tiện vận chuyển:</Col>
                  <Col xs={14}>
                    <Radio.Group
                      className='ant-radio-flex'
                      name='vanchuyen'
                      defaultValue='road'
                      onChange={(e) => handlerTinhVanChuyenGHTK(e)}
                    >
                      <Col xs={10}>
                        <Radio value='fly'>Đường bay</Radio>
                      </Col>
                      <Col xs={8}>
                        <Radio value='road' checked={true}>
                          Đường bộ
                        </Radio>
                      </Col>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row style={{ padding: '5px 10px' }}>
                  <Col xs={10}>Chọn ca giao:</Col>
                  <Col xs={14}>
                    <Radio.Group
                      name='cagiao'
                      defaultValue='giaotheoca'
                      className='ant-radio-flex'
                      onChange={(e) => handlerTinhVanChuyenGHTK(e)}
                    >
                      <Col xs={10}>
                        <Radio value='giaotheoca'>Giao theo ca</Radio>
                      </Col>
                      <Col xs={8}>
                        <Radio value='giaotoi'>Giao tối</Radio>
                      </Col>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row style={{ padding: '5px 10px' }}>
                  <Col xs={10}>Hàng dễ vỡ:</Col>
                  <Col xs={14}>
                    <Radio.Group
                      name='tuychondevo'
                      defaultValue='khongdevo'
                      className='ant-radio-flex'
                      onChange={(e) => handlerTinhVanChuyenGHTK(e)}
                    >
                      <Col xs={10}>
                        <Radio value='khongdevo'>Không</Radio>
                      </Col>
                      <Col xs={8}>
                        <Radio value='codevo'>Có</Radio>
                      </Col>
                    </Radio.Group>
                  </Col>
                </Row>
                {chiTietDonHang.vanchuyen.xFast && (
                  <Row style={{ padding: '5px 10px' }}>
                    <Col xs={10}>Giao nhanh 2 giờ (xFast):</Col>
                    <Col xs={14}>
                      <Radio.Group
                        name='giaoxfast'
                        defaultValue='khong'
                        className='ant-radio-flex'
                        onChange={(e) => handlerTinhVanChuyenGHTK(e)}
                      >
                        <Col xs={10}>
                          <Radio value='khong'>Không</Radio>
                        </Col>
                        <Col xs={8}>
                          <Radio value='xfast'>Có</Radio>
                        </Col>
                      </Radio.Group>
                    </Col>
                  </Row>
                )}
              </Content>
            );
          } else {
            message.error(
              'Không thể lấy được thông tin phí ship từ Giao Hàng Tiết Kiệm'
            );
          }
        } catch (err) {
          message.error(
            'Không thể lấy được thông tin phí ship từ Giao Hàng Tiết Kiệm'
          );
        }
      } else if (e === 'ghn') {
        setCauHinhVanChuyen(
          <div
            style={{
              height: 128,
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin size='large' />
          </div>
        );
        try {
          const checkData = {
            transport: 'fly',
            city: chiTietDonHang.donhang.provine.city,
            district: chiTietDonHang.donhang.provine.district,
            ward: chiTietDonHang.donhang.provine.ward,
            address: chiTietDonHang.donhang.address,
            weight: chiTietDonHang.donhang.weight,
            value: chiTietDonHang.donhang.conlai,
          };

          const tinhphiship = await axios.post(
            `/api/tinhphiship/ghn`,
            checkData,
            config
          );
          if (tinhphiship.data.success === false) {
            message.error(
              `Không thể tính phí ship GHN với lỗi: ${tinhphiship.data.error}`
            );
          } else {
            setCustomerLocationInfo({ ...tinhphiship.data.customerLocationId });
            setShopLocationInfo({ ...tinhphiship.data.shopLocationId });
            dispatchChiTietDonHang({
              type: 'set-thong-tin-van-chuyen',
              data: {
                fee: tinhphiship.data.phiship.total,
                partner: 'ghn',
                trackingId: '',
                estimated_pick_time: '',
                estimated_deliver_time: '',
              },
            });

            setCauHinhVanChuyen((prev) => {
              return (
                <Content
                  style={{
                    marginTop: '10px',
                    backgroundColor: '#e6fffb',
                    padding: '5px 0',
                  }}
                >
                  <Row>
                    <Col
                      xs={24}
                      style={{ textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Cấu hình vận chuyển GHN:
                    </Col>
                  </Row>
                  <Row style={{ padding: '0px 10px' }}>
                    <Col xs={8}>Phương Thức Vận Chuyển:</Col>
                    <Col xs={16}>
                      <Radio.Group
                        className='ant-radio-flex'
                        name='vanchuyen'
                        defaultValue={tinhphiship.data.service[0].service_id}
                        onChange={(e) => handlerTinhVanChuyenGHN(e)}
                      >
                        {tinhphiship.data.service.map((item, index) => (
                          <Radio
                            value={item.service_id}
                            id={item.service_id}
                            key={item.service_id}
                            checked={index === 0 ? true : false}
                          >
                            {item.short_name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Col>
                  </Row>
                </Content>
              );
            });
          }
        } catch (err) {
          console.log(err);
          message.error(`Không thể tính phí ship GHN với lỗi: ${err}`);
        }
      } else {
        setCauHinhVanChuyen('');
      }
    },
    handlerTinhVanChuyenGHN = async (e) => {
      try {
        const checkData = {
          city: chiTietDonHang.donhang.provine.city,
          district: chiTietDonHang.donhang.provine.district,
          ward: chiTietDonHang.donhang.provine.ward,
          address: chiTietDonHang.donhang.address,
          weight: chiTietDonHang.donhang.weight,
          value: chiTietDonHang.donhang.conlai,
          customerLocationInfo: customerLocationInfo,
          shopLocationInfo: shopLocationiIfo,
          service_id: e.target.value,
        };

        const tinhphiship = await axios.post(
          `/api/tinhphiship/ghn`,
          checkData,
          config
        );
        if (tinhphiship.data.success === false) {
          message.error(
            `Không thể tính phí ship GHN với lỗi: ${tinhphiship.data.error}`
          );
        } else {
          dispatchChiTietDonHang({
            type: 'set-thong-tin-van-chuyen',
            data: {
              fee: tinhphiship.data.phiship.total,
              partner: 'ghn',
              trackingId: '',
              estimated_pick_time: '',
              estimated_deliver_time: '',
            },
          });
        }
      } catch (err) {
        console.log(err);
        message.error(`Có lỗi khi tính phí Ship GHN với lỗi: ${err}`);
      }
    },
    handleProductQuantityChange = (e) => {
      const quantity = e.target.value,
        productId = e.target.dataset.productid;
      dispatchChiTietDonHang({
        type: 'update-product',
        productId: productId,
        quantity: quantity,
      });
    },
    handleDelete = (item) => {
      dispatchChiTietDonHang({ type: 'xoa-san-pham', productId: item });
    },
    handlerTinhVanChuyenGHTK = async (e) => {
      try {
        const checkData = {
          transport: document.querySelector('input[name="vanchuyen"]:checked')
            .value,
          tuychondevo: document.querySelector(
            'input[name="tuychondevo"]:checked'
          ).value,
          tocdogiao: document.querySelector('input[name="giaoxfast"]:checked')
            ? document.querySelector('input[name="giaoxfast"]:checked').value
            : 'none',
          city: chiTietDonHang.donhang.provine.city,
          district: chiTietDonHang.donhang.provine.district,
          ward: chiTietDonHang.donhang.provine.ward,
          address: chiTietDonHang.donhang.address,
          weight: chiTietDonHang.donhang.weight,
          value: chiTietDonHang.donhang.conlai,
        };
        const tinhphiship = await axios.post(
          '/api/tinhphiship/ghtk',
          JSON.stringify(checkData),
          config
        );
        if (tinhphiship.data.success === true) {
          dispatchChiTietDonHang({
            type: 'set-thong-tin-van-chuyen',
            data: {
              fee: tinhphiship.data.fee.fee,
              partner: 'ghtk',
              trackingId: '',
              estimated_pick_time: '',
              estimated_deliver_time: '',
              xFast: false,
            },
          });
        } else {
          message.error(
            'Không thể lấy được thông tin phí ship từ Giao Hàng Tiết Kiệm'
          );
        }
      } catch (err) {
        console.log(err);
        message.error(
          'Không thể lấy được thông tin phí ship từ Giao Hàng Tiết Kiệm'
        );
      }
    },
    handlePhoneChange = (e) => {
      console.log(chiTietDonHang);
      dispatchChiTietDonHang({
        type: 'update-customer',
        adition: { rPhone: e.target.value },
        data: { phone: e.target.value },
      });

      orderForm.setFieldsValue({ rPhone: e.target.value });
    },
    handleFirstNameChange = (e) => {
      dispatchChiTietDonHang({
        type: 'update-customer',
        adition: {
          name:
            document.getElementById('lastName').value + ' ' + e.target.value,
        },
        data: { firstName: e.target.value },
      });
      orderForm.setFieldsValue({
        name: document.getElementById('lastName').value + ' ' + e.target.value,
      });
    },
    handleLastNameChange = (e) => {
      dispatchChiTietDonHang({
        type: 'update-customer',
        adition: {
          name:
            e.target.value + ' ' + document.getElementById('firstName').value,
        },
        data: { lastName: e.target.value },
      });

      orderForm.setFieldsValue({
        name: e.target.value + ' ' + document.getElementById('firstName').value,
      });
    },
    handleOtherChange = (e, datestring = '', id = '') => {
      if (id === 1) {
        dispatchChiTietDonHang({
          type: 'update-order',
          data: { date: e.toDate() },
        });
      } else {
        const change = {};
        change[e.target.id] = e.target.value;
        dispatchChiTietDonHang({
          type: 'update-order',
          data: change,
        });
      }
    },
    handleShippingFeeChange = (e) => {
      const phiShip = e.target.value
          ? parseInt(e.target.value.replace('.', ''))
          : 0,
        conLai =
          chiTietDonHang.donhang.value +
          phiShip -
          chiTietDonHang.donhang.discount -
          chiTietDonHang.donhang.paid;
      dispatchChiTietDonHang({
        type: 'update-order',
        data: { shippingFee: phiShip, conlai: conLai },
      });
      orderForm.setFieldsValue({
        shippingFee: phiShip ? phiShip.toLocaleString('vi-VN') : 0,
      });
    },
    handleDiscountChange = (e) => {
      const discount = e.target.value
          ? parseInt(e.target.value.replace('.', ''))
          : 0,
        conLai =
          chiTietDonHang.donhang.value +
          chiTietDonHang.donhang.shippingFee -
          discount -
          chiTietDonHang.donhang.paid;
      dispatchChiTietDonHang({
        type: 'update-order',
        data: { discount: discount, conlai: conLai },
      });

      orderForm.setFieldsValue({
        discount: discount ? discount.toLocaleString('vi-VN') : 0,
      });
    },
    handlePaidChange = (e) => {
      const paid = e.target.value
          ? parseInt(e.target.value.replace('.', ''))
          : 0,
        conLai =
          chiTietDonHang.donhang.value +
          chiTietDonHang.donhang.shippingFee -
          chiTietDonHang.donhang.discount -
          paid;
      dispatchChiTietDonHang({
        type: 'update-order',
        data: { paid: paid, conlai: conLai },
      });
      orderForm.setFieldsValue({
        paid: paid ? paid.toLocaleString('vi-VN') : 0,
      });
    },
    handleCityChange = async (value, option) => {
      try {
        Store.dispatch(initProvine(value, 0, 0, option.children, '', ''));
        Store.dispatch(saveDistrict(value));
        Store.dispatch(saveWard([]));
        dispatchChiTietDonHang({
          type: 'update-provine',
          data: {
            provine: {
              city: option.children,
              cityCode: value,
              district: '',
              districtCode: '',
              ward: '',
              wardCode: '',
            },
            shippingFee: 20000,
          },
        });
      } catch (err) {
        console.log(err);
        message.error('error fetching district');
      }
    },
    handleDistrictChange = async (value, option) => {
      try {
        const phiship = noithanh.includes(value) ? 20000 : 30000;

        Store.dispatch(changeDistrict(value, option.children));
        dispatchChiTietDonHang({
          type: 'update-order',
          data: {
            provine: {
              city: chiTietDonHang.donhang.provine.city,
              cityCode: chiTietDonHang.donhang.provine.cityCode,
              district: option.children,
              districtCode: value,
              ward: '',
              wardCode: '',
            },
            shippingFee: phiship,
          },
          provine: {
            city: chiTietDonHang.donhang.provine.city,
            cityCode: chiTietDonHang.donhang.provine.cityCode,
            district: option.children,
            districtCode: value,
            ward: '',
            wardCode: '',
          },
        });
        orderForm.setFieldsValue({
          shippingFee: phiship.toLocaleString('vi-VN'),
        });
      } catch (err) {
        console.log(err);
        message.error('error fetching district');
      }
    },
    handleWardChange = (value, option) => {
      Store.dispatch(changeWard(value, option.children));
      dispatchChiTietDonHang({
        type: 'update-order',
        data: {
          provine: {
            city: chiTietDonHang.donhang.provine.city,
            cityCode: chiTietDonHang.donhang.provine.cityCode,
            district: chiTietDonHang.donhang.provine.district,
            districtCode: chiTietDonHang.donhang.provine.districtCode,
            ward: option.children,
            wardCode: value,
          },
        },
        provine: {
          city: chiTietDonHang.donhang.provine.city,
          cityCode: chiTietDonHang.donhang.provine.cityCode,
          district: chiTietDonHang.donhang.provine.district,
          districtCode: chiTietDonHang.donhang.provine.districtCode,
          ward: option.children,
          wardCode: value,
        },
      });
    },
    handleSelect = (data) => {
      dispatchChiTietDonHang({
        type: 'them-san-pahm',
        data: searchResult[data],
      });
      orderForm.setFieldsValue({ sanpham: '' });
      setOptions([]);
    },
    handleSearch = async (searchTerm) => {
      if (searchTerm.length === 0) {
        setOptions([]);
        setSearchResult({});
      }
      if (searchTerm.length >= 3) {
        try {
          setOptions((prev) => []);
          const body = JSON.stringify({ searchTerm: searchTerm });
          const res = await axios.post(`/api/products/search/`, body, config),
            auto = [],
            result = {};
          if (res.data.success) {
            for (let val of res.data.products) {
              const label =
                val.quantity <= 0 ? (
                  <span style={{ color: 'red' }}>
                    {val.productName} - Giá:
                    {val.outPrice.toLocaleString('vi-VN')}
                  </span>
                ) : (
                  `${val.productName} - Giá: ${val.outPrice.toLocaleString(
                    'vi-VN'
                  )} `
                );
              auto.push({
                value: val._id,
                label: label,
                key: val._id,
              });
              result[val._id] = val;
            }
            setOptions([...auto]);
            setSearchResult({ ...result });
          }
        } catch (err) {
          console.log(err);
          message.error('Có lỗi khi tìm kiếm sản phẩm');
        }
      }
    },
    handlePhoneSelect = () => {
      handleCityChange(customerSearchResult.provine.cityCode, {
        key: customerSearchResult.provine.cityCode,
        value: customerSearchResult.provine.cityCode,
        children: customerSearchResult.provine.city,
      });
      handleDistrictChange(customerSearchResult.provine.districtCode, {
        key: customerSearchResult.provine.districtCode,
        value: customerSearchResult.provine.districtCode,
        children: customerSearchResult.provine.district,
      });
      handleWardChange(customerSearchResult.provine.wardCode, {
        key: customerSearchResult.provine.wardCode,
        value: customerSearchResult.provine.wardCode,
        children: customerSearchResult.provine.wardCode,
      });
      dispatchChiTietDonHang({
        type: 'chon-khach-hang-cu',
        data: customerSearchResult,
      });
      setCustomerPhoneOption([]);
      orderForm.setFieldsValue({ address: customerSearchResult.address });
      orderForm.setFieldsValue({ firstName: customerSearchResult.firstName });
      orderForm.setFieldsValue({ lastName: customerSearchResult.lastName });
      orderForm.setFieldsValue({
        name: `${customerSearchResult.lastName} ${customerSearchResult.firstName} `,
      });
    },
    handlePhoneSearch = async (searchTerm) => {
      if (searchTerm.length === 0) {
        setCustomerPhoneOption([]);
        setCustomerSearchResult({});
      }
      if (searchTerm.length >= 10) {
        try {
          setCustomerPhoneOption((prev) => []);
          const body = JSON.stringify({ searchTerm: searchTerm });
          const res = await axios.post(`/api/customers/search`, body, config);

          console.log(res);
          if (res.data.success) {
            setCustomerPhoneOption([
              {
                label: res.data.customers.label,
                value: res.data.customers.value,
              },
            ]);
            setCustomerSearchResult({ ...res.data.customers });
          }
        } catch (err) {
          console.log(err);
          message.error('Có lỗi khi tìm kiếm sản phẩm');
        }
      }
    },
    handleChonDaGiaoHang = (e) => {
      e.target.checked
        ? dispatchChiTietDonHang({ type: 'chon-don-hang-da-giao' })
        : dispatchChiTietDonHang({ type: 'bo-chon-don-hang-da-giao' });
    },
    onFinish = () => {},
    onFinishFailed = () => {};
  const hamletChoice = chiTietDonHang.hamlet.map((item, i) => {
    // return (
    //   <Option key={item} value={item}>
    //     {item}
    //   </Option>
    // );
    return { value: item, label: item };
  });
  if (isMobile) {
    return (
      <>
        <Modal
          visible={openModal}
          onOk={handleOk}
          onCancel={() => {
            dispatchChiTietDonHang({ type: 'clear-all' });
            handleCancel();
          }}
          footer={modalFotter}
          width='95%'
          style={{ top: 20 }}
          motion={null}
          bodyStyle={{ height: '600px', overflow: 'scroll' }}
        >
          <Form
            name='order-form'
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={orderForm}
            autoComplete='off'
          >
            <input type='hidden' value='something' />
            {chiTietDonHang.donhang['_id'] && (
              <>
                <Row
                  justify='right'
                  style={{ margin: '8px 16px 8px 0', textAlign: 'right' }}
                >
                  <Button danger style={{ margin: '0 8px  0 8px' }}>
                    {trangThaiDonHang}
                  </Button>
                  <Dropdown
                    overlay={updateOrderMenu}
                    overlayClassName='chon-trang-thai-don-hang'
                  >
                    <Button type='primary'>
                      Cập nhật trạng thái
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Row>
              </>
            )}
            {chiTietDonHang.donhang['_id'] &&
              chiTietDonHang.donhang.status !== 'dahuy' &&
              chiTietDonHang.donhang.status !== 'danhanhang' &&
              chiTietDonHang.donhang.status !== 'dagiaohang' &&
              chiTietDonHang.donhang.status !== 'dadoisoat' && (
                <Row align='center'>
                  <Col
                    xs={11}
                    style={{
                      marginBottom: '5px',
                      marginTop: '5px',

                      fontWeight: 'bold',
                    }}
                  >
                    Chọn vận chuyển:
                  </Col>
                  <Col xs={13}>
                    <Form.Item
                      name='chonvanchuyen'
                      style={{ marginBottom: '5px', marginTop: '5px' }}
                    >
                      <Select onChange={hadlerChonVanChuyen}>
                        <Option value='tugiao'>Tự Giao</Option>
                        <Option value='grab'>Grab</Option>
                        {user.ghtkApiKey && (
                          <Option value='ghtk'>Giao Hàng Tiết Kiệm</Option>
                        )}
                        {user.ghnApiKey && (
                          <Option value='ghn'>Giao Hàng Nhanh</Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            <Row>{cauHinhVanChuyen}</Row>
            {chiTietDonHang.vanchuyen.partner === 'ghtk' ? (
              <Row>
                <List
                  header={<center>Thông tin vận chuyển</center>}
                  bordered={false}
                  style={{
                    backgroundColor: '#fffbe6',
                    fontWeight: 'bold',
                    width: '100%',
                    paddingBottom: '10px',
                  }}
                >
                  <div className='ant-row-flex'>
                    <Col xs={8}>
                      <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                        <span style={{ color: '#ff4d4f' }}>Phi Ship:</span>
                        <span
                          style={{
                            float: 'right',
                            marginRight: '15px',
                            color: '#3f6600',
                          }}
                        >
                          {chiTietDonHang.vanchuyen.fee
                            ? parseInt(
                                chiTietDonHang.vanchuyen.fee
                              ).toLocaleString('vi-VN')
                            : 0}
                        </span>
                      </List.Item>
                      <List.Item style={{ borderBottom: 0, padding: '5px 0' }}>
                        <span style={{ color: '#ff4d4f' }}> Mã vận đơn:</span>
                        <span
                          style={{
                            float: 'right',
                            marginRight: '15px',
                            color: '#3f6600',
                          }}
                        >
                          {chiTietDonHang.vanchuyen.trackingId}
                        </span>
                      </List.Item>
                    </Col>
                    <Col xs={12}>
                      <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                        <span style={{ color: '#ff4d4f' }}>
                          Thời gian lấy hàng:
                        </span>
                        <span
                          style={{
                            float: 'right',
                            marginRight: '10px',
                            color: '#3f6600',
                          }}
                        >
                          {chiTietDonHang.vanchuyen.estimated_pick_time}
                        </span>
                      </List.Item>

                      <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                        <span style={{ color: '#ff4d4f' }}>
                          Thời gian giao hàng:
                        </span>
                        <span
                          style={{
                            float: 'right',
                            marginRight: '10px',
                            color: '#3f6600',
                          }}
                        >
                          {chiTietDonHang.vanchuyen.estimated_deliver_time}
                        </span>
                      </List.Item>
                    </Col>
                  </div>
                </List>
              </Row>
            ) : (
              ''
            )}
            {chiTietDonHang.vanchuyen.partner === 'ghn' ? (
              <Row>
                <List
                  header={<center>Thông tin vận chuyển</center>}
                  bordered={false}
                  style={{
                    backgroundColor: '#fffbe6',
                    fontWeight: 'bold',
                    width: '100%',
                    paddingBottom: '10px',
                  }}
                >
                  <div className='ant-row-flex'>
                    <Col xs={8}>
                      <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                        <span style={{ color: '#ff4d4f' }}>Phi Ship:</span>
                        <span
                          style={{
                            float: 'right',
                            marginRight: '15px',
                            color: '#3f6600',
                          }}
                        >
                          {chiTietDonHang.vanchuyen.fee
                            ? parseInt(
                                chiTietDonHang.vanchuyen.fee
                              ).toLocaleString('vi-VN')
                            : 0}
                        </span>
                      </List.Item>
                      <List.Item style={{ borderBottom: 0, padding: '5px 0' }}>
                        <span style={{ color: '#ff4d4f' }}> Mã vận đơn:</span>
                        <span
                          style={{
                            float: 'right',
                            marginRight: '15px',
                            color: '#3f6600',
                          }}
                        >
                          {chiTietDonHang.vanchuyen.trackingId}
                        </span>
                      </List.Item>
                    </Col>
                    <Col xs={12}>
                      <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                        <span style={{ color: '#ff4d4f' }}>
                          Thời gian lấy hàng:
                        </span>
                        <span
                          style={{
                            float: 'right',
                            marginRight: '10px',
                            color: '#3f6600',
                          }}
                        >
                          {chiTietDonHang.vanchuyen.estimated_pick_time}
                        </span>
                      </List.Item>

                      <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                        <span style={{ color: '#ff4d4f' }}>
                          Thời gian giao hàng:
                        </span>
                        <span
                          style={{
                            float: 'right',
                            marginRight: '10px',
                            color: '#3f6600',
                          }}
                        >
                          {chiTietDonHang.vanchuyen.estimated_deliver_time
                            ? moment(
                                chiTietDonHang.vanchuyen.estimated_deliver_time
                              ).format('DD/MM/YYYY')
                            : ''}
                        </span>
                      </List.Item>
                    </Col>
                  </div>
                </List>
              </Row>
            ) : (
              ''
            )}

            <Collapse
              accordion
              defaultActiveKey={['1']}
              style={{ border: 'none' }}
            >
              <Panel
                header='Thông Tin Khách Hàng'
                className='order-collapse-panel'
                key='1'
              >
                <Form.Item
                  name='phone'
                  className='new-order-mobile-form'
                  colon={true}
                  onChange={handlePhoneChange}
                >
                  <AutoComplete
                    options={customerPhoneOption}
                    bordered={false}
                    onSelect={handlePhoneSelect}
                    onSearch={handlePhoneSearch}
                    placeholder='Số điện thoại Khách'
                    className='new-order-mobile-input'
                  />
                </Form.Item>
                <Form.Item
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Please input Customer last name',
                    },
                  ]}
                  onChange={handleLastNameChange}
                  className='new-order-mobile-form'
                  autoComplete='off'
                >
                  <Input
                    type='text'
                    id='lastName'
                    size='middle'
                    placeholder='Họ Khách'
                    autoComplete='off'
                    bordered={false}
                    className='new-order-mobile-input'
                  />
                </Form.Item>
                <Form.Item
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Please input Customer first name',
                    },
                  ]}
                  onChange={handleFirstNameChange}
                  className='new-order-mobile-form'
                >
                  <Input
                    type='text'
                    id='firstName'
                    size='middle'
                    placeholder='Tên Khách'
                    bordered={false}
                    className='new-order-mobile-input'
                  />
                </Form.Item>
              </Panel>
              <Panel
                header='Thông Tin Nhận Hàng'
                className='order-collapse-panel'
                key='2'
              >
                <Form.Item
                  name='rPhone'
                  rules={[
                    {
                      required: true,
                      message: 'Nhập số điện thoại nhận hàng',
                    },
                  ]}
                  onChange={handleOtherChange}
                  className='new-order-mobile-form'
                >
                  <Input
                    type='text'
                    id='rPhone'
                    size='middle'
                    placeholder='Số điện thoại nhận hàng'
                    autoComplete='new-password'
                    bordered={false}
                    className='new-order-mobile-input'
                  />
                </Form.Item>

                <Form.Item name='name' className='new-order-mobile-form'>
                  <Input
                    type='text'
                    id='name'
                    size='middle'
                    placeholder='Họ tên Người Nhận Hàng'
                    onChange={handleOtherChange}
                    autoComplete='new-password'
                    bordered={false}
                    className='new-order-mobile-input'
                  />
                </Form.Item>
                <Form.Item
                  name='address'
                  rules={[
                    {
                      required: true,
                      message: 'Please input Customer Address',
                    },
                  ]}
                  className='new-order-mobile-form'
                  onChange={handleOtherChange}
                >
                  <Input
                    type='text'
                    id='address'
                    size='middle'
                    placeholder='Địa chỉ nhận hàng'
                    autoComplete='new-password'
                    bordered={false}
                    className='new-order-mobile-input'
                  />
                </Form.Item>

                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%', marginBottom: '5px' }}
                  placeholder='Chọn Tỉnh/Thành'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .replace(/đ/g, 'd')
                      .replace(/Đ/g, 'D')
                      .indexOf(
                        input
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .replace(/đ/g, 'd')
                          .replace(/Đ/g, 'D')
                      ) >= 0
                  }
                  value={chiTietDonHang.provine.cityCode}
                  onClear={(event) => {
                    dispatchChiTietDonHang({ type: 'clear-city-code' });
                  }}
                  onSelect={handleCityChange}
                >
                  {citiChoice}
                </Select>

                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%', marginBottom: '5px' }}
                  placeholder='Chọn Quận Huyện'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .replace(/đ/g, 'd')
                      .replace(/Đ/g, 'D')
                      .indexOf(
                        input
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .replace(/đ/g, 'd')
                          .replace(/Đ/g, 'D')
                      ) >= 0
                  }
                  value={chiTietDonHang.provine.districtCode}
                  onClear={(event) => {
                    dispatchChiTietDonHang({ type: 'clear-district-code' });
                  }}
                  onSelect={handleDistrictChange}
                >
                  {districtChoice}
                </Select>

                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%', marginBottom: '10px' }}
                  placeholder='Chọn Phường Xã'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .replace(/đ/g, 'd')
                      .replace(/Đ/g, 'D')
                      .indexOf(
                        input
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .replace(/đ/g, 'd')
                          .replace(/Đ/g, 'D')
                      ) >= 0
                  }
                  value={chiTietDonHang.provine.wardCode}
                  onClear={(event) => {
                    dispatchChiTietDonHang({ type: 'clear-ward-code' });
                  }}
                  onSelect={handleWardChange}
                >
                  {wardChoice}
                </Select>
                {chiTietDonHang.vanchuyen.partner === 'ghtk' ? (
                  <Form.Item name='selectHamlet'>
                    <Select
                      showSearch
                      allowClear
                      style={{ width: '100%', marginBottom: '10px' }}
                      placeholder='Chọn khu phố'
                      options={hamletChoice}
                    ></Select>
                  </Form.Item>
                ) : (
                  ''
                )}
              </Panel>
              <Panel
                header='Thông Tin Đơn Hàng'
                className='order-collapse-panel'
                key='3'
              >
                <Form.Item name='date' className='new-order-mobile-form'>
                  <DatePicker
                    style={{ width: '100%' }}
                    format='DD/MM/YYYY'
                    id='date'
                    bordered={false}
                    className='new-order-mobile-input'
                    placeholder='Chọn ngày chốt dơn'
                    onChange={(date, dateString) =>
                      handleOtherChange(date, dateString, 1)
                    }
                    // onChange={handleOtherChange}
                  />
                </Form.Item>
                <Table
                  dataSource={chiTietDonHang.sanpham}
                  columns={detailProductsColumnMobile}
                  className='order-product-detail-table'
                  pagination={false}
                  sticky={true}
                  scroll={{ y: 200 }}
                  rowClassName='product-table-row'
                />
                {chiTietDonHang.donhang.status !== 'dahuy' &&
                  chiTietDonHang.donhang.status !== 'danhanhang' &&
                  chiTietDonHang.donhang.status !== 'dagiaohang' &&
                  chiTietDonHang.donhang.status !== 'dadoisoat' && (
                    <Col xs={24}>
                      <Form.Item
                        name='sanpham'
                        style={{ marginBottom: '5px', marginTop: '5px' }}
                        label={<b>Thêm Sản Phẩm</b>}
                      >
                        <AutoComplete
                          options={options}
                          style={{
                            width: '100%',
                          }}
                          onSelect={handleSelect}
                          onSearch={debounce(handleSearch, 300)}
                          placeholder='Nhập tên sản phẩm'
                        />
                      </Form.Item>
                    </Col>
                  )}
                <Form.Item
                  name='shippingFee'
                  rules={[
                    {
                      required: true,
                      message: 'Nhập phí ship',
                    },
                  ]}
                  style={{
                    marginBottom: '5px',
                    fontWeight: 'bold',
                  }}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  label='Phí Ship'
                  className='new-order-mobile-form'
                  onChange={handleShippingFeeChange}
                >
                  <Input
                    type='text'
                    id='shippingFee'
                    size='middle'
                    placeholder='Phí Ship'
                    style={{ textAlign: 'right' }}
                    bordered={false}
                    className='new-order-mobile-input'
                  />
                </Form.Item>
                <Form.Item
                  name='discount'
                  style={{ marginBottom: '5px', fontWeight: 'bold' }}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  label='Giảm Giá'
                  className='new-order-mobile-form'
                  onChange={handleDiscountChange}
                >
                  <Input
                    type='text'
                    id='discount'
                    size='middle'
                    placeholder='Giảm Giá'
                    style={{ textAlign: 'right' }}
                    bordered={false}
                    className='new-order-mobile-input'
                  />
                </Form.Item>
                <Form.Item
                  name='paid'
                  style={{ marginBottom: '5px', fontWeight: 'bold' }}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  label='Đã thanh toán'
                  className='new-order-mobile-form'
                  onChange={handlePaidChange}
                >
                  <Input
                    type='text'
                    id='paid'
                    size='middle'
                    placeholder='Đã Thanh Toán'
                    style={{ textAlign: 'right' }}
                    bordered={false}
                    className='new-order-mobile-input'
                  />
                </Form.Item>
                <b>Ghi chú nội bộ:</b>
                <Form.Item
                  name='noteInside'
                  style={{ marginBottom: '5px' }}
                  wrapperCol={{ span: 24 }}
                  onBlur={handleOtherChange}
                >
                  <Input.TextArea id='noteInside' />
                </Form.Item>
                <b>Ghi chú trên đơn hàng:</b>
                <Form.Item
                  name='note'
                  style={{ marginBottom: '5px' }}
                  wrapperCol={{ span: 24 }}
                  onBlur={handleOtherChange}
                >
                  <Input.TextArea id='note' />
                </Form.Item>
                <Form.Item
                  name='dagiaohang'
                  id='dagiaohang'
                  style={{ marginTop: '10px' }}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={handleChonDaGiaoHang}
                    style={{ fontWeight: 'bold' }}
                  >
                    Bán tại cửa hàng / đã giao hàng
                  </Checkbox>
                </Form.Item>
              </Panel>
            </Collapse>
          </Form>
        </Modal>
      </>
    );
  }
  return (
    <Modal
      visible={openModal}
      onOk={handleOk}
      onCancel={() => {
        dispatchChiTietDonHang({ type: 'clear-all' });
        handleCancel();
      }}
      footer={modalFotter}
      width={1200}
      style={{ top: 20, padding: '12px' }}
      motion={null}
    >
      <Content>
        <Form
          name='order-form'
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={orderForm}
          autoComplete='off'
          autocomplete='off'
        >
          <input type='hidden' value='something' />
          <Row gutter={8}>
            <Col xs={24} md={24} xl={12} lg={12}>
              <Divider
                orientation='left'
                style={{ margin: '5px', color: '#0050b3' }}
              >
                Thông Tin Khách Hàng
              </Divider>

              <Row gutter={8}>
                <Col xs={24} md={24} lg={8}>
                  <Form.Item
                    name='phone'
                    style={{ marginBottom: '5px' }}
                    onChange={handlePhoneChange}
                    autoComplete='off'
                  >
                    <AutoComplete
                      options={customerPhoneOption}
                      style={{
                        width: '100%',
                      }}
                      onSelect={handlePhoneSelect}
                      onSearch={handlePhoneSearch}
                      placeholder='Số điện thoại Khách'
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={8}>
                  <Form.Item
                    name='lastName'
                    rules={[
                      {
                        required: true,
                        message: 'Please input Customer last name',
                      },
                    ]}
                    style={{ marginBottom: '5px' }}
                    onChange={handleLastNameChange}
                    autoComplete='off'
                  >
                    <Input
                      type='text'
                      id='lastName'
                      size='middle'
                      placeholder='Họ Khách'
                      autoComplete='off'
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={8}>
                  <Form.Item
                    name='firstName'
                    rules={[
                      {
                        required: true,
                        message: 'Please input Customer first name',
                      },
                    ]}
                    style={{ marginBottom: '5px' }}
                    onChange={handleFirstNameChange}
                    autoComplete='off'
                  >
                    <Input
                      type='text'
                      id='firstName'
                      size='middle'
                      placeholder='Tên Khách'
                      autoComplete='off'
                      autoCapitalize='off'
                      autoCorrect='off'
                      spellCheck='false'
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                orientation='left'
                style={{ margin: '5px', color: '#0050b3', marginTop: '15px' }}
              >
                Thông Tin Nhận Hàng
              </Divider>

              <Row gutter={8}>
                <Col xs={24} md={24} lg={24}>
                  <Form.Item
                    name='address'
                    rules={[
                      {
                        required: true,
                        message: 'Please input Customer Address',
                      },
                    ]}
                    style={{ marginBottom: '5px' }}
                    onChange={handleOtherChange}
                  >
                    <Input
                      type='text'
                      id='address'
                      size='middle'
                      placeholder='Địa chỉ nhận hàng'
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Input.Group>
                <Row gutter={8} style={{ marginTop: '5px' }}>
                  <Col xs={24} md={24} lg={7} style={{ marginRight: '17px' }}>
                    <Select
                      showSearch
                      allowClear
                      style={{ width: 180 }}
                      placeholder='Chọn Tỉnh/Thành'
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .replace(/đ/g, 'd')
                          .replace(/Đ/g, 'D')
                          .indexOf(
                            input
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .replace(/đ/g, 'd')
                              .replace(/Đ/g, 'D')
                          ) >= 0
                      }
                      value={chiTietDonHang.provine.cityCode}
                      onClear={(event) => {
                        dispatchChiTietDonHang({ type: 'clear-city-code' });
                      }}
                      onSelect={handleCityChange}
                    >
                      {citiChoice}
                    </Select>
                  </Col>
                  <Col xs={24} md={24} lg={7} style={{ marginRight: '17px' }}>
                    <Select
                      showSearch
                      allowClear
                      style={{ width: 180 }}
                      placeholder='Chọn Quận Huyện'
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .replace(/đ/g, 'd')
                          .replace(/Đ/g, 'D')
                          .indexOf(
                            input
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .replace(/đ/g, 'd')
                              .replace(/Đ/g, 'D')
                          ) >= 0
                      }
                      value={chiTietDonHang.provine.districtCode}
                      onClear={(event) => {
                        dispatchChiTietDonHang({ type: 'clear-district-code' });
                      }}
                      onSelect={handleDistrictChange}
                    >
                      {districtChoice}
                    </Select>
                  </Col>

                  <Col xs={24} md={24} lg={8}>
                    <Select
                      showSearch
                      allowClear
                      style={{ width: 200 }}
                      placeholder='Chọn Phường Xã'
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .replace(/đ/g, 'd')
                          .replace(/Đ/g, 'D')
                          .indexOf(
                            input
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .replace(/đ/g, 'd')
                              .replace(/Đ/g, 'D')
                          ) >= 0
                      }
                      value={chiTietDonHang.provine.wardCode}
                      onClear={(event) => {
                        dispatchChiTietDonHang({ type: 'clear-ward-code' });
                      }}
                      onSelect={handleWardChange}
                    >
                      {wardChoice}
                    </Select>
                  </Col>
                </Row>
                {chiTietDonHang.vanchuyen.partner === 'ghtk' ? (
                  <Row gutter={8} style={{ marginTop: '5px' }}>
                    <Col xs={24} md={24} lg={10}>
                      <Form.Item name='selectHamlet'>
                        <Select
                          showSearch
                          allowClear
                          style={{ width: 300 }}
                          placeholder='Chọn Khu phố'
                          options={hamletChoice}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </Input.Group>

              <Row gutter={8} style={{ marginTop: '10px' }}>
                <Col xs={24} md={24} lg={8}>
                  <Form.Item
                    name='rPhone'
                    rules={[
                      {
                        required: true,
                        message: 'Nhập số điện thoại nhận hàng',
                      },
                    ]}
                    style={{ marginBottom: '5px' }}
                    onChange={handleOtherChange}
                  >
                    <Input
                      type='text'
                      id='rPhone'
                      size='middle'
                      placeholder='Số điện thoại nhận hàng'
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={8}>
                  <Form.Item name='name' style={{ marginBottom: '5px' }}>
                    <Input
                      type='text'
                      id='name'
                      size='middle'
                      placeholder='Họ tên Người Nhận Hàng'
                      onChange={handleOtherChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={8}>
                  <Form.Item name='date' style={{ marginBottom: '5px' }}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format='DD/MM/YYYY'
                      id='date'
                      onChange={(date, dateString) =>
                        handleOtherChange(date, dateString, 1)
                      }
                      // onChange={handleOtherChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }} justify='space-between'>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    name='shippingFee'
                    rules={[
                      {
                        required: true,
                        message: 'Nhập phí ship',
                      },
                    ]}
                    style={{
                      marginBottom: '5px',
                      fontWeight: 'bold',
                    }}
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    label='Phí Ship'
                    onChange={handleShippingFeeChange}
                  >
                    <Input
                      type='text'
                      id='shippingFee'
                      size='middle'
                      placeholder='Phí Ship'
                      style={{ textAlign: 'right' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name='discount'
                    style={{ marginBottom: '5px', fontWeight: 'bold' }}
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    label='Giảm Giá'
                    onChange={handleDiscountChange}
                  >
                    <Input
                      type='text'
                      id='discount'
                      size='middle'
                      placeholder='Giảm Giá'
                      style={{ textAlign: 'right' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name='paid'
                    style={{ marginBottom: '5px', fontWeight: 'bold' }}
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    label='Đã thanh toán'
                    onChange={handlePaidChange}
                  >
                    <Input
                      type='text'
                      id='paid'
                      size='middle'
                      placeholder='Đã Thanh Toán'
                      style={{ textAlign: 'right' }}
                    />
                  </Form.Item>
                  <List
                    header={<center>Tóm Tắt Đơn Hàng</center>}
                    bordered={false}
                    style={{ backgroundColor: '#e6f7ff', fontWeight: 'bold' }}
                  >
                    <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                      Tổng tiền hàng:
                      <span style={{ float: 'right', marginRight: '10px' }}>
                        {chiTietDonHang.donhang.value
                          ? chiTietDonHang.donhang.value.toLocaleString(
                              'vi-VN'
                            ) + ' đ'
                          : '0 đ'}
                      </span>
                    </List.Item>
                    <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                      Phí Ship:
                      <span style={{ float: 'right', marginRight: '10px' }}>
                        {chiTietDonHang.donhang.shippingFee
                          ? chiTietDonHang.donhang.shippingFee.toLocaleString(
                              'vi-VN'
                            ) + ' đ'
                          : '0 đ'}
                      </span>
                    </List.Item>
                    <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                      Giảm Giá:
                      <span style={{ float: 'right', marginRight: '10px' }}>
                        {chiTietDonHang.donhang.discount
                          ? chiTietDonHang.donhang.discount.toLocaleString(
                              'vi-VN'
                            ) + ' đ'
                          : '0 đ'}
                      </span>
                    </List.Item>
                    <List.Item style={{ borderBottom: 0, padding: '3px 0' }}>
                      Đã thanh toán:{' '}
                      <span style={{ float: 'right', marginRight: '10px' }}>
                        {chiTietDonHang.donhang.paid
                          ? chiTietDonHang.donhang.paid.toLocaleString(
                              'vi-VN'
                            ) + ' đ'
                          : '0 đ'}
                      </span>
                    </List.Item>
                    <List.Item
                      style={{
                        borderBottom: 0,
                        padding: '3px 0',
                        color: '#f5222d',
                      }}
                    >
                      Còn Lại:
                      <span style={{ float: 'right', marginRight: '10px' }}>
                        {chiTietDonHang.donhang.conlai
                          ? chiTietDonHang.donhang.conlai.toLocaleString(
                              'vi-VN'
                            ) + ' đ'
                          : '0 đ'}
                      </span>
                    </List.Item>
                  </List>
                </Col>
                <Col xs={24} md={24} lg={11}>
                  Ghi chú nội bộ:
                  <Form.Item
                    name='noteInside'
                    style={{ marginBottom: '5px' }}
                    wrapperCol={{ span: 24 }}
                    onBlur={handleOtherChange}
                  >
                    <Input.TextArea id='noteInside' />
                  </Form.Item>
                  Ghi chú trên đơn hàng:
                  <Form.Item
                    name='note'
                    style={{ marginBottom: '5px' }}
                    wrapperCol={{ span: 24 }}
                    onBlur={handleOtherChange}
                  >
                    <Input.TextArea id='note' />
                  </Form.Item>
                  <Form.Item
                    name='dagiaohang'
                    id='dagiaohang'
                    style={{ marginTop: '10px' }}
                    valuePropName='checked'
                  >
                    <Checkbox
                      onChange={handleChonDaGiaoHang}
                      style={{ fontWeight: 'bold' }}
                    >
                      Bán tại cửa hàng / đã giao hàng
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={24} xl={12} lg={12}>
              {chiTietDonHang.donhang['_id'] && (
                <Row justify='center'>
                  <Col span={12}>
                    Trang Thái Đơn Hàng
                    <Button danger style={{ marginLeft: '5px' }}>
                      {trangThaiDonHang}
                    </Button>
                  </Col>

                  <Col span={8}>
                    <Dropdown
                      overlay={updateOrderMenu}
                      overlayClassName='chon-trang-thai-don-hang'
                    >
                      <Button type='primary'>
                        Cập nhật trạng thái
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
              )}
              <Row style={{ overflow: 'hidden' }}>
                <Table
                  dataSource={chiTietDonHang.sanpham}
                  columns={detailProductsColumn}
                  className='order-product-detail-table'
                  pagination={false}
                  sticky={true}
                  scroll={{ y: 200 }}
                  rowClassName='product-table-row'
                />
              </Row>
              <Row>
                <Col xs={24}>
                  <Row>
                    {chiTietDonHang.donhang.status !== 'dahuy' &&
                      chiTietDonHang.donhang.status !== 'danhanhang' &&
                      chiTietDonHang.donhang.status !== 'dagiaohang' &&
                      chiTietDonHang.donhang.status !== 'dadoisoat' && (
                        <Col xs={chiTietDonHang.donhang['_id'] ? 12 : 24}>
                          <Form.Item
                            name='sanpham'
                            style={{ marginBottom: '5px', marginTop: '5px' }}
                            label='Thêm Sản Phẩm'
                            labelCol={
                              chiTietDonHang.donhang['_id']
                                ? { span: 10 }
                                : { span: 5 }
                            }
                            wrapperCol={
                              chiTietDonHang.donhang['_id']
                                ? { span: 14 }
                                : { span: 19 }
                            }
                          >
                            <AutoComplete
                              options={options}
                              style={{
                                width: '100%',
                              }}
                              onSelect={handleSelect}
                              onSearch={debounce(handleSearch, 300)}
                              placeholder='Nhập tên sản phẩm'
                              dropdownAlign={{
                                points: ['tl', 'bl'],
                                offset: [-120, 0],
                              }}
                              dropdownStyle={{
                                width: 580,
                                maxWidth: 580,
                                minWidth: 580,
                              }}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    {chiTietDonHang.donhang['_id'] &&
                      chiTietDonHang.donhang.status !== 'dahuy' &&
                      chiTietDonHang.donhang.status !== 'danhanhang' &&
                      chiTietDonHang.donhang.status !== 'dagiaohang' &&
                      chiTietDonHang.donhang.status !== 'dadoisoat' && (
                        <Col xs={12}>
                          <Form.Item
                            name='chonvanchuyen'
                            style={{ marginBottom: '5px', marginTop: '5px' }}
                            label='Chọn vận chuyển'
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 13 }}
                          >
                            <Select onChange={hadlerChonVanChuyen}>
                              <Option value='tugiao'>Tự Giao</Option>
                              <Option value='grab'>Grab</Option>
                              {user.ghtkApiKey && (
                                <Option value='ghtk'>
                                  Giao Hàng Tiết Kiệm
                                </Option>
                              )}
                              {user.ghnApiKey && (
                                <Option value='ghn'>Giao Hàng Nhanh</Option>
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                  </Row>
                </Col>
              </Row>
              {cauHinhVanChuyen}
              {chiTietDonHang.vanchuyen.partner === 'ghtk' ? (
                <Row>
                  <List
                    header={<center>Thông tin vận chuyển</center>}
                    bordered={false}
                    style={{
                      backgroundColor: '#fffbe6',
                      fontWeight: 'bold',
                      width: '100%',
                      paddingBottom: '10px',
                    }}
                  >
                    <div className='ant-row-flex'>
                      <Col xs={8}>
                        <List.Item
                          style={{ borderBottom: 0, padding: '3px 0' }}
                        >
                          <span style={{ color: '#ff4d4f' }}>Phi Ship:</span>
                          <span
                            style={{
                              float: 'right',
                              marginRight: '15px',
                              color: '#3f6600',
                            }}
                          >
                            {chiTietDonHang.vanchuyen.fee
                              ? parseInt(
                                  chiTietDonHang.vanchuyen.fee
                                ).toLocaleString('vi-VN')
                              : 0}
                          </span>
                        </List.Item>
                        <List.Item
                          style={{ borderBottom: 0, padding: '5px 0' }}
                        >
                          <span style={{ color: '#ff4d4f' }}> Mã vận đơn:</span>
                          <span
                            style={{
                              float: 'right',
                              marginRight: '15px',
                              color: '#3f6600',
                            }}
                          >
                            {chiTietDonHang.vanchuyen.trackingId}
                          </span>
                        </List.Item>
                      </Col>
                      <Col xs={12}>
                        <List.Item
                          style={{ borderBottom: 0, padding: '3px 0' }}
                        >
                          <span style={{ color: '#ff4d4f' }}>
                            Thời gian lấy hàng:
                          </span>
                          <span
                            style={{
                              float: 'right',
                              marginRight: '10px',
                              color: '#3f6600',
                            }}
                          >
                            {chiTietDonHang.vanchuyen.estimated_pick_time}
                          </span>
                        </List.Item>

                        <List.Item
                          style={{ borderBottom: 0, padding: '3px 0' }}
                        >
                          <span style={{ color: '#ff4d4f' }}>
                            Thời gian giao hàng:
                          </span>
                          <span
                            style={{
                              float: 'right',
                              marginRight: '10px',
                              color: '#3f6600',
                            }}
                          >
                            {chiTietDonHang.vanchuyen.estimated_deliver_time}
                          </span>
                        </List.Item>
                      </Col>
                    </div>
                  </List>
                </Row>
              ) : (
                ''
              )}
              {chiTietDonHang.vanchuyen.partner === 'ghn' ? (
                <Row>
                  <List
                    header={<center>Thông tin vận chuyển</center>}
                    bordered={false}
                    style={{
                      backgroundColor: '#fffbe6',
                      fontWeight: 'bold',
                      width: '100%',
                      paddingBottom: '10px',
                    }}
                  >
                    <div className='ant-row-flex'>
                      <Col xs={8}>
                        <List.Item
                          style={{ borderBottom: 0, padding: '3px 0' }}
                        >
                          <span style={{ color: '#ff4d4f' }}>Phi Ship:</span>
                          <span
                            style={{
                              float: 'right',
                              marginRight: '15px',
                              color: '#3f6600',
                            }}
                          >
                            {chiTietDonHang.vanchuyen.fee
                              ? parseInt(
                                  chiTietDonHang.vanchuyen.fee
                                ).toLocaleString('vi-VN')
                              : 0}
                          </span>
                        </List.Item>
                        <List.Item
                          style={{ borderBottom: 0, padding: '5px 0' }}
                        >
                          <span style={{ color: '#ff4d4f' }}> Mã vận đơn:</span>
                          <span
                            style={{
                              float: 'right',
                              marginRight: '15px',
                              color: '#3f6600',
                            }}
                          >
                            {chiTietDonHang.vanchuyen.trackingId}
                          </span>
                        </List.Item>
                      </Col>
                      <Col xs={12}>
                        <List.Item
                          style={{ borderBottom: 0, padding: '3px 0' }}
                        >
                          <span style={{ color: '#ff4d4f' }}>
                            Thời gian lấy hàng:
                          </span>
                          <span
                            style={{
                              float: 'right',
                              marginRight: '10px',
                              color: '#3f6600',
                            }}
                          >
                            {chiTietDonHang.vanchuyen.estimated_pick_time}
                          </span>
                        </List.Item>

                        <List.Item
                          style={{ borderBottom: 0, padding: '3px 0' }}
                        >
                          <span style={{ color: '#ff4d4f' }}>
                            Thời gian giao hàng:
                          </span>
                          <span
                            style={{
                              float: 'right',
                              marginRight: '10px',
                              color: '#3f6600',
                            }}
                          >
                            {chiTietDonHang.vanchuyen.estimated_deliver_time
                              ? moment(
                                  chiTietDonHang.vanchuyen
                                    .estimated_deliver_time
                                ).format('DD/MM/YYYY')
                              : ''}
                          </span>
                        </List.Item>
                      </Col>
                    </div>
                  </List>
                </Row>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

OrderModal.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  provine: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.AuthRedux.user,
  data: state.Orders,
  provine: state.Provine,
});

export default connect(mapStateToProps, {
  saveDistrict,
  saveWard,
  changeDistrict,
  changeWard,
  initProvine,
})(OrderModal);
