import axios from 'axios';
import {
  COUNTORDER_SUCCESS,
  COUNTORDER_FAIL,
  LIST_ALLORDER_SUCCESS,
  LOADING_LIST,
  CURRENT_ORDER,
  CLEAR_ALL_ORDERS,
  LIST_SEARCH_ORDERS,
  ADD_ORDER,
  ADD_ORDER1,
  NO_LOADING_LIST,
  UPDATE_COUNTER,
  UPDATE_COUNTER1,
  DELETE_ORDER,
  CONFIRM_ORDER,
  UPDATE_ORDER,
} from './Types';
import { setAlert } from './Alert';

const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month, year].join('/');
};

//set loading state
export const loadingList = () => (dispatch) => {
  dispatch({
    type: LOADING_LIST,
  });
};
//disable loading state
export const noLoadingList = () => (dispatch) => {
  dispatch({
    type: NO_LOADING_LIST,
  });
};
//load orders count
export const countOrder = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.get('/api/orders/count', config);
    if (res.data.success) {
      dispatch({
        type: COUNTORDER_SUCCESS,
        payload: { payload: res.data },
      });
    } else {
      dispatch(setAlert(res.msg, 'error'));
    }
  } catch (err) {
    const error = err.res.data.error;
    console.log(error);
    if (error) {
      for (let err1 of error) {
        dispatch(setAlert(err1.msg, 'error'));
      }
    }
    dispatch({
      type: COUNTORDER_FAIL,
    });
  }
};

//List all orders
export const listAllOrder = (type, page) => async (dispatch) => {
  const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    data = {},
    allOrdersTable = [];

  try {
    const res = await axios.get(
      `/api/orders/list/${type ? type : 'all'}${page ? '/' + page : ''}`,
      config
    );
    if (res.data.success) {
      for (let val of res.data.data) {
        data[val._id] = val;
        let sanpham = '';
        for (let val1 in val.product) {
          //   console.log(val.product[val1]);
          sanpham += ` ${sanpham.length > 1 ? '| ' : ''}${
            val.product[val1].productName
          } x ${val.product[val1].quantity}`;
        }
        allOrdersTable.push({
          key: val._id,
          tenKhach: val.name,
          dienThoai: val.rPhone,
          diaChi: val.address,
          sanPham: sanpham,
          tongTien: val.value.toLocaleString('vi-VN'),
          ngayMua: formatDate(val.date),
          vanChuyen: {
            donViVanChuyen: val['shippingDetail']
              ? val['shippingDetail']['shippingPartner']
              : '',
            vanDon: val['shippingDetail']
              ? val['shippingDetail']['shippingCode']
              : '',
            status: val.status,
          },

          ghiChu: `${val.note ? val.note : ''} ${
            val.note && val.noteInside ? ' | ' : ''
          } ${val.noteInside ? val.noteInside : ''}`,
        });
      }

      dispatch({
        type: LIST_ALLORDER_SUCCESS,
        payload: {
          payload: data,
          payload1: res.data.count,
          payload2: allOrdersTable,
          tab: type ? type : 'all',
        },
      });
    } else {
      dispatch(setAlert(res.msg, 'error'));
    }
  } catch (err) {
    console.log(err);
    // const error = err.res.data.error;
    // console.log(error);
    // if (error) {
    //   for (let err1 of error) {
    //     dispatch(setAlert(err1.msg, 'error'));
    //   }
    // }
  }
};

//load current order
export const loadCurrentOrder = (order) => async (dispatch) => {
  try {
    dispatch({
      type: CURRENT_ORDER,
      payload: {
        payload: order,
      },
    });
  } catch (err) {
    dispatch(setAlert('cannot load order', 'error'));
    console.log(err);
    // const error = err.res.data.error;
    // console.log(error);
    // if (error) {
    //   for (let err1 of error) {
    //     dispatch(setAlert(err1.msg, 'error'));
    //   }
    // }
  }
};

//clear all order
export const clearAllOrders = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_ORDERS });
};

//List search orders
export const listAllSearchOrder =
  (searchTerm, page = 1) =>
  async (dispatch) => {
    const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      data = {},
      allOrdersTable = [];

    try {
      const body = JSON.stringify({ searchTerm: searchTerm, page: page });
      const res = await axios.post(`/api/orders/search/`, body, config);
      if (res.data.success) {
        for (let val of res.data.data) {
          data[val._id] = val;
          let sanpham = '';
          for (let val1 in val.product) {
            //   console.log(val.product[val1]);
            sanpham += ` ${sanpham.length > 1 ? '| ' : ''}${
              val.product[val1].productName
            } x ${val.product[val1].quantity}`;
          }
          allOrdersTable.push({
            key: val._id,
            tenKhach: val.name,
            dienThoai: val.rPhone,
            diaChi: val.address,
            sanPham: sanpham,
            tongTien: val.value.toLocaleString('vi-VN'),
            ngayMua: formatDate(val.date),
            vanChuyen: {
              donViVanChuyen: val['shippingDetail']
                ? val['shippingDetail']['shippingPartner']
                : '',
              status: val.status,
            },

            ghiChu: `${val.note ? val.note : ''} ${
              val.note && val.noteInside ? ' | ' : ''
            } ${val.noteInside ? val.noteInside : ''}`,
          });
        }

        dispatch({
          type: LIST_SEARCH_ORDERS,
          payload: {
            payload: data,
            payload1: res.data.count,
            payload2: allOrdersTable,
          },
        });
      } else {
        dispatch(setAlert(res.msg, 'error'));
      }
    } catch (err) {
      console.log(err);
      // const error = err.res.data.error;
      // console.log(error);
      // if (error) {
      //   for (let err1 of error) {
      //     dispatch(setAlert(err1.msg, 'error'));
      //   }
      // }
    }
  };

//Add new order
export const addOrder =
  (data, orderType = 'new') =>
  async (dispatch) => {
    try {
      const newOrder = {};
      let sanpham = '';
      for (let val1 in data.product) {
        //   console.log(val.product[val1]);
        sanpham += ` ${sanpham.length > 1 ? '| ' : ''}${
          data.product[val1].productName
        } x ${data.product[val1].quantity}`;
      }
      const orderTable = {
        key: data._id,
        tenKhach: data.name,
        dienThoai: data.rPhone,
        diaChi: data.address,
        sanPham: sanpham,
        tongTien: data.value.toLocaleString('vi-VN'),
        ngayMua: formatDate(data.date),
        vanChuyen: {
          donViVanChuyen: data['shippingDetail']
            ? data['shippingDetail']['shippingPartner']
            : '',
          status: data.status,
        },
      };

      newOrder[data._id] = data;
      dispatch({
        type: ADD_ORDER,
        payload: {
          payload: newOrder,
          payload1: orderTable,
          orderType: orderType,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert(err, 'error'));
    }
  };

export const deleteOrder = (orderId, oldStatus, newStatus) => (dispatch) => {
  try {
    dispatch({
      type: DELETE_ORDER,
      payload: orderId,
    });
    dispatch({
      type: UPDATE_COUNTER1,
      payload: { oldStatus: oldStatus, newStatus: newStatus },
    });
  } catch (err) {
    console.log(err);
  }
};

export const confirmOrder =
  (data, oldStatus, newStatus) => async (dispatch) => {
    try {
      const newOrder = {};
      let sanpham = '';
      for (let val1 in data.product) {
        //   console.log(val.product[val1]);
        sanpham += ` ${sanpham.length > 1 ? '| ' : ''}${
          data.product[val1].productName
        } x ${data.product[val1].quantity}`;
      }
      const orderTable = {
        key: data._id,
        tenKhach: data.name,
        dienThoai: data.rPhone,
        diaChi: data.address,
        sanPham: sanpham,
        tongTien: data.value.toLocaleString('vi-VN'),
        ngayMua: formatDate(data.date),
        vanChuyen: {
          donViVanChuyen: data['shippingDetail']
            ? data['shippingDetail']['shippingPartner']
            : '',
          status: data.status,
        },
      };

      newOrder[data._id] = data;
      dispatch({
        type: ADD_ORDER,
        payload: {
          payload: newOrder,
          payload1: orderTable,
          orderType: 'update',
        },
      });
      dispatch({ type: CONFIRM_ORDER, payload: newOrder });
      dispatch({
        type: UPDATE_COUNTER1,
        payload: { oldStatus: oldStatus, newStatus: newStatus },
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert(err, 'error'));
    }
  };
export const confirmOrder1 =
  (data, oldStatus, newStatus) => async (dispatch) => {
    try {
      const newOrder = {};
      let sanpham = '';
      for (let val1 in data.product) {
        //   console.log(val.product[val1]);
        sanpham += ` ${sanpham.length > 1 ? '| ' : ''}${
          data.product[val1].productName
        } x ${data.product[val1].quantity}`;
      }
      const orderTable = {
        key: data._id,
        tenKhach: data.name,
        dienThoai: data.rPhone,
        diaChi: data.address,
        sanPham: sanpham,
        tongTien: data.value.toLocaleString('vi-VN'),
        ngayMua: formatDate(data.date),
        vanChuyen: {
          donViVanChuyen: data['shippingDetail']
            ? data['shippingDetail']['shippingPartner']
            : '',
          status: data.status,
        },
      };

      newOrder[data._id] = data;
      dispatch({
        type: ADD_ORDER1,
        payload: {
          payload: newOrder,
          payload1: orderTable,
        },
      });
      dispatch({ type: CONFIRM_ORDER, payload: newOrder });
      dispatch({
        type: UPDATE_COUNTER1,
        payload: { oldStatus: oldStatus, newStatus: newStatus },
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert(err, 'error'));
    }
  };
export const updateOrder = (data, oldStatus, newStatus) => async (dispatch) => {
  try {
    const newOrder = {};
    let sanpham = '';
    for (let val1 in data.product) {
      //   console.log(val.product[val1]);
      sanpham += ` ${sanpham.length > 1 ? '| ' : ''}${
        data.product[val1].productName
      } x ${data.product[val1].quantity}`;
    }
    const orderTable = {
      key: data._id,
      tenKhach: data.name,
      dienThoai: data.rPhone,
      diaChi: data.address,
      sanPham: sanpham,
      tongTien: data.value.toLocaleString('vi-VN'),
      ngayMua: formatDate(data.date),
      vanChuyen: {
        donViVanChuyen: data['shippingDetail']
          ? data['shippingDetail']['shippingPartner']
          : '',
        status: data.status,
      },
    };

    newOrder[data._id] = data;
    dispatch({
      type: UPDATE_ORDER,
      payload: { payload: newOrder, payload1: orderTable },
    });
    dispatch({
      type: UPDATE_COUNTER1,
      payload: { oldStatus: oldStatus, newStatus: newStatus },
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert(err, 'error'));
  }
};

export const updateCounterNewOrder = (ordertype) => (dispatch) => {
  dispatch({ type: UPDATE_COUNTER, payload: ordertype });
};

export const updateCounter = (oldStatus, newStatus) => (dispatch) => {
  dispatch({
    type: UPDATE_COUNTER1,
    payload: { oldStatus: oldStatus, newStatus: newStatus },
  });
};
