import {
  LOAD_PROVINE,
  SAVE_DISTRICT,
  SAVE_WARD,
  CHANGE_DISTRICT,
  CHANGE_WARD,
  CLEAR_ALL_PROVINE,
} from '../action/Types';
const cityJson = require('../json/cities.json');
let city = [];
for (let val in cityJson) {
  city.push({ cityCode: val, cityName: cityJson[val] });
}
const initialState = {
  provineLoading: true,
  city: city,
  district: [],
  ward: [],
};

const Provine = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_WARD:
      return {
        ...state,
        choicedWardCode: payload.choicedWardCode,
        choicedward: payload.choicedward,
      };
    case CHANGE_DISTRICT:
      return {
        ...state,
        choicedDistrictCode: payload.choicedDistrictCode,
        choiceddistrict: payload.choicedDistrictName,
        choicedWardCode: '',
        choicedward: '',
        ward: payload.ward,
      };
    case LOAD_PROVINE:
      return {
        ...state,
        choicedWardCode: payload.choicedWardCode,
        choicedDistrictCode: payload.choicedDistrictCode,
        choicedCityCode: payload.choicedCityCode,
        choicedcity: payload.choicedCity,
        choiceddistrict: payload.choicedDistrict,
        choicedward: payload.choicedWard,
      };
    case SAVE_DISTRICT:
      return { ...state, district: payload };
    case SAVE_WARD:
      return { ...state, ward: payload };
    case CLEAR_ALL_PROVINE:
      return { provineLoading: true, city: city, district: [], ward: [] };
    default:
      return state;
  }
};

export default Provine;
