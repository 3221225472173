import React, { useState } from 'react';
import { Layout, Row, Col, Button, Image, Popconfirm, message } from 'antd';
import Store from '../Store';
import { deleteProduct } from '../action/Products';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
const ExpandProduct = (props) => {
  const { product, updateProduct } = props,
    { Content } = Layout,
    [visible, setVisible] = useState(false),
    confirm = async (e) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        const res = await axios.delete(`/api/products/${e}`, config);
        if (res.data.success === true) {
          Store.dispatch(deleteProduct(e));
          message.success('Đã xoá sản phẩm thành công');
        }
      } catch (err) {
        console.log(err);
        message.error('Có lỗi trong quá trình xoá sản phẩm');
      }
    },
    cancel = (e) => {
      message.success('Đã huỷ bỏ việc xoá sản phẩm');
    };
  if (isMobile) {
    return (
      <>
        <Row align='center'>
          {product.imageLink && product.imageLink.length === 0 && (
            <Image
              width={100}
              height={100}
              src='/uploads/noImage.png'
              alt='default'
            />
          )}
          {product.imageLink && product.imageLink.length > 0 && (
            <Image width={100} src={product.imageLink[0]} alt='default' />
          )}
        </Row>
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Mã Sản Phẩm:
          </Col>
          <Col xs={15}>{product.productId}</Col>
        </Row>
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Vị trí sản phẩm:
          </Col>
          <Col xs={15}>{product.location}</Col>
        </Row>
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Link sản phẩm:
          </Col>
          <Col xs={15}>
            {product.link && (
              <a href={product.link} target='_blank' rel='noreferrer'>
                LINK
              </a>
            )}
          </Col>
        </Row>
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Kích Thước:
          </Col>
          <Col xs={15}>
            (DxRxC) {product.length}CM x {product.width}CM x {product.height}
            CM
          </Col>
        </Row>
        {product.idLienKet && product.idLienKet.woocommerce && (
          <Row
            align='center'
            style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
          >
            <Col
              xs={9}
              style={{
                textAlign: 'right',
                paddingRight: '5px',
                alignSelf: 'center',
              }}
            >
              ID Website:
            </Col>
            <Col xs={15}>{product.idLienKet.woocommerce}</Col>
          </Row>
        )}
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Giá nhập:
          </Col>
          <Col xs={15}>{product.inPrice.toLocaleString('vi-VN')}đ</Col>
        </Row>
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Giá bán:
          </Col>
          <Col xs={15}>{product.outPrice.toLocaleString('vi-VN')}đ</Col>
        </Row>
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Tồn kho:
          </Col>
          <Col xs={15}>{product.quantity.toLocaleString('vi-VN')}</Col>
        </Row>
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Đã bán:
          </Col>
          <Col xs={15}>
            {product.sold ? product.sold.toLocaleString('vi-VN') : 0}
          </Col>
        </Row>
        <Row
          align='center'
          style={{ borderBottom: '1px solid #d9d9d9', padding: '5px' }}
        >
          <Col
            xs={9}
            style={{
              textAlign: 'right',
              paddingRight: '5px',
              alignSelf: 'center',
            }}
          >
            Khối lượng:
          </Col>
          <Col xs={15}>{product.weight.toLocaleString('vi-VN')}</Col>
        </Row>
        <Row style={{ justifyContent: 'center', margin: '30px 0px 10px 0px' }}>
          <Button
            style={{ marginRight: '20px' }}
            shape='round'
            className='product-update-button'
            size='small'
            onClick={() => updateProduct(product, 'update')}
          >
            Cập Nhật
          </Button>
          <Button
            style={{ marginRight: '20px' }}
            shape='round'
            className='product-copy-button'
            size='small'
            onClick={() => updateProduct(product, 'copy')}
          >
            Nhân Bản
          </Button>
          <Popconfirm
            title={`Xác nhận xoá sản phẩm: ${product.productName} ?`}
            onConfirm={() => confirm(product._id)}
            onCancel={cancel}
            okText='Xoá'
            cancelText='Không'
          >
            <Button
              shape='round'
              className='product-delete-button'
              size='small'
            >
              Xoá
            </Button>
          </Popconfirm>
        </Row>
      </>
    );
  }
  return (
    <Content
      style={{
        fontSize: '110%',
        padding: '0px 10px',
        backgroundColor: 'white',
      }}
    >
      <Row glutter={8}>
        <Col xs={7} lg={6} xl={12} style={{ textAlign: 'center' }}>
          <Row>
            <Col span={24}>
              {product.imageLink && product.imageLink.length > 0 && (
                <Image
                  preview={{ visible: false }}
                  src={product.imageLink[0]}
                  style={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '250px',
                    maxHeight: '250px',
                  }}
                  onClick={() => setVisible(true)}
                />
              )}
              {product.imageLink && product.imageLink.length === 0 && (
                <img
                  style={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '250px',
                    maxHeight: '250px',
                  }}
                  src='/uploads/noImage.png'
                  alt='default'
                />
              )}
              <div style={{ display: 'none' }}>
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                  }}
                >
                  {product.imageLink &&
                    product.imageLink.map((image, index) => (
                      <span key={index}>
                        <Image src={image} id={index} key={index} />
                      </span>
                    ))}
                </Image.PreviewGroup>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={12} xl={8}>
          <Row
            glutter={8}
            style={{
              padding: '15px 0px 5px 0px',
              borderBottom: '1px solid #d9d9d9',
              marginRight: '15px',
            }}
          >
            <Col
              xs={10}
              lg={10}
              xl={9}
              xxl={9}
              style={{ textAlign: 'right', paddingRight: '10px' }}
            >
              Mã Sản Phẩm:
            </Col>
            <Col xs={14}>{product.productId}</Col>
          </Row>
          <Row
            glutter={8}
            style={{
              padding: '15px 0px 5px 0px',
              borderBottom: '1px solid #d9d9d9',
              marginRight: '15px',
            }}
          >
            <Col
              xs={10}
              lg={10}
              xl={9}
              xxl={9}
              style={{ textAlign: 'right', paddingRight: '10px' }}
            >
              Vị Trí Sản Phẩm:
            </Col>
            <Col xs={14}>{product.location}</Col>
          </Row>
          <Row
            glutter={8}
            style={{
              padding: '15px 0px 5px 0px',
              borderBottom: '1px solid #d9d9d9',
              marginRight: '15px',
            }}
          >
            <Col
              xs={10}
              lg={10}
              xl={9}
              xxl={9}
              style={{ textAlign: 'right', paddingRight: '10px' }}
            >
              Link Sản Phẩm:
            </Col>
            <Col xs={14}>
              {product.link && (
                <a href={product.link} target='_blank' rel='noreferrer'>
                  LINK
                </a>
              )}
            </Col>
          </Row>
          <Row
            glutter={8}
            style={{
              padding: '15px 0px 5px 0px',
              borderBottom: '1px solid #d9d9d9',
              marginRight: '15px',
            }}
          >
            <Col
              xs={10}
              lg={10}
              xl={9}
              xxl={9}
              style={{ textAlign: 'right', paddingRight: '10px' }}
            >
              Kích Thước SP:
            </Col>
            <Col xs={14}>
              (DxRxC) {product.length}CM x {product.width}CM x {product.height}
              CM
            </Col>
          </Row>
          {product.idLienKet && product.idLienKet.woocommerce && (
            <Row
              glutter={8}
              style={{
                padding: '15px 0px 5px 0px',
                borderBottom: '1px solid #d9d9d9',
                marginRight: '15px',
              }}
            >
              <Col
                xs={10}
                lg={10}
                xl={9}
                xxl={9}
                style={{ textAlign: 'right', paddingRight: '10px' }}
              >
                ID Woocommerce:
              </Col>
              <Col xs={14}>{product.idLienKet.woocommerce}</Col>
            </Row>
          )}
        </Col>
        <Col xs={5} lg={6} xl={4}>
          <Row
            glutter={8}
            style={{
              textAlign: 'right',
              padding: '15px 0px 5px 0px',
              marginLeft: '15px',
              borderBottom: '1px solid #d9d9d9',
            }}
          >
            <Col xs={12}>Giá Nhập:</Col>
            <Col xs={12}>{product.inPrice.toLocaleString('vi-VN')}</Col>
          </Row>
          <Row
            glutter={8}
            style={{
              textAlign: 'right',
              padding: '15px 0px 5px 0px',
              borderBottom: '1px solid #d9d9d9',
            }}
          >
            <Col xs={12}>Giá Bán:</Col>
            <Col xs={12}>{product.outPrice.toLocaleString('vi-VN')}</Col>
          </Row>
          <Row
            glutter={8}
            style={{
              textAlign: 'right',
              padding: '15px 0px 5px 0px',
              borderBottom: '1px solid #d9d9d9',
            }}
          >
            <Col xs={12}>Tồn Kho:</Col>
            <Col xs={12}>{product.quantity.toLocaleString('vi-VN')}</Col>
          </Row>
          <Row
            glutter={8}
            style={{
              textAlign: 'right',
              padding: '15px 0px 5px 0px',
              borderBottom: '1px solid #d9d9d9',
            }}
          >
            <Col xs={12}>Đã Bán:</Col>
            <Col xs={12}>
              {product.sold ? product.sold.toLocaleString('vi-VN') : 0}
            </Col>
          </Row>
          <Row
            glutter={8}
            style={{
              textAlign: 'right',
              padding: '10px 5px',
              borderBottom: '1px solid #d9d9d9',
            }}
          >
            <Col xs={12}>Khối Lượng:</Col>
            <Col xs={12}>{product.weight.toLocaleString('vi-VN')}g</Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ justifyContent: 'center', margin: '30px 0px 10px 0px' }}>
        <Button
          style={{ marginRight: '20px' }}
          shape='round'
          className='product-update-button'
          size='large'
          onClick={() => updateProduct(product, 'update')}
        >
          Cập Nhật
        </Button>
        <Button
          style={{ marginRight: '20px' }}
          shape='round'
          className='product-copy-button'
          size='large'
          onClick={() => updateProduct(product, 'copy')}
        >
          Nhân Bản
        </Button>
        <Popconfirm
          title={`Xác nhận xoá sản phẩm: ${product.productName} ?`}
          onConfirm={() => confirm(product._id)}
          onCancel={cancel}
          okText='Xoá'
          cancelText='Không'
        >
          <Button shape='round' className='product-delete-button' size='large'>
            Xoá
          </Button>
        </Popconfirm>
      </Row>
    </Content>
  );
};

export default ExpandProduct;
