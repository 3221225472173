import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SAVEUSER,
} from '../action/Types';
const user = localStorage.user ? JSON.parse(localStorage.user) : null,
  isAuthenticated = localStorage.token ? true : false;

const initialState = {
  token: localStorage.token,
  isAuthenticated: isAuthenticated,
  loading: false,
  user: user,
};

const AuthRedux = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.payload1.token,
        isAuthenticated: true,
        loading: false,
        user: payload.payload2,
      };
    case AUTH_ERROR:
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return { ...state, token: null, isAuthenticated: null, loading: false };
    case USER_LOADED:
      return { ...state, isAuthenticated: true, loading: false, user: payload };
    case SAVEUSER:
      return { ...state, user: payload.payload1 };
    default:
      return state;
  }
};

export default AuthRedux;
