import React from 'react';

import axios from 'axios';
import { connect } from 'react-redux';
import { setAlert } from '../../action/Alert';
import { register } from '../../action/Auth';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Row, Button, Col, Typography, Form, Input } from 'antd';
import { blue } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';

const Register = ({ setAlert, register, isAuthenticated }) => {
  const { Title, Text } = Typography;

  const emailValidator = async (rule, value) => {
    if (value && value.trim().includes('@')) {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const body = JSON.stringify({ email: value });
        const checkEmailResult = await axios.post(
          '/api/users/checkemailexists',
          body,
          config
        );
        if (!checkEmailResult.data.success) {
          return Promise.reject('Email already exists');
        } else {
          return Promise.resolve();
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
  };
  const phoneValidator = async (rule, value) => {
    if (value && value.trim().length > 9) {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const body = JSON.stringify({ phone: value });
        const checkPhoneResult = await axios.post(
          '/api/users/checkphoneexists',
          body,
          config
        );
        if (!checkPhoneResult.data.success) {
          return Promise.reject('Phone number already exists');
        } else {
          return Promise.resolve();
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
  };
  const onFinish = (value) => {
    register(value.email, value.password, value.confirmPassword, value.phone);
  };

  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <Row justify='space-around' align='middle' style={{ marginTop: '5px' }}>
      <Col xs={24} sm={9} className='signinCol'>
        <Title
          level={3}
          style={{ color: blue, marginBottom: '5px', marginLeft: '10px' }}
        >
          <Text style={{ color: blue[6] }}> Đăng ký tài khoản</Text>
        </Title>
        <UserOutlined style={{ marginLeft: '10px' }} /> Tạo tài khoản của bạn
        <Form
          name='normal_login'
          className='login-form'
          scrollToFirstError
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 15,
          }}
          onFinish={onFinish}
          style={{ marginTop: '20px' }}
        >
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                type: 'email',
                message: 'Định dạng Email không chính xác!',
              },
              { required: true, message: 'Xin vui lòng nhập email của bạn!' },
              {
                validator: emailValidator,
              },
            ]}
            hasFeedback
            validateTrigger='onBlur'
          >
            <Input type='email' allowClear placeholder='Email@domain.com' />
          </Form.Item>
          <Form.Item
            name='phone'
            label='Số điệnt thoại'
            rules={[
              { required: true, message: 'Xin vui lòng nhập số điện thoại' },
              { min: 10, message: 'Định dạng số điện thoại không chính xác.' },
              {
                validator: phoneValidator,
              },
            ]}
            hasFeedback
            validateTrigger='onBlur'
          >
            <Input type='text' placeholder='Phone' />
          </Form.Item>
          <Form.Item
            name='password'
            label='Mật mã'
            validateTrigger='onBlur'
            hasFeedback
            rules={[
              { required: true, message: 'Xin vui lòng nhập mật mã!' },
              { min: 6, message: 'Mật mã phải có ít nhất 6 ký tự.' },
            ]}
          >
            <Input.Password placeholder='password' />
          </Form.Item>
          <Form.Item
            name='confirmPassword'
            label='Xác nhận mật mã'
            dependencies={['password']}
            validateTrigger='onBlur'
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Xin vui lòng nhập mật mã của bạn!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error('Mật mã và xác nhận mật mã phải trùng nhau')
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder='password' />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 10, offset: 7 },
              sm: { span: 8, offset: 10 },
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              className='signup-form-button'
              size='large'
            >
              Đăng Ký
            </Button>
          </Form.Item>
          <Text className='already-register-or-login'>
            Bạn đã có tài khoản? <Link to='/register'>Đăng nhập tại đây</Link>
          </Text>
        </Form>
      </Col>
    </Row>
  );
};
Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.AuthRedux.isAuthenticated,
});
export default connect(mapStateToProps, { setAlert, register })(Register);
