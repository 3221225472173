export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SAVEUSER = 'SAVEUSER';
export const SAVEUSER_FAIL = 'SAVEUSER_FAIL';
export const COUNTORDER_FAIL = 'COUNTORDER_FAIL';
export const COUNTORDER_SUCCESS = 'COUNTORDER_SUCCESS';
export const LIST_ALLORDER_SUCCESS = 'LIST_ALLORDER_SUCCESS';
export const LIST_NEW_ORDER_SUCCESS = 'LIST_NEW_ORDER_SUCCESS';
export const LIST_CONFIRMED_ORDER_SUCCESS = 'LIST_CONFIRMED_ORDER_SUCCESS';
export const LIST_WAITINGTOSHIP_ORDER_SUCCESS =
  'LIST_WAITINGTOSHIP_ORDER_SUCCESS';
export const LIST_SHIPPED_ORDER_SUCCESS = 'LIST_SHIPPED_ORDER_SUCCESS';
export const LIST_RECEIVED_ORDER_SUCCESS = 'LIST_RECEIVED_ORDER_SUCCESS';
export const LIST_COMPLETED_ORDER_SUCCESS = 'LIST_COMPLETED_ORDER_SUCCESS';
export const LIST_CANCELED_ORDER_SUCCESS = 'LIST_CANCELED_ORDER_SUCCESS';
export const LOADING_LIST = 'LOADING_LIST';
export const CURRENT_ORDER = 'CURRENT_ORDER';
export const LOAD_PROVINE = 'LOAD_PROVINE';
export const SAVE_DISTRICT = 'SAVE_DISTRICT';
export const SAVE_WARD = 'SAVE_WARD';
export const SAVE_CITY = 'SAVE_CITY';
export const CHANGE_DISTRICT = 'CHANGE_DISTRICT';
export const CHANGE_WARD = 'CHANGE_WARD';
export const GET_STATISTICS = 'GET_STATISTICS';
export const UPDATE_STATISTICS = 'UPDATE_STATISTICS';
export const CLEAR_ALL_STATISTICS = 'CLEAR_ALL_STATISTICS';
export const CLEAR_ALL_ORDERS = 'CLEAR_ALL_ORDERS';
export const CLEAR_ALL_PROVINE = 'CLEAR_ALL_PROVINE';
export const LIST_SEARCH_ORDERS = 'LIST_SEARCH_ORDERS';
export const LOAD_PRODUCT_TABLE = 'LOAD_PRODUCT_TABLE';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const LOAD_SEARCH_PRODUCT_TABLE = 'LOAD_SEARCH_PRODUCT_TABLE';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDER1 = 'ADD_ORDER1';
export const NO_LOADING_LIST = 'NO_LOADING_LIST';
export const UPDATE_COUNTER = 'UPDATE_COUNTER';
export const UPDATE_COUNTER1 = 'UPDATE_COUNTER1';
export const DELETE_ORDER = 'DELETE_ORDE';
export const CONFIRM_ORDER = 'CONFIRM_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
