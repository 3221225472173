import React, { useEffect, useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Popconfirm,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Store from '../Store';
import {
  loadProducts,
  loadingList,
  listAllSearchProduct,
  deleteProduct,
  noLoadingList,
} from '../action/Products';
import ExpandProduct from './ExpandProduct';
import ProductModal from './layout/ProductModal';
import {
  CheckCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { isMobile } from 'react-device-detect';

const Products = ({ products, loading, count, user }) => {
  const { Content } = Layout,
    { Search } = Input,
    [selectedProduct, setSelectedProduct] = useState([]),
    [hasSelected, setHasSelected] = useState(false),
    [expandedRowKeys, setExpandedRowKey] = useState([]),
    [productAction, setProductAction] = useState({}),
    [currentPage, setCurrentPage] = useState(1),
    [screenHeight, setScreenHeight] = useState(window.innerHeight - 250),
    columns = [
      {
        title: 'Mã SP',
        dataIndex: 'productId',
        width: '8%',
        render: (text) => <center>{text}</center>,
      },
      {
        title: 'Tên Sản Phẩm',
        dataIndex: 'productName',
        ellipsis: true,
      },
      {
        title: 'Giá Vốn',
        dataIndex: 'inPrice',
        align: 'right',
        width: '9%',
        render: (text) => text.toLocaleString('vi-VN'),
      },
      {
        title: 'Giá Bán',
        dataIndex: 'outPrice',
        width: '9%',
        align: 'right',
        render: (text) => text.toLocaleString('vi-VN'),
      },
      {
        title: 'Tồn Kho',
        dataIndex: 'quantity',
        align: 'right',
        width: '9%',
        render: (text) => text.toLocaleString('vi-VN'),
      },
      {
        title: 'Đã Bán',
        dataIndex: 'sold',
        width: '9%',
        align: 'right',
        render: (text) => (text ? text.toLocaleString('vi-VN') : 0),
      },
      {
        title: 'Vị Trí',
        dataIndex: 'location',
        width: '20%',
        ellipsis: true,
      },
    ],
    columnsMobile = [
      {
        title: 'Tên Sản Phẩm',
        dataIndex: 'productName',
        ellipsis: true,
      },

      {
        title: 'Giá Bán',
        dataIndex: 'outPrice',
        width: '22%',
        align: 'right',
        render: (text) => text.toLocaleString('vi-VN'),
      },
      {
        title: 'Tồn Kho',
        dataIndex: 'quantity',
        align: 'right',
        width: '20%',
        render: (text) => text.toLocaleString('vi-VN'),
      },
    ],
    onSelectChange = (selectedProduct) => {
      setSelectedProduct({ selectedProduct });
      setHasSelected(selectedProduct.length > 0);
    },
    productSelection = {
      selectedProduct,
      onChange: onSelectChange,
    },
    handleProductSearch = (e) => {
      setCurrentPage(1);
      try {
        if (e) {
          Store.dispatch(loadingList());
          Store.dispatch(listAllSearchProduct(e));
        } else {
          Store.dispatch(loadingList());
          Store.dispatch(loadProducts());
        }
      } catch (err) {
        console.log(err);
        message.error('Error while doing search');
      }
    },
    pageChangehandler = (page, pagesize) => {
      setCurrentPage(page);
      if (products.search) {
        const searchTerm = document.querySelector(
          'input[name="searchProduct"]'
        ).value;

        Store.dispatch(loadingList());
        Store.dispatch(listAllSearchProduct(searchTerm, page));
      } else {
        Store.dispatch(loadingList());
        Store.dispatch(loadProducts(page));
      }
    },
    clearExpaned = () => {
      setExpandedRowKey([]);
    },
    handleProductModal = (product, type) => {
      setProductAction({
        product: product,
        type: type,
        clearExpaned: clearExpaned,
      });
    },
    massCancel = (e) => {
      message.success('Đã huỷ bỏ việc xoá sản phẩm');
    },
    confirmMassDelete = async (e) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      try {
        for (let val of e) {
          const res = await axios.delete(`/api/products/${val}`, config);
          if (res.data.success === true) {
            Store.dispatch(deleteProduct(val));
          }
        }
        message.success('Đã xoá sản phẩm thành công');
        setSelectedProduct([]);
      } catch (err) {
        console.log(err);
        message.error('Có lỗi trong quá trình xoá sản phẩm');
      }
    };

  useEffect(() => {
    async function initilizeState() {
      try {
        if (user.partition) {
          await Store.dispatch(loadProducts());
        }
        if (!user.partition) {
          Store.dispatch(noLoadingList());
        }
      } catch (err) {
        message.error('Initilize error orrcured');
      }
    }
    initilizeState();
  }, []);
  useEffect(() => {
    function handleResize() {
      setScreenHeight(window.innerHeight - 330);
    }
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  if (isMobile) {
    return (
      <>
        <ProductModal productAction={productAction} />
        <Row
          style={{
            padding: '10px 5px',
            marginBottom: '8px',
            backgroundImage:
              'radial-gradient( circle 610px at 5.2% 51.6%,  rgba(5,8,114,1) 0%, rgba(7,3,53,1) 97.5% )',
          }}
        >
          <Col xs={7}>
            <Button
              shape='circle'
              size='default'
              type='primary'
              icon={<PlusOutlined style={{ fontSize: '160%' }} />}
              style={{
                marginRight: '8px',
                marginLeft: '5px',
                marginBottom: '5px',
                backgroundColor: '#cf1322',
                border: 'none',
              }}
              onClick={() => handleProductModal({}, 'new')}
            ></Button>
            <Popconfirm
              title={`Xác nhận xoá ${
                selectedProduct.selectedProduct
                  ? selectedProduct.selectedProduct.length
                  : 0
              } sản phẩm ?`}
              onConfirm={() =>
                confirmMassDelete(selectedProduct.selectedProduct)
              }
              onCancel={massCancel}
              okText='Xoá'
              cancelText='Không'
            >
              <Button
                type='primary'
                shape='circle'
                icon={<DeleteOutlined style={{ fontSize: '160%' }} />}
                size='middle'
                style={{
                  marginRight: '8px',
                  marginLeft: '5px',
                  marginBottom: '5px',
                  backgroundColor: '#237804',
                  border: 'none',
                }}
                className='product-head-action-button'
                disabled={!hasSelected}
              ></Button>
            </Popconfirm>
          </Col>
          <Col xs={17}>
            <Search
              placeholder='Tìm kiếm tên sản phẩm'
              enterButton
              name='searchProduct'
              onSearch={(e) => {
                handleProductSearch(e);
              }}
            />
          </Col>
        </Row>
        <Row style={{ padding: '5px' }}>
          <Table
            rowSelection={productSelection}
            columns={columnsMobile}
            dataSource={[...products]}
            loading={loading}
            tableLayout='fixed'
            rowKey='_id'
            locale='vi-VN'
            pagination={{
              defaultPageSize: 30,
              total: count,
              showSizeChanger: false,
              position: ['bottomCenter'],
              onChange: pageChangehandler,
              size: 'large',
              showQuickJumper: true,
              current: currentPage,
              hideOnSinglePage: true,
            }}
            expandable={{
              expandRowByClick: true,
              expandIconColumnIndex: -1,
              expandedRowRender: (record) => (
                <ExpandProduct
                  product={record}
                  updateProduct={handleProductModal}
                />
              ),
              expandedRowClassName: (record, index, indent) =>
                'expanded-product-row',
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded, record) => {
                let keys = [];

                expanded && keys.push(record._id);
                setExpandedRowKey(keys);
              },
            }}
            rowClassName={(record, index) => {
              if (expandedRowKeys.includes(record._id)) {
                return index % 2 === 0
                  ? 'table-row-light expanded-product-row'
                  : 'table-row-dark expanded-product-row';
              } else {
                return index % 2 === 0 ? 'table-row-light' : 'table-row-dark';
              }
            }}
            size='middle'
            showHeader={true}
            scroll={{ y: screenHeight }}
          />
        </Row>
      </>
    );
  }
  return (
    <>
      <ProductModal productAction={productAction} />
      <Content style={{ padding: '10px' }}>
        <Row justify='end'>
          {selectedProduct.selectedProduct &&
            selectedProduct.selectedProduct.length > 0 && (
              <Col xs={10} sm={8} md={6} lg={13} xl={15}>
                <Popconfirm
                  title={`Xác nhận xoá ${
                    selectedProduct.selectedProduct
                      ? selectedProduct.selectedProduct.length
                      : 0
                  } sản phẩm ?`}
                  onConfirm={() =>
                    confirmMassDelete(selectedProduct.selectedProduct)
                  }
                  onCancel={massCancel}
                  okText='Xoá'
                  cancelText='Không'
                >
                  <Button
                    type='primary'
                    shape='round'
                    icon={<CheckCircleOutlined />}
                    size='middle'
                    className='product-head-action-button'
                    disabled={!hasSelected}
                  >
                    Xoá Sản Phẩm
                  </Button>
                </Popconfirm>
                <span
                  style={{ marginLeft: 8, color: '#c2136b', fontWeight: 600 }}
                >
                  {hasSelected
                    ? `Đã chọn ${selectedProduct.selectedProduct.length} Sản Phẩm`
                    : ''}
                </span>
              </Col>
            )}

          <Col xs={10} sm={8} md={6} lg={4} xl={3}>
            <Button
              type='primary'
              onClick={() => handleProductModal({}, 'new')}
            >
              Tạo Sản Phẩm Mới
            </Button>
          </Col>
          <Col xs={14} sm={16} md={12} lg={7} xl={6}>
            <Search
              placeholder='Tìm kiếm tên sản phẩm'
              enterButton
              name='searchProduct'
              onSearch={(e) => {
                handleProductSearch(e);
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col>
            <Table
              rowSelection={productSelection}
              columns={columns}
              dataSource={[...products]}
              loading={loading}
              tableLayout='fixed'
              rowKey='_id'
              locale='vi-VN'
              pagination={{
                defaultPageSize: 30,
                total: count,
                showSizeChanger: false,
                position: ['bottomCenter'],
                onChange: pageChangehandler,
                size: 'large',
                showQuickJumper: true,
                current: currentPage,
                hideOnSinglePage: true,
              }}
              expandable={{
                expandRowByClick: true,
                expandIconColumnIndex: -1,
                expandedRowRender: (record) => (
                  <ExpandProduct
                    product={record}
                    updateProduct={handleProductModal}
                  />
                ),
                expandedRowClassName: (record, index, indent) =>
                  'expanded-product-row',
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => {
                  let keys = [];

                  expanded && keys.push(record._id);
                  setExpandedRowKey(keys);
                },
              }}
              rowClassName={(record, index) => {
                if (expandedRowKeys.includes(record._id)) {
                  return index % 2 === 0
                    ? 'table-row-light expanded-product-row'
                    : 'table-row-dark expanded-product-row';
                } else {
                  return index % 2 === 0 ? 'table-row-light' : 'table-row-dark';
                }
              }}
              size='middle'
              showHeader={true}
              scroll={{ y: screenHeight }}
            />
          </Col>
        </Row>
      </Content>
    </>
  );
};

Products.propTypes = {
  products: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.Products.products,
  loading: state.Products.loading,
  count: state.Products.count,
  user: state.AuthRedux.user,
});

export default connect(mapStateToProps, {
  loadProducts,
})(Products);
