import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isAdmin } from '../util/IsLoggin';

import { Layout, Menu, Avatar, Typography } from 'antd';
import {
  ProfileOutlined,
  ShoppingOutlined,
  LineChartOutlined,
  SettingOutlined,
  ToolOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';

const Sidebar = (props) => {
  const {
      auth: { isAuthenticated, loading, user },
      collapsed,
      onCollapse,
    } = props,
    { Sider } = Layout,
    { Text } = Typography,
    [collapsedWidth, setCollapsedWidth] = useState(60),
    [showPhoneNo, setShowPhoneNo] = useState(false),
    history = useHistory(),
    currPath = history.location.pathname,
    [title, setTitle] = useState(null);

  useEffect(() => {
    onCollapse(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (collapsed === true || collapsed === undefined) {
      setShowPhoneNo(false);
    } else {
      setTimeout(() => {
        setShowPhoneNo(collapsed ? false : true);
      }, 200);
    }
  }, [collapsed]);

  const handleMenuClick = (e) => {
    setTitle(e.key);
    onCollapse(true);
    if (currPath !== e.key) return history.push(e.key);
  };
  return (
    <Sider
      breakpoint='xl'
      // collapsedWidth='0'
      collapsible={true}
      width={140}
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      // trigger={null}
      onBreakpoint={(broken) => {
        if (broken === true) {
          setCollapsedWidth(0);
        }
        if (broken === false) {
          setCollapsedWidth(70);
        }
      }}
      onCollapse={onCollapse}
      defaultCollapsed={true}
    >
      <div className='logo'>
        <Avatar size={42} src='/images/pngegg.png' />
        {isAuthenticated && !loading && user ? (
          <div className={showPhoneNo ? 'avatarText' : 'avatarTextHide'}>
            <Text strong={true} type='avatarText'>
              {user.phone}{' '}
            </Text>
          </div>
        ) : (
          ''
        )}
      </div>
      <Menu
        onClick={handleMenuClick}
        theme='dark'
        mode='inline'
        selectedKeys={[title]}
      >
        <Menu.Item icon={<DashboardOutlined />} key='/dashboard'>
          Thống Kê
        </Menu.Item>

        <Menu.Item key='/orders' icon={<ProfileOutlined />}>
          Đơn Hàng
        </Menu.Item>
        <Menu.Item key='/products' icon={<ShoppingOutlined />}>
          Sản Phẩm
        </Menu.Item>
        <Menu.Item key='/doisoat' icon={<DollarCircleOutlined />}>
          Đối Soát
        </Menu.Item>
        <Menu.Item key='/reports' icon={<LineChartOutlined />}>
          Báo Cáo
        </Menu.Item>
        <Menu.Item key='/setting' icon={<SettingOutlined />}>
          Cấu Hình
        </Menu.Item>
        {isAdmin() && (
          <Menu.Item key='/admin' icon={<ToolOutlined />}>
            Admin
          </Menu.Item>
        )}
      </Menu>
    </Sider>

    // <Fragment>
    //     <Drawer
    //       variant='permanent'
    //       transitionDuration={2000}
    //       className={clsx(classes.drawer, {
    //         [classes.drawerOpen]: props.open,
    //         [classes.drawerClose]: !props.open,
    //       })}
    //       classes={{
    //         paper: clsx({
    //           [classes.drawerOpen]: props.open,
    //           [classes.drawerClose]: !props.open,
    //         }),
    //       }}
    //     >
    //       <div className={classes.toolbar}>
    //         <Grid container>
    //           <Grid item xs={8} className={classes.sideAvatar}>
    //             <Avatar
    //               alt='hinh avatar'
    //               src='http://localhost:5050/images/pngegg.png'
    //               className={classes.large}
    //               children='abc'
    //             />
    //             {!loading && (
    //               <Fragment>
    //                 {isAuthenticated && !loading && user ? user.phone : ''}
    //               </Fragment>
    //             )}
    //           </Grid>
    //           <Grid item xs={4} style={{ marginTop: '5px' }}>
    //             <IconButton onClick={props.handleDrawerClose}>
    //               {theme.direction === 'rtl' ? (
    //                 <ChevronRightIcon />
    //               ) : (
    //                 <ChevronLeftIcon />
    //               )}
    //             </IconButton>
    //           </Grid>
    //         </Grid>
    //       </div>
    //       <Divider />
    //       <List>
    //         {['Dashboard', 'Đơn Hàng', 'Sản phẩm', 'Báo cáo', 'Cấu hình'].map(
    //           (text, index) => (
    //             <Link
    //               to={
    //                 index === 0
    //                   ? '/dashboard'
    //                   : index === 1
    //                   ? '/orders'
    //                   : index === 2
    //                   ? '/products'
    //                   : index === 3
    //                   ? '/report'
    //                   : '/setting'
    //               }
    //               className='noDecorLink'
    //               key={index}
    //             >
    //               <ListItem
    //                 selected={cp === text ? true : false}
    //                 button
    //                 key={text}
    //                 className={classes.drawList}
    //               >
    //                 <ListItemIcon className={classes.icon}>
    //                   {index === 0 ? (
    //                     <HomeIcon color='primary' />
    //                   ) : index === 1 ? (
    //                     <ReceiptIcon color='primary' />
    //                   ) : index === 2 ? (
    //                     <ShoppingBasketIcon color='primary' />
    //                   ) : index === 3 ? (
    //                     <AssessmentIcon color='primary' />
    //                   ) : (
    //                     <SettingsIcon color='primary' />
    //                   )}
    //                 </ListItemIcon>

    //                 <ListItemText
    //                   primary={text}
    //                   className={classes.sidebarText}
    //                 />
    //               </ListItem>
    //             </Link>
    //           )
    //         )}
    //         {isAdmin() ? (
    //           <Link to='/admin' className='noDecorLink' key='5'>
    //             <ListItem
    //               selected={cp === 'admin' ? true : false}
    //               button
    //               key='admin'
    //               className={classes.drawList}
    //             >
    //               <ListItemIcon className={classes.icon}>
    //                 <SupervisorAccountIcon color='primary' />
    //               </ListItemIcon>
    //               <ListItemText primary='Admin' className={classes.sidebarText} />
    //             </ListItem>
    //           </Link>
    //         ) : null}
    //       </List>
    //     </Drawer>
    //   </Fragment>
  );
};

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.AuthRedux,
});

export default connect(mapStateToProps, {})(Sidebar);
