import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { message } from 'antd';

function Alert({ alerts }) {
  useEffect(() => {
    if (alerts != null && alerts.length > 0) {
      for (let toDisplay of alerts) {
        message[toDisplay.alertType](toDisplay.msg);
      }
    }
  }, [alerts]);
  return null;
}

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.Alert,
});

export default connect(mapStateToProps)(Alert);
