import { GET_STATISTICS, CLEAR_ALL_STATISTICS } from '../action/Types';
const initialState = {};

const Statistics = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STATISTICS:
      return {
        ...state,
        ...payload.payload,
      };
    case CLEAR_ALL_STATISTICS:
      return { ...payload.payload };
    default:
      return state;
  }
};

export default Statistics;
