import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isLoggin } from './util/IsLoggin';
import { Redirect } from 'react-router-dom';
const drawerWidth = 160;
const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Main() {
  const classes = useStyles();
  const logedin = isLoggin();
  if (logedin) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      kdfjlsdjfklsdjfklds
    </main>
  );
}

export default Main;
