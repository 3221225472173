import {
  COUNTORDER_SUCCESS,
  LIST_ALLORDER_SUCCESS,
  LOADING_LIST,
  CURRENT_ORDER,
  CLEAR_ALL_ORDERS,
  LIST_SEARCH_ORDERS,
  ADD_ORDER,
  ADD_ORDER1,
  NO_LOADING_LIST,
  UPDATE_COUNTER,
  UPDATE_COUNTER1,
  DELETE_ORDER,
  CONFIRM_ORDER,
  UPDATE_ORDER,
} from '../action/Types';
const initialState = {
  allOrder: {},
  sodon: {},
  allOrdersTable: [],
  tableLoading: true,
  currentOrder: {},
  orderLoading: true,
  search: false,
  currentTab: 'all',
};

const Orders = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COUNTORDER_SUCCESS:
      return {
        ...state,
        sodon: payload.payload.sodon,
      };
    case UPDATE_COUNTER:
      if (payload === 'moi') {
        const total = state.sodon.total + 1;
        const moi = state.sodon.moi + 1;
        return { ...state, sodon: { ...state.sodon, total: total, moi: moi } };
      }
      if (payload === 'danhanhang') {
        const total = state.sodon.total + 1;
        const danhanhang = state.sodon.danhanhang + 1;
        return {
          ...state,
          sodon: { ...state.sodon, total: total, danhanhang: danhanhang },
        };
      }
      return { ...state };
    case UPDATE_COUNTER1:
      const sodon1 = { ...state.sodon };
      sodon1[payload.oldStatus] = sodon1[payload.oldStatus] - 1;
      sodon1[payload.newStatus] = sodon1[payload.newStatus] + 1;

      return { ...state, sodon: sodon1 };
    case CONFIRM_ORDER:
      return {
        ...state,
        allOrder: {
          ...state.allOrder,
          data: { ...state.allOrder.data, ...payload },
        },
      };
    case DELETE_ORDER:
      return {
        ...state,
        allOrdersTable: state.allOrdersTable.filter(
          (order) => order.key !== payload
        ),
      };
    case LIST_ALLORDER_SUCCESS:
      return {
        ...state,
        allOrder: { count: payload.payload1, data: payload.payload },
        allOrdersTable: payload.payload2,
        tableLoading: false,
        search: false,
        currentTab: payload.tab,
      };
    case UPDATE_ORDER:
      var newOrdersTable = [];
      for (let val in state.allOrdersTable) {
        if (state.allOrdersTable[val].key === payload.payload1.key) {
          newOrdersTable.push(payload.payload1);
        } else {
          newOrdersTable.push(state.allOrdersTable[val]);
        }
      }
      return {
        ...state,
        allOrdersTable: newOrdersTable,
        allOrder: {
          ...state.allOrder,
          data: { ...state.allOrder.data, ...payload.payload },
        },
      };
    case ADD_ORDER:
      var count = 0;
      if (state.allOrdersTable.length >= 30) {
        state.allOrdersTable.pop();
      }

      count = state.allOrder.count + 1;

      return {
        ...state,
        allOrdersTable: [payload.payload1, ...state.allOrdersTable],
        allOrder: {
          count: count,
          data: { ...payload.payload, ...state.allOrder.data },
        },
      };
    case ADD_ORDER1:
      if (state.allOrdersTable.length >= 30) {
        state.allOrdersTable.pop();
      }
      return {
        ...state,
        allOrdersTable: [payload.payload1, ...state.allOrdersTable],
      };
    case LIST_SEARCH_ORDERS:
      return {
        ...state,
        allOrder: { count: payload.payload1, data: payload.payload },
        allOrdersTable: payload.payload2,
        tableLoading: false,
        search: true,
      };
    case LOADING_LIST:
      return { ...state, tableLoading: true };
    case NO_LOADING_LIST:
      return { ...state, tableLoading: false };
    case CURRENT_ORDER:
      return { ...state, currentOrder: payload.payload, orderLoading: false };
    case CLEAR_ALL_ORDERS:
      return {
        ...state,
        allOrder: {},
        sodon: {},
        allOrdersTable: [],
        tableLoading: true,
        currentOrder: {},
        orderLoading: true,
      };
    default:
      return state;
  }
};

export default Orders;
