import axios from 'axios';
import {
  LOAD_PRODUCT_TABLE,
  DELETE_PRODUCT,
  ADD_PRODUCT,
  LOADING_LIST,
  LOAD_SEARCH_PRODUCT_TABLE,
  UPDATE_PRODUCT,
  NO_LOADING_LIST,
} from './Types';
import { setAlert } from './Alert';
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};
//load product
export const loadProducts =
  (page = 1, sortTerm = '', sortOrder = '') =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/products/?sortTerm=${sortTerm}&sortOrder=${sortOrder}&page=${page}`,
        config
      );
      if (res.data.success) {
        dispatch({
          type: LOAD_PRODUCT_TABLE,
          payload: { payload: res.data },
        });
      } else {
        dispatch(setAlert(res.msg, 'error'));
      }
    } catch (err) {
      const error = err.res.data.error;
      console.log(error);
      if (error) {
        for (let err1 of error) {
          dispatch(setAlert(err1.msg, 'error'));
        }
      }
    }
  };

export const deleteProduct = (productId) => (dispatch) => {
  dispatch({
    type: DELETE_PRODUCT,
    payload: productId,
  });
};

export const addProduct = (data) => (dispatch) => {
  console.log(data);
  dispatch({
    type: ADD_PRODUCT,
    payload: data,
  });
};

export const loadingList = () => (dispatch) => {
  dispatch({
    type: LOADING_LIST,
  });
};
export const noLoadingList = () => (dispatch) => {
  dispatch({
    type: NO_LOADING_LIST,
  });
};

export const listAllSearchProduct =
  (searchTerm, page = 1) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const body = JSON.stringify({ searchTerm: searchTerm, page: page });
      const res = await axios.post(`/api/products/searchlist/`, body, config);
      if (res.data.success) {
        dispatch({
          type: LOAD_SEARCH_PRODUCT_TABLE,
          payload: { payload: res.data },
        });
      } else {
        dispatch(setAlert(res.msg, 'error'));
      }
    } catch (err) {
      const error = err.res.data.msg;
      console.log(error);
      if (error) {
        for (let err1 of error) {
          dispatch(setAlert(err1.msg, 'error'));
        }
      }
    }
  };

export const updateProduct = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCT,
    payload: data,
  });
};
