import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { isLoggin, isShop } from '../util/IsLoggin';

const MyRoute = ({
  component: Component,
  isRestricted = false,
  needShop = false,
  ...rest
}) => {
  if (!isRestricted && !needShop) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggin() ? <Component {...props} /> : <Redirect to='/login' />
        }
      />
    );
  }
  if (isRestricted && !needShop) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggin() ? <Component {...props} /> : <Redirect to='/login' />
        }
      />
    );
  }
  // if (isRestricted) {
  //   console.log(isAdmin());
  //   return (
  //     <Route
  //       {...rest}
  //       render={(props) =>
  //         isAdmin() ? <Component {...props} /> : <Redirect to='/' />
  //       }
  //     />
  //   );
  // }

  if (needShop) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isShop() ? <Component {...props} /> : <Redirect to='/setting' />
        }
      />
    );
  }
};

export default MyRoute;
