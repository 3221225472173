import React from 'react';
// import socketio from 'socket.io-client';
import { io } from 'socket.io-client';

export const socket = io('/', {
  reconnection: true,
  reconnectionDelay: 1000,
  maxReconnectionAttempts: Infinity,
});
export const SocketContext = React.createContext();
