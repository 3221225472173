import { combineReducers } from 'redux';
import Alert from './Alert';
import AuthRedux from './Auth';
import Orders from './Orders';
import Provine from './Provine';
import Statistics from './Statistics';
import Products from './Products';

export default combineReducers({
  Alert,
  AuthRedux,
  Orders,
  Provine,
  Statistics,
  Products,
});
