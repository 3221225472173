import {
  LOAD_PRODUCT_TABLE,
  DELETE_PRODUCT,
  ADD_PRODUCT,
  LOADING_LIST,
  LOAD_SEARCH_PRODUCT_TABLE,
  UPDATE_PRODUCT,
  NO_LOADING_LIST,
} from '../action/Types';
const initialState = {
  products: [],
  count: 0,
  loading: true,
  search: false,
};
const Products = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_PRODUCT_TABLE:
      return { ...state, ...payload.payload, loading: false };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter((product) => product._id !== payload),
      };
    case ADD_PRODUCT:
      state.products.pop();
      const count = state.count + 1;

      return {
        ...state,

        products: [payload, ...state.products],
        count: count,
        test: count,
      };
    case UPDATE_PRODUCT:
      for (let val in state.products) {
        if (state.products[val]._id === payload._id) {
          state.products[val] = payload;
        }
      }
      return { ...state };
    case LOAD_SEARCH_PRODUCT_TABLE:
      return { ...payload.payload, loading: false, search: true };
    case LOADING_LIST:
      return { ...state, loading: true };
    case NO_LOADING_LIST:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default Products;
