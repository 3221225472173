import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Collapse,
  Form,
  Input,
  Typography,
  Tooltip,
  Space,
  Select,
  message,
} from 'antd';
import { red, grey, lime } from '@ant-design/colors';

import { saveuser } from '../action/Auth';
import {
  initProvine,
  saveDistrict,
  saveWard,
  changeDistrict,
  changeWard,
} from '../action/Provine';
import { SaveFilled } from '@ant-design/icons';

const Setting = ({
  user,
  saveuser,
  provine,
  initProvine,
  saveDistrict,
  saveWard,
  changeDistrict,
  changeWard,
}) => {
  const { Panel } = Collapse,
    { Title, Link } = Typography,
    { Option } = Select,
    handleCityChange = async (value, option) => {
      try {
        initProvine(value, 0, 0, option.children, '', '');
        saveDistrict(value);
        saveWard([]);
      } catch (err) {
        console.log(err);
        message.error('Không thể tải thông tin Tỉnh Thành');
      }
    },
    handleDistrictChange = async (value, option) => {
      try {
        changeDistrict(value, option.children);
      } catch (err) {
        console.log(err);
        message.error('Không thể tải thông tin Quận Huyện');
      }
    },
    handleWardChange = (value, option) => {
      changeWard(value, option.children);
    };

  useEffect(() => {
    const loadProvine = async () => {
      try {
        await initProvine(
          user.location && user.location.citiCode ? user.location.citiCode : 0,
          user.location && user.location.districtCode
            ? user.location.districtCode
            : 0,
          user.location && user.location.wardCode ? user.location.wardCode : 0,
          user.location && user.location.city ? user.location.city : '',
          user.location && user.location.district ? user.location.district : '',
          user.location && user.location.ward ? user.location.ward : 0
        );

        if (user.location && user.location.citiCode !== '0') {
          await saveDistrict(user.location.citiCode);
        }
        if (user.location && user.location.districtCode !== '0') {
          await saveWard(user.location.districtCode);
        }
      } catch (err) {
        console.log(err);
        message.error('Không thể tải thông tin Tỉnh Thành');
      }
    };
    loadProvine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let citiChoice = provine.city.map((item, i) => {
    return (
      <Option key={item.cityCode} value={item.cityCode}>
        {item.cityName}
      </Option>
    );
  });

  let districtChoice = provine.district.map((item, i) => {
    return (
      <Option key={item.districtCode} value={item.districtCode}>
        {item.districtName}
      </Option>
    );
  });

  let wardChoice = provine.ward.map((item, i) => {
    return (
      <Option key={item.wardCode} value={item.wardCode}>
        {item.wardName}
      </Option>
    );
  });

  const onFinish = (value) => {
    let data = { ...value };
    data.location = {
      address: value.shopAddress,
      city: provine.choicedcity,
      citiCode: provine.choicedCityCode,
      district: provine.choiceddistrict,
      districtCode: provine.choicedDistrictCode,
      ward: provine.choicedward,
      wardCode: provine.choicedWardCode,
    };
    console.log(data);
    saveuser(data);
  };

  return (
    <Row
      style={{
        border: '1px solid' + grey[1],
        borderRadius: '5px',
        boxShadow: '-3px 3px 5px 1px #cecece',
      }}
    >
      <Col span={24}>
        <Form
          name='setting_form'
          className='setting-form'
          scrollToFirstError
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 12,
          }}
          onFinish={onFinish}
        >
          <Collapse
            accordion
            defaultActiveKey={['1']}
            bordered={true}
            style={{}}
          >
            <Panel
              header='Thiết lập cơ bản'
              key='1'
              style={{ backgroundColor: lime[8], color: 'white' }}
              className='settingPanel'
            >
              <Row justify='space-around'>
                <Col xs={24} sm={24} md={10} className='user-setting-left-col'>
                  <Form.Item
                    name='name'
                    label='Họ Tên'
                    initialValue={user && user.name ? user.name : ''}
                  >
                    <Input type='text' allowClear placeholder='Họ Tên' />
                  </Form.Item>
                  <Form.Item
                    name='email'
                    label='Email'
                    validateTrigger='onBlur'
                    initialValue={user.email ? user.email : ''}
                  >
                    <Input
                      type='email'
                      allowClear
                      placeholder='Email@domain.com'
                    />
                  </Form.Item>
                  <Form.Item
                    name='phone'
                    label='Số Điện Thoại'
                    validateTrigger='onBlur'
                    initialValue={user.phone ? user.phone : ''}
                  >
                    <Input type='text' placeholder='Số Điện Thoại' />
                  </Form.Item>
                  <Form.Item
                    name='password'
                    label='Mật Mã'
                    validateTrigger='onBlur'
                    rules={[]}
                  >
                    <Input.Password placeholder='Mật Mã' />
                  </Form.Item>
                  <Form.Item
                    name='confirmPassword'
                    label='Xác Nhận Mật Mã'
                    dependencies={['password']}
                    validateTrigger='onBlur'
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              'Mật mã và xác nhận mật mã phải giống nhau!'
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder='Xác nhận mật mã' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={11}>
                  <Form.Item
                    name='shop'
                    label='Tên Shop'
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng nhập tên Shop!',
                      },
                    ]}
                    validateTrigger='onBlur'
                    initialValue={user.shop ? user.shop : ''}
                  >
                    <Input
                      type='text'
                      allowClear
                      placeholder='Tên Shop của bạn'
                    />
                  </Form.Item>
                  <Form.Item
                    name='facebook'
                    label='Facebook Page của Shop'
                    initialValue={user.facebook ? user.facebook : ''}
                    style={{
                      paddingBottom: '0px !important',
                      marginBottom: '10px',
                    }}
                  >
                    <Input
                      type='text'
                      allowClear
                      placeholder='Tên facebook Page của Shop'
                    />
                  </Form.Item>
                  <Form.Item
                    name='address'
                    label='Địa chỉ Shop'
                    initialValue={user.address ? user.address : ''}
                    style={{
                      paddingBottom: '0px !important',
                      marginBottom: '5px',
                    }}
                  >
                    <Input
                      type='text'
                      allowClear
                      placeholder='Địa chỉ Shop của bạn'
                    />
                  </Form.Item>

                  {/* <Form.Item
                    lable='Provine'
                    wrapperCol={{
                      span: 24,
                      offset: 6,
                    }}
                  >
                    <Space> */}
                  <Row style={{ marginBottom: '20px' }}>
                    <Col
                      xs={24}
                      lg={{ span: 6, offset: 6 }}
                      style={{ marginRight: '5px' }}
                    >
                      <Select
                        style={{
                          width: '100%',
                          marginBottom: '5px',
                        }}
                        onChange={handleCityChange}
                        value={provine.choicedCityCode}
                      >
                        <Option key={0} value={0}>
                          Chọn Tỉnh/Thành Phố
                        </Option>
                        {citiChoice}
                      </Select>
                    </Col>
                    <Col xs={24} lg={5} style={{ marginRight: '5px' }}>
                      <Select
                        style={{ width: '100%', marginBottom: '5px' }}
                        value={provine.choicedDistrictCode}
                        onChange={handleDistrictChange}
                      >
                        <Option key={0} value={0}>
                          Chọn Quận/Huyện
                        </Option>
                        {districtChoice}
                      </Select>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Select
                        value={provine.choicedWardCode}
                        style={{ width: '100%', marginBottom: '5px' }}
                        onChange={handleWardChange}
                      >
                        <Option key={0} value={0}>
                          Chọn Phường Xã
                        </Option>
                        {wardChoice}
                      </Select>
                    </Col>
                  </Row>
                  {/* </Space>
                  </Form.Item> */}
                  <Form.Item
                    name='website'
                    label='Địa chỉ trang web'
                    initialValue={user.website ? user.website : ''}
                  >
                    <Input
                      type='text'
                      allowClear
                      placeholder='Địa chỉ trang web của shop'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header='Cấu hình vận chuyển'
              key='2'
              style={{ backgroundColor: lime[8], color: 'white' }}
              className='settingPanel'
            >
              <Row justify='space-around'>
                <Col xs={24} sm={24} md={11} className='user-setting-left-col'>
                  <Title
                    level={5}
                    style={{
                      textAlign: 'center',
                      marginBottom: '15px',
                      color: red[7],
                    }}
                  >
                    Giao Hàng Tiết Kiệm
                  </Title>
                  <Form.Item
                    label='GHTK Api Key'
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                  >
                    <Space>
                      <Form.Item
                        name='ghtkApiKey'
                        initialValue={user.ghtkApiKey ? user.ghtkApiKey : ''}
                        noStyle
                      >
                        <Input
                          style={{ width: 400 }}
                          placeholder='GHTK Api Key'
                        />
                      </Form.Item>
                      <Tooltip title='Useful information'>
                        <Link
                          href='https://giaohangtietkiem.vn/lay-api-token-key-nhu-nao-6141'
                          target='_blank'
                        >
                          Cách lấy APIKEY?
                        </Link>
                      </Tooltip>
                    </Space>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={10}>
                  <Title
                    level={5}
                    style={{
                      textAlign: 'center',
                      marginBottom: '15px',
                      color: red[7],
                    }}
                  >
                    {' '}
                    Giao Hàng Nhanh
                  </Title>
                  <Form.Item
                    label='GHN Api Key'
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 20,
                    }}
                  >
                    <Space>
                      <Form.Item
                        name='ghnApiKey'
                        initialValue={user.ghnApiKey ? user.ghnApiKey : ''}
                        noStyle
                      >
                        <Input
                          style={{ width: 400 }}
                          placeholder='GHN Api Key'
                        />
                      </Form.Item>

                      <Tooltip title='Useful information'>
                        <Tooltip title='Useful information'>
                          <Link
                            href='https://api.ghn.vn/home/docs/detail'
                            target='_blank'
                          >
                            Cách lấy APIKEY?
                          </Link>
                        </Tooltip>
                      </Tooltip>
                    </Space>
                  </Form.Item>
                  <Form.Item
                    name='ghnShopId'
                    label='GHN Shop ID'
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 18,
                    }}
                    initialValue={user.ghnShopId ? user.ghnShopId : ''}
                  >
                    <Input style={{ width: 400 }} placeholder='GHN Shop ID' />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header='Cấu hình tích hợp'
              key='3'
              style={{ backgroundColor: lime[8], color: 'white' }}
              className='settingPanel'
            >
              <Row justify='space-start'>
                <Col xs={24} sm={24} md={14} className='user-setting-left-col'>
                  <Title
                    level={5}
                    style={{
                      textAlign: 'center',
                      marginBottom: '15px',
                      color: red[7],
                    }}
                  >
                    Tích hợp với Web bán hàng cá nhân Woocommerce
                  </Title>
                  <Form.Item
                    label='Woocommerce Api Key'
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 18,
                    }}
                  >
                    <Space>
                      <Form.Item
                        name='woocommerceApiKey'
                        initialValue={
                          user.woocommerceApiKey ? user.woocommerceApiKey : ''
                        }
                        noStyle
                      >
                        <Input
                          style={{ width: 500 }}
                          placeholder='Woocommerce Api Key'
                          allowClear
                        />
                      </Form.Item>
                      <Tooltip title='Useful information'>
                        <Tooltip title='Useful information'>
                          <Link
                            href='https://docs.woocommerce.com/document/woocommerce-rest-api/'
                            target='_blank'
                          >
                            Cách lấy APikey?
                          </Link>
                        </Tooltip>
                      </Tooltip>
                    </Space>
                  </Form.Item>
                  <Form.Item
                    name='woocommerceSecretKey'
                    label='Woocommerce Secret Key'
                    initialValue={
                      user.woocommerceSecretKey ? user.woocommerceSecretKey : ''
                    }
                  >
                    <Input
                      type='text'
                      style={{ width: 500 }}
                      allowClear
                      placeholder='Woocommerce Secret Key'
                    />
                  </Form.Item>
                  <Form.Item
                    name='WoocommerceUrl'
                    label='Địa chỉ web woocommerce'
                    initialValue={
                      user.WoocommerceUrl ? user.WoocommerceUrl : ''
                    }
                  >
                    <Input
                      type='text'
                      style={{ width: 500 }}
                      allowClear
                      placeholder='Địa chỉ web bán hàng cá nhân woocommerce của bạn'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header='Cấu hình in đơn hàng'
              key='4'
              style={{ backgroundColor: lime[8], color: 'white' }}
              className='settingPanel'
            >
              Chưa sẵn sàng
            </Panel>
          </Collapse>
          <Form.Item
            style={{ marginTop: '15px' }}
            wrapperCol={{
              xs: { span: 10, offset: 7 },
              sm: { span: 8, offset: 10 },
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              className='save-setting-button'
              size='large'
              shape='round'
              icon={<SaveFilled />}
            >
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

Setting.propTypes = {
  user: PropTypes.object.isRequired,
  provine: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.AuthRedux.user,
  provine: state.Provine,
});

export default connect(mapStateToProps, {
  saveuser,
  initProvine,
  saveDistrict,
  saveWard,
  changeDistrict,
  changeWard,
})(Setting);
